import { cilArrowLeft } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CForm, CFormInput, CFormLabel, CFormSelect, CFormTextarea, CRow, CToast, CToastBody, CToastClose,CFooter } from '@coreui/react'
import axios from 'axios'
import React,{useState, useEffect} from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { endPoints } from '../../../../../Constants'
import { getAccessToken, writeDocumentTitle } from '../../../../../Helpers'

const Add = (props) => {
      const [inputValues, setInputValue] = useState({
            problem_class_id:'',
            opportunity_class_id:'',
            name:'',
            status:'1'
      });
      const [validation, setValidation] = useState({
            problem_class_id:'',
            opportunity_class_id:'',
            name:'',
            status:'1'
      });
      const [opportunityClassList, setOpportunityClassList] = useState([]);
      const [problemClassList, setProblemClassList] = useState([]);
      const [showAlert, setShowAlert] = useState(false);
      const [showMessage, setShowMessage] = useState('');
      const [color, setColor] = useState('');
      const navigate = useNavigate();
      
      const handleChange = (e) => {
          const { name, value } = e.target;
          setInputValue({ ...inputValues, [name]: value });
      }
      useEffect(()=>{
            writeDocumentTitle(props.title);
            /*---------------- get access token --------------*/
            const accessToken = getAccessToken();
            if(!accessToken) {
                localStorage.removeItem('isAdminLoggedIn');
                navigate(`${process.env.PUBLIC_URL}/`);
            }
            const config = {
                headers: { Authorization: `Bearer ${accessToken}` }
            };
          /*---------------- get access token --------------*/
          
          axios.get(`${endPoints}opportunityClassesList`,config)
          .then((response)=>{
              if(response.data.status === true) { 
                  setOpportunityClassList(response.data.aLists);
                  
              }
          })
          .catch((error)=>{
              if(error.response && error.response.status === 401) {
                localStorage.removeItem('isAdminLoggedIn');
                navigate(`${process.env.PUBLIC_URL}/`);
              }
          })
      },[]);
      
    /*------------- form validation ---------------*/
            const checkValidation = () => {
              let errors = {};
              if(!inputValues.opportunity_class_id.trim()) {
                errors.opportunity_class_id = "Opportunity class is required";
              } 
              if(!inputValues.problem_class_id.trim()) {
                errors.problem_class_id = "Problem class is required";
              }
              if(!inputValues.name.trim()) {
                errors.name = "Problem category is required";
              } 
              return errors;
          };
    
  /*------------- form validation ---------------*/
      
  /*------------ handle form submit ----------*/
        const handleSubmit = async (e) => {
          e.preventDefault();
          let isErrors = await checkValidation(inputValues);
          setValidation(isErrors);
          if(Object.keys(isErrors).length === 0) {
                /*---------------- get access token --------------*/
                    const accessToken = getAccessToken();
                    if(!accessToken) {
                        localStorage.removeItem('isAdminLoggedIn');
                        navigate(`${process.env.PUBLIC_URL}/`);
                    }
                    const config = {
                        headers: { Authorization: `Bearer ${accessToken}` }
                    };
               /*---------------- get access token --------------*/
                  setShowAlert(false);
                  setColor('');
                 
                  axios.post(`${endPoints}add-problem-category`,inputValues,config)
                  .then((response)=>{
                    if(response.data.status === true) {
                        setShowMessage(response.data.message);
                        setShowAlert(true);
                        setColor('success');
                        setInputValue({
                            opportunity_class_id: '',
                            problem_class_id:'',
                            name:'',
                            status:'1'
                        });
                        // setTimeout(()=>{
                        //   navigate(`${process.env.PUBLIC_URL}/admin/users`);
                        // },1500);
                    } else {
                        setShowMessage(response.data.message);
                        setShowAlert(true);
                        setColor('failure');
                    }           
                  })
                  .catch(function (error) {
                      if(error.response && error.response.status === 401) {
                          localStorage.removeItem('isAdminLoggedIn');
                          navigate(`${process.env.PUBLIC_URL}/`);
                      }
                  }); 
    
          } 
          
      }
  /*------------ handle form submit ----------*/
  
  /*------------ get problem class by opportunity class --------------*/
       const getProblemClassByOpportunityClass = (e) => {
            let opportunity_class_id = e.target.value;
            if(!!opportunity_class_id) {
                     /*---------------- get access token --------------*/
                      const accessToken = getAccessToken();
                      if(!accessToken) {
                          localStorage.removeItem('isAdminLoggedIn');
                          navigate(`${process.env.PUBLIC_URL}/`);
                      }
                      const config = {
                          headers: { Authorization: `Bearer ${accessToken}` }
                      };
                    /*---------------- get access token --------------*/
                    let inputValues = {opportunity_class_id:opportunity_class_id};
                    axios.post(`${endPoints}getProblemClassByOpportunityClassList`,inputValues,config)
                    .then((response)=>{
                         if(response.data.status === true) { 
                              setProblemClassList(response.data.aProblemClassesLists);
                         }
                    })
                    .catch((error)=>{
                        if(error.response && error.response.status === 401) {
                          localStorage.removeItem('isAdminLoggedIn');
                          navigate(`${process.env.PUBLIC_URL}/`);
                        }
                    })
            }
       }
  /*------------ get problem class by opportunity class --------------*/
  return (
     <>
       <CRow className="align-items-center mb-2">
          <CCol xs={3}></CCol>
             <CCol xs={6}>
                <CToast autohide={true} delay={5000} visible={showAlert} color={`${color && color==='failure' ? 'danger' : 'success' }`} animation={true} className="text-white align-items-center">
                              <div className="d-flex">
                                <CToastBody>{showMessage}</CToastBody>
                                <CToastClose className="me-2 m-auto" />
                              </div>
                  </CToast>
             </CCol>
          <CCol xs={3}></CCol>
       </CRow>
       <CRow>
        
          <CCol xs={12}>
            <CCard className="mb-4">
              <CCardHeader>
               <CRow className="align-items-center">
                  <CCol xs={8} ><h5 className="cardHeading">New Problem Category</h5></CCol>
                  <CCol xs={4} className="text-end">
                      <NavLink to={`${process.env.PUBLIC_URL}/admin/master/problem-categories`} className="linkText">
                        <CIcon icon={cilArrowLeft} className="me-2" />Back</NavLink>
                  </CCol>
              </CRow>
                
              </CCardHeader>
              <CCardBody>
                <CForm onSubmit={handleSubmit}>
                   <CRow>
                   <CCol xs={12} md={6} xl={6}>
                            <div className="mb-3">
                              <CFormLabel htmlFor="opportunity_class_id">Opportunity Class<span className="formError">*</span></CFormLabel>
                              <CFormSelect aria-label="Default select example" 
                                name="opportunity_class_id"
                                id="opportunity_class_id"
                                onChange={(e) => {handleChange(e); getProblemClassByOpportunityClass(e); }}
                                value={inputValues.opportunity_class_id}>
                                  <option value="">Select Opportunity Class</option>
                                  {
                                    opportunityClassList && opportunityClassList.length > 0 ? opportunityClassList.map((item,index)=><option key={item.id} value={item.id}>{item.name}</option>) : null
                                  }
                                  
                              </CFormSelect>
                              <p className="formError">{validation.opportunity_class_id ? validation.opportunity_class_id :  ''}</p>
                            </div>
                      </CCol>
                      <CCol xs={12} md={6} xl={6}>
                            <div className="mb-3">
                              <CFormLabel htmlFor="problem_class_id">Problem Class<span className="formError">*</span></CFormLabel>
                              <CFormSelect aria-label="Default select example" 
                                name="problem_class_id"
                                id="problem_class_id"
                                onChange={(e) => handleChange(e)} 
                                value={inputValues.problem_class_id}>
                                  <option value="">Select Problem Class</option>
                                  {
                                    problemClassList && problemClassList.length > 0 ? problemClassList.map((item,index)=><option key={item.id} value={item.id}>{item.name}</option>) : null
                                  }
                                  
                              </CFormSelect>
                              <p className="formError">{validation.problem_class_id ? validation.problem_class_id :  ''}</p>
                            </div>
                      </CCol>
                      <CCol xs={12} md={6} xl={6}>
                            <div className="mb-3">
                              <CFormLabel htmlFor="name">Problem Category<span className="formError">*</span></CFormLabel>
                              <CFormInput
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Problem Category"
                                onChange={(e) => handleChange(e)} 
                                value={inputValues.name}
                              />
                              <p className="formError">{validation.name ? validation.name :  ''}</p>
                            </div>
                        </CCol>
                        
                        <CCol xs={12} md={6} xl={6}>
                            <div className="mb-3">
                              <CFormLabel htmlFor="status">Status<span className="formError">*</span></CFormLabel>
                              <CFormSelect aria-label="Default select example" name="status" id="status" onChange={(e) => handleChange(e)}>
                                  <option value="1">Active</option>
                                  <option value="0">Inactive</option>
                              </CFormSelect>
                            </div>
                        </CCol>
                     </CRow>
                    
                    <CFooter className='cardFooter'>
                         <CButton type="submit" color="success">Submit</CButton>
                    </CFooter>
                  </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
     </>
   )
}

export default Add
