import './App.css';
import Navigation from './Navigation';

function App() {
  return (
           <Navigation />
  );
}

export default App;
