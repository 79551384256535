import React,{useState, useEffect} from 'react'
import {useNavigate, NavLink} from 'react-router-dom'
import axios from 'axios';
import {endPoints} from '../../../../../Constants';
import {getAccessToken, writeDocumentTitle} from '../../../../../Helpers';
import {FaPlus, FaRegEye,FaPencilAlt,FaTrashAlt,FaSyncAlt,FaFileDownload } from 'react-icons/fa';

import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CFormSelect,
  CPagination,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CToast,
  CToastBody,
  CToastClose,CTooltip
} from '@coreui/react'
import Pagination from '../../layout/Pagination';
import { cilArrowBottom, cilArrowTop } from '@coreui/icons';
import CIcon from '@coreui/icons-react';


const List = (props) => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
 
  
   /*--------------- custom pagination ----------------*/
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [showAlert, setShowAlert] = useState(false);
    const [showMessage, setShowMessage] = useState('');
    const [color, setColor] = useState('');
    const [totalRecord, setTotalRecord] = useState('');
    const [ loading, setLoading ] = useState(true);
    const [ isSearch, setSearch ] = useState(false);
    const [startingSerialNumber, setStartingSerialNumber] = useState(1);
    
    let indexOfLastData = currentPage * dataPerPage;
    //let indexOfFirstData = indexOfLastData - dataPerPage;
    //const currentData = data.slice(indexOfFirstData, indexOfLastData);
    
    useEffect(() => {
      setTimeout(()=>{
        const updatedStartingSerialNumber = (currentPage - 1) * dataPerPage + 1;
        setStartingSerialNumber(updatedStartingSerialNumber);
      },100);  
   }, [data]);
    
    const paginate = (number) => {
          filterListData(number,sortConfig.key,sortConfig.direction);
          setCurrentPage(number);
    };
  /*--------------- custom pagination ----------------*/
  
      useEffect(()=>{
        writeDocumentTitle(props.title);
        getListData();
      
    },[]);
    useEffect(()=>{
      
      if(isSearch) {
        filterListData(currentPage,sortConfig.key,sortConfig.direction);
      }
    },[isSearch]);
  
  /*---------------  Handle column sorting ----------------*/
        
        const sortColumn = (key) => {
          let direction = 'asc';
          if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
          }
          filterListData(currentPage,key,direction);
          setSortConfig({ key, direction });
        };
   /*---------------  Handle column sorting ----------------*/

/*----------------------- get user data ----------------*/
  const getListData = async () => {
       /*----------------------- get user access token otherwise logout ----------------*/
          let token = getAccessToken();
          if(!!token) {
              const config = {
                  headers: { Authorization: `Bearer ${token}` }
              };
              await axios.get(`${endPoints}opportunity-classes`,config)
              .then((response)=>{
                   setData(response.data.aLists);
                   setTotalRecord(response.data.iTotalRecords);
                   setLoading(false);
              })
              .catch((error) => {
                  if(error.response && error.response.status === 401) {
                     localStorage.removeItem('isAdminLoggedIn');
                     navigate(`${process.env.PUBLIC_URL}/`);
                  }
                  setLoading(false);
               })
          } else {
              navigate(`${process.env.PUBLIC_URL}/`);
          }
       /*----------------------- get user access token otherwise logout ----------------*/
       
  } 
/*----------------------- get user data ----------------*/

 /*--------------- filter list data ---------------*/
 const filterListData = (pageNumber='',sortBy='',orderBy='') => {
  let search = isSearch ? document.getElementById("search").value : '';
  let status = isSearch ? document.getElementById("status").value : '';
      /*---------------- get access token --------------*/
          const accessToken = getAccessToken();
          if(!accessToken) {
              localStorage.removeItem('isAdminLoggedIn');
              navigate(`${process.env.PUBLIC_URL}/`);
          }
          const config = {
              headers: { Authorization: `Bearer ${accessToken}` }
          };
      /*---------------- get access token --------------*/
      let inputValues = { search: search, status:status,pageNumber:pageNumber,sortBy:sortBy,orderBy:orderBy};
      axios.post(`${endPoints}filterOpportunityClassList`, inputValues,config)
          .then((response) => {
               setData(response.data.aLists);
               setTotalRecord(response.data.iTotalRecords);
          })
          .catch((error) => {
              if(error.response && error.response.status === 401) {
                  localStorage.removeItem('isAdminLoggedIn');
                  navigate(`${process.env.PUBLIC_URL}/`);
              }
          })
}
/*--------------- filter list data ---------------*/

/*--------------- change status -------------*/
const changeStatus = (id, status) => {
  let inputValues = { id: id, status: status };
  if (Object.keys(inputValues).length === 2) {
    /*---------------- get access token --------------*/
      const accessToken = getAccessToken();
      if(!accessToken) {
          localStorage.removeItem('isAdminLoggedIn');
          navigate(`${process.env.PUBLIC_URL}/`);
      }
      const config = {
          headers: { Authorization: `Bearer ${accessToken}` }
      };
      
    /*---------------- get access token --------------*/
      setShowAlert(false);
      setColor('');
      axios.put(`${endPoints}changeOpportunityClassStatus`, inputValues,config)
          .then((response) => {
              if(response.data.status === true) {
                  filterListData(currentPage,sortConfig.key,sortConfig.direction);
                  setColor('success');
              } else {
                  setColor('failure');
              }
              setShowMessage(response.data.message);
              setShowAlert(true);
              
          })
          .catch((error) => {
               if(error.response && error.response.status === 401) {
                  localStorage.removeItem('isAdminLoggedIn');
                  navigate(`${process.env.PUBLIC_URL}/`);
               }
          })
  }
}
/*--------------- change status -------------*/

/*--------------- delete data -------------*/
const deleteData = (id) => {
  if (!!id) {
      /*---------------- get access token --------------*/
          const accessToken = getAccessToken();
          if(!accessToken) {
              localStorage.removeItem('isAdminLoggedIn');
              navigate(`${process.env.PUBLIC_URL}/`);
          }
          const config = {
              headers: { Authorization: `Bearer ${accessToken}` }
          };
      /*---------------- get access token --------------*/
      setShowAlert(false);
      setColor('');
      axios.delete(`${endPoints}delete-opportunity-class/${id}`,config)
          .then((response) => {
              if(response.data.status === true) {
                  filterListData(currentPage,sortConfig.key,sortConfig.direction);
                  setColor('success');
              } else {
                  setColor('failure');
              }
              setShowMessage(response.data.message);
              setShowAlert(true);
          })
          .catch((error) => {
              if(error.response && error.response.status === 401) {
                  localStorage.removeItem('isAdminLoggedIn');
                  navigate(`${process.env.PUBLIC_URL}/`);
              }
      })
  }
}
/*--------------- delete data -------------*/

/*--------------- clear filter --------------------*/
    const isFilter = () => {
      isSearch && filterListData(currentPage,sortConfig.key,sortConfig.direction);
      setSearch(true);
    }
    const clearFilter = () => {
        document.getElementById("search").value = '';
        document.getElementById("status").value = '';
        setSortConfig({key: '', direction: ''});
        getListData();
        setSearch(false);
        setCurrentPage(1);
    }
/*--------------- clear filter --------------------*/
  return (
    <>
      <CCol xs={12}>
      <CRow className="align-items-center mb-2">
          <CCol xs={3}></CCol>
             <CCol xs={6}>
                <CToast autohide={true} delay={5000} visible={showAlert} color={`${color && color==='failure' ? 'danger' : 'success' }`} animation={true} className="text-white align-items-center">
                              <div className="d-flex">
                                <CToastBody>{showMessage}</CToastBody>
                                <CToastClose className="me-2 m-auto" />
                              </div>
                  </CToast>
             </CCol>
          <CCol xs={3}></CCol>
       </CRow>
        <CCard className="mb-4 customCardTable">
          <CCardHeader>
          <CRow className="align-items-center">
          <CCol xs={12} xl={12}>
          <CRow className="align-items-center">
          <CCol xs={3} className='mobilemarginSpace'><CFormInput type="text" name="search" id="search" placeholder="Search ..." aria-label="default input example"/></CCol>
              <CCol xs={3} className='mobilemarginSpace'>
                    <CFormSelect aria-label="Default select example" name="status" id="status">
                        <option value="">Filter By Status</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                    </CFormSelect>
              </CCol>
              <CCol xs={6} className="customOneFilters mobBigbtn">
              <div className=''>
              <CButton className='searchBtn' onClick={() => isFilter()}>Search</CButton>
              <CButton className='refreshBtn' variant='outline' onClick={() => { clearFilter() }}>Clear</CButton>
                </div>
              <div className='rightAddBtn'>
              <NavLink to={`${process.env.PUBLIC_URL}/admin/master/add-opportunity-class`} className="btnAdd">
              <CButton color="success"><FaPlus className='me-1'/>Add Opportunity Class</CButton></NavLink>
              </div>
              </CCol>
             </CRow>
            </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
          <div className='table-reposnive'>
              <CTable bordered>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell scope="col">#</CTableHeaderCell>
                    <CTableHeaderCell scope="col" onClick={() => sortColumn('name')}><CIcon icon={sortConfig.direction && sortConfig.direction === 'asc' && sortConfig.key === 'name' ? cilArrowTop : cilArrowBottom} className="me-2" />Opportunity Class</CTableHeaderCell>
                    <CTableHeaderCell scope="col" onClick={() => sortColumn('root_cause_required')}><CIcon icon={sortConfig.direction && sortConfig.direction === 'asc' && sortConfig.key === 'root_cause_required' ? cilArrowTop : cilArrowBottom} className="me-2" />Root Cause Required</CTableHeaderCell>
                    <CTableHeaderCell scope="col" onClick={() => sortColumn('status')}><CIcon icon={sortConfig.direction && sortConfig.direction === 'asc' && sortConfig.key === 'status' ? cilArrowTop : cilArrowBottom} className="me-2" />Status</CTableHeaderCell>
                    <CTableHeaderCell scope="col" onClick={() => sortColumn('created_at')}><CIcon icon={sortConfig.direction && sortConfig.direction === 'asc' && sortConfig.key === 'created_at' ? cilArrowTop : cilArrowBottom} className="me-2" />Created At</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Action</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {
                    data && data.length > 0 ? data.map((item,index)=>{
                              
                              return <CTableRow key={index+1}>
                                <CTableHeaderCell scope="row">{startingSerialNumber+index}</CTableHeaderCell>
                                <CTableDataCell>{item.name}</CTableDataCell>
                                <CTableDataCell style={{textTransform: 'capitalize'}}>{item.root_cause_required}</CTableDataCell>
                                <CTableDataCell>
                                   <CButton color={item.status && item.status == 1 ? 'success' : 'danger'} variant="ghost" onClick={() => { changeStatus(item.id, item.status) }}>{item.status && item.status == 1 ? 'Active' : 'Inactive'}</CButton>
                                
                                  </CTableDataCell>
                                  
                                <CTableDataCell>{item.created_at}</CTableDataCell>
                                <CTableDataCell className="actionBtn actionBtnReport">
                                    <NavLink to={`${process.env.PUBLIC_URL}/admin/master/edit-opportunity-class/${item.id}`}>
                                    <CTooltip content="Edit"  placement="top">
                                      <CButton  className='editBtn'> <FaPencilAlt/> </CButton>
                                    </CTooltip>
                                      </NavLink>
                                      <CTooltip content="Delete" placement="top">
                                    <CButton color="danger" onClick={() => deleteData(item.id)} className='trashBtn'><FaTrashAlt/></CButton>
                                    </CTooltip>
                                </CTableDataCell>
                            </CTableRow>
                           
                       })
                       : <CTableRow>
                            <CTableDataCell colSpan="20" className="noDataFound">{loading ? 'Loading ...' : 'No records were found'}</CTableDataCell>
                       </CTableRow>
                  }
                  
                </CTableBody>
              </CTable>
              </div>
              <CRow className="align-items-center showPageNum">
                  {
                    totalRecord && totalRecord > 10 ?
                     <>
                            <CCol xs={4} >
                              <p>Showing {((currentPage-1)*dataPerPage+1)} to {indexOfLastData < totalRecord ? indexOfLastData : totalRecord} of {totalRecord} entries</p>
                            </CCol>
                            <CCol xs={4} ></CCol>
                            <CCol xs={4} >
                                <CPagination align="end" aria-label="Page navigation example">
                                          <Pagination
                                              currentPage={currentPage}
                                              totalPages={Math.ceil(totalRecord/dataPerPage)}
                                              onPageChange={paginate}
                                              dataPerPage={dataPerPage}
                                          />
                                      </CPagination> 
                              
                            </CCol>
                      </>
                    : 
                    null
                  }
             </CRow>
              
              
             
              
          </CCardBody>
         
        </CCard>
      </CCol>
    </>
  )
}

export default List
