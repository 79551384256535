import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './views/pages/login/Login';
import ForgotPassword from './views/pages/login/ForgotPassword';
import ResetPassword from './views/pages/login/ResetPassword';
import Dashboard from './views/pages/admin/dashboard/Dashboard';
import MasterLayoutProtected from './views/pages/admin/layout/MasterLayoutProtected';
import UsersList from './views/pages/admin/users/List';
import AddUser from './views/pages/admin/users/Add';
import EditUser from './views/pages/admin/users/Edit';
import Profile from './views/pages/admin/account/Profile';
import ChangePassword from './views/pages/admin/account/ChangePassword';
import CustomersList from './views/pages/admin/masters/customer/List';
import AddCustomer from './views/pages/admin/masters/customer/Add';
import EditCustomer from './views/pages/admin/masters/customer/Edit';
import SuppliersList from './views/pages/admin/masters/supplier/List';
import AddSupplier from './views/pages/admin/masters/supplier/Add';
import EditSupplier from './views/pages/admin/masters/supplier/Edit';
import FieldsList from './views/pages/admin/masters/fields/List';
import AddField from './views/pages/admin/masters/fields/Add';
import EditField from './views/pages/admin/masters/fields/Edit';
import AddressList from './views/pages/admin/masters/address/List';
import AddAddress from './views/pages/admin/masters/address/Add';
import EditAddress from './views/pages/admin/masters/address/Edit';
import OpportunityClassList from './views/pages/admin/masters/opportunityClass/List';
import AddOpportunityClass from './views/pages/admin/masters/opportunityClass/Add';
import EditOpportunityClass from './views/pages/admin/masters/opportunityClass/Edit';
import ProblemClassList from './views/pages/admin/masters/problemClass/List';
import AddProblemClass from './views/pages/admin/masters/problemClass/Add';
import EditProblemClass from './views/pages/admin/masters/problemClass/Edit';
import ProblemCategoryList from './views/pages/admin/masters/problemCategory/List';
import AddProblemCategory from './views/pages/admin/masters/problemCategory/Add';
import EditProblemCategory from './views/pages/admin/masters/problemCategory/Edit';
import ProblemDetailList from './views/pages/admin/masters/problemDetail/List';
import AddProblemDetail from './views/pages/admin/masters/problemDetail/Add';
import EditProblemDetail from './views/pages/admin/masters/problemDetail/Edit';
import managementAllocationList from './views/pages/admin/masters/managementAllocation/List';
import ReportsList from './views/pages/admin/reports/List';
import AddReport from './views/pages/admin/reports/Add';
import EditReport from './views/pages/admin/reports/Edit';
import ViewReport from './views/pages/admin/reports/View';
import Page404 from './views/pages/404/Page404';
import MasterLayout from './views/pages/admin/layout/MasterLayout';

const Navigation = () => {
  
  return (
    <>
    <Routes>
              <Route exact path={`${process.env.PUBLIC_URL}/forgot-password`} element={<ForgotPassword  title={`${process.env.REACT_APP_SITE_NAME} | Forgot Password`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/reset-password/:link`} element={<ResetPassword  title= {`${process.env.REACT_APP_SITE_NAME} | Reset Password`} />} />
              
              <Route exact path={`${process.env.PUBLIC_URL}/admin/users`} element={<MasterLayoutProtected cmp={UsersList} title={`${process.env.REACT_APP_SITE_NAME} | Users List`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/add-user`} element={<MasterLayoutProtected cmp={AddUser} title={`${process.env.REACT_APP_SITE_NAME} | Add User`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/edit-user/:id`} element={<MasterLayoutProtected cmp={EditUser} title={`${process.env.REACT_APP_SITE_NAME} | Add Edit`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/master/customers`} element={<MasterLayoutProtected cmp={CustomersList} title={`${process.env.REACT_APP_SITE_NAME} | Customer List`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/master/add-customer`} element={<MasterLayoutProtected cmp={AddCustomer} title={`${process.env.REACT_APP_SITE_NAME} | Add Customer`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/master/edit-customer/:id`} element={<MasterLayoutProtected cmp={EditCustomer} title={`${process.env.REACT_APP_SITE_NAME} | Edit Customer`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/master/suppliers`} element={<MasterLayoutProtected cmp={SuppliersList} title={`${process.env.REACT_APP_SITE_NAME} | Suppliers List`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/master/add-supplier`} element={<MasterLayoutProtected cmp={AddSupplier} title={`${process.env.REACT_APP_SITE_NAME} | Add Supplier`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/master/edit-supplier/:id`} element={<MasterLayoutProtected cmp={EditSupplier} title={`${process.env.REACT_APP_SITE_NAME} | Edit Supplier`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/master/fields`} element={<MasterLayoutProtected cmp={FieldsList} title={`${process.env.REACT_APP_SITE_NAME} | Fields List`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/master/add-field`} element={<MasterLayoutProtected cmp={AddField} title={`${process.env.REACT_APP_SITE_NAME} | Add Field`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/master/edit-field/:id`} element={<MasterLayoutProtected cmp={EditField} title={`${process.env.REACT_APP_SITE_NAME} | Edit Field`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/master/address`} element={<MasterLayoutProtected cmp={AddressList} title={`${process.env.REACT_APP_SITE_NAME} | Address List`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/master/add-address`} element={<MasterLayoutProtected cmp={AddAddress} title={`${process.env.REACT_APP_SITE_NAME} | Add Address`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/master/edit-address/:id`} element={<MasterLayoutProtected cmp={EditAddress} title={`${process.env.REACT_APP_SITE_NAME} | Edit Address`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/master/opportunity-classes`} element={<MasterLayoutProtected cmp={OpportunityClassList} title={`${process.env.REACT_APP_SITE_NAME} | Opportunity Class List`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/master/add-opportunity-class`} element={<MasterLayoutProtected cmp={AddOpportunityClass} title={`${process.env.REACT_APP_SITE_NAME} | Add Opportunity Class`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/master/edit-opportunity-class/:id`} element={<MasterLayoutProtected cmp={EditOpportunityClass} title={`${process.env.REACT_APP_SITE_NAME} | Edit Opportunity Class`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/master/problem-classes`} element={<MasterLayoutProtected cmp={ProblemClassList} title={`${process.env.REACT_APP_SITE_NAME} | Problem Class List`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/master/add-problem-class`} element={<MasterLayoutProtected cmp={AddProblemClass} title={`${process.env.REACT_APP_SITE_NAME} | Add Problem Class`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/master/edit-problem-class/:id`} element={<MasterLayoutProtected cmp={EditProblemClass} title={`${process.env.REACT_APP_SITE_NAME} | Edit Problem Class`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/master/problem-categories`} element={<MasterLayoutProtected cmp={ProblemCategoryList} title={`${process.env.REACT_APP_SITE_NAME} | Problem Category List`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/master/add-problem-category`} element={<MasterLayoutProtected cmp={AddProblemCategory} title={`${process.env.REACT_APP_SITE_NAME} | Add Problem Category`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/master/edit-problem-category/:id`} element={<MasterLayoutProtected cmp={EditProblemCategory} title={`${process.env.REACT_APP_SITE_NAME} | Edit Problem Category`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/master/problem-details`} element={<MasterLayoutProtected cmp={ProblemDetailList} title={`${process.env.REACT_APP_SITE_NAME} | Problem Detail List`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/master/add-problem-detail`} element={<MasterLayoutProtected cmp={AddProblemDetail} title={`${process.env.REACT_APP_SITE_NAME} | Add Problem Detail`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/master/edit-problem-detail/:id`} element={<MasterLayoutProtected cmp={EditProblemDetail} title={`${process.env.REACT_APP_SITE_NAME} | Edit Problem Detail`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/master/management-allocation`} element={<MasterLayoutProtected cmp={managementAllocationList} title={`${process.env.REACT_APP_SITE_NAME} | Management Allocation List`} />} />
             
              <Route exact path={`${process.env.PUBLIC_URL}/admin/dashboard`} element={<MasterLayout cmp={Dashboard} title={`${process.env.REACT_APP_SITE_NAME} | Dashboard`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/reports`} element={<MasterLayout cmp={ReportsList} title={`${process.env.REACT_APP_SITE_NAME} | Reports List`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/add-report`} element={<MasterLayout cmp={AddReport} title={`${process.env.REACT_APP_SITE_NAME} | Add Report`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/edit-report/:id`} element={<MasterLayout cmp={EditReport} title={`${process.env.REACT_APP_SITE_NAME} | Edit Report`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/view-report/:id`} element={<MasterLayout cmp={ViewReport} title={`${process.env.REACT_APP_SITE_NAME} | View Report`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/profile`} element={<MasterLayout cmp={Profile} title={`${process.env.REACT_APP_SITE_NAME} | Profile`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/admin/change-password`} element={<MasterLayout cmp={ChangePassword} title={`${process.env.REACT_APP_SITE_NAME} | Change Password`} />} />
              <Route exact path={`${process.env.PUBLIC_URL}/`} element={<Login  title={`${process.env.REACT_APP_SITE_NAME} | Login`} />} />
              <Route path="*" element={<Page404  title={`Page Not Found`} />} />
        </Routes>
    </>
  )
}

export default Navigation
