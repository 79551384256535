import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import { CBadge } from '@coreui/react'
import { getLoggedInUserRole } from '../../../../Helpers';

export const AppSidebarNav = ({ items }) => {
  const[showLeftMenu, setLeftMenu] = useState('');
  useEffect(() => {
     let sLoggedInUserRole = getLoggedInUserRole();
     setLeftMenu(sLoggedInUserRole);
  }, [showLeftMenu]);
  
  const location = useLocation()
  const navLink = (name, icon, badge) => {
    return (
      <>
        {icon && icon}
        {name && name}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </>
    )
  }

  const navItem = (item, index) => {
    const { component, name, badge, icon, ...rest } = item
    const Component = component
    
    return (
      
        showLeftMenu && (showLeftMenu == "superadmin") ?
            <Component
              {...(rest.to &&
                !rest.items && {
                  component: NavLink,
                })}
              key={index}
              {...rest}
            >
              {navLink(name, icon, badge)}
            </Component>
        : 
        (
          showLeftMenu && showLeftMenu !== "superadmin" && (item.name == 'Dashboard' || item.name == 'Reports') ?
              <Component
                    {...(rest.to &&
                      !rest.items && {
                        component: NavLink,
                      })}
                    key={index}
                    {...rest}
                  >
                    {navLink(name, icon, badge)}
              </Component>
          : null
      )
    )
  }
  const navGroup = (item, index) => {
    const { component, name, icon, to, ...rest } = item
    const Component = component
   
    return (
      showLeftMenu && (showLeftMenu == "superadmin") ?
          <Component
            idx={String(index)}
            key={index}
            toggler={navLink(name, icon)}
            visible={location.pathname.startsWith(to)}
            {...rest}
          >
            {item.items?.map((item, index) =>
              item.items ? navGroup(item, index) : navItem(item, index),
            )}
          </Component>
       :
       null
    )
  }

  return (
    <React.Fragment>
      {items &&
        items.map((item, index) => (item.items ? navGroup(item, index) : navItem(item, index)))}
    </React.Fragment>
  )
}

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
}
