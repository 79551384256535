import React,{useState, useEffect} from 'react'
import {useNavigate, NavLink} from 'react-router-dom'
import axios from 'axios';
import {endPoints} from '../../../../../Constants';
import {getAccessToken, writeDocumentTitle} from '../../../../../Helpers';
import {FaPlus, FaRegEye,FaPencilAlt,FaTrashAlt,FaSyncAlt,FaFileDownload } from 'react-icons/fa';

import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CPagination,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CToast,
  CToastBody,
  CToastClose,CTooltip
} from '@coreui/react'
import Pagination from '../../layout/Pagination';
import { cilArrowBottom, cilArrowTop } from '@coreui/icons';
import CIcon from '@coreui/icons-react';


const List = (props) => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  useEffect(()=>{
       getListData();
     
  },[]);
  useEffect(()=>{
    writeDocumentTitle(props.title);
  },[]);
  
   /*--------------- custom pagination ----------------*/
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [showAlert, setShowAlert] = useState(false);
    const [showMessage, setShowMessage] = useState('');
    const [color, setColor] = useState('');
    const [totalRecord, setTotalRecord] = useState('');
    const [visible, setVisible] = useState(false);
    const [opportunityClassId, setOpportunityClassId] = useState('');
    const [opportunityClassName, setOpportunityClassName] = useState('');
    const [userAllocation, setUserAllocation] = useState('');
    const [adminUsersList, setAdminUsersList] = useState([]);
    const [ loading, setLoading ] = useState(true);
    
    const [validation, setValidation] = useState({
        opportunityClassId:'',
        userId:''
    });
    
    let indexOfLastData = currentPage * dataPerPage;
    let indexOfFirstData = indexOfLastData - dataPerPage;
    //const currentData = data.slice(indexOfFirstData, indexOfLastData);
    
    const paginate = (number) => {
          filterListData(number,sortConfig.key,sortConfig.direction);
          setCurrentPage(number);
          
    };
  /*--------------- custom pagination ----------------*/
  
  /*---------------  Handle column sorting ----------------*/
        
        const sortColumn = (key) => {
          let direction = 'asc';
          if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
          }
          filterListData(currentPage,key,direction);
          setSortConfig({ key, direction });
        };
   /*---------------  Handle column sorting ----------------*/

/*----------------------- get user data ----------------*/
  const getListData = async () => {
       /*----------------------- get user access token otherwise logout ----------------*/
          let token = getAccessToken();
          if(!!token) {
              const config = {
                  headers: { Authorization: `Bearer ${token}` }
              };
              await axios.get(`${endPoints}management-allocations`,config)
              .then((response)=>{
                    setData(response.data.aLists);
                    setTotalRecord(response.data.iTotalRecords);
                    setAdminUsersList(response.data.aUserLists);
                    setLoading(false);
              })
              .catch((error) => {
                  if(error.response && error.response.status === 401) {
                     localStorage.removeItem('isAdminLoggedIn');
                     navigate(`${process.env.PUBLIC_URL}/`);
                  }
                  setLoading(false);
               })
          } else {
              navigate(`${process.env.PUBLIC_URL}/`);
          }
       /*----------------------- get user access token otherwise logout ----------------*/
       
  } 
/*----------------------- get user data ----------------*/

 /*--------------- filter list data ---------------*/
 const filterListData = (pageNumber='',sortBy='',orderBy='') => {
      /*---------------- get access token --------------*/
          const accessToken = getAccessToken();
          if(!accessToken) {
              localStorage.removeItem('isAdminLoggedIn');
              navigate(`${process.env.PUBLIC_URL}/`);
          }
          const config = {
              headers: { Authorization: `Bearer ${accessToken}` }
          };
      /*---------------- get access token --------------*/
      let inputValues = {pageNumber:pageNumber,sortBy:sortBy,orderBy:orderBy};
      axios.post(`${endPoints}filterManagementAllocationsList`, inputValues,config)
          .then((response) => {
               setData(response.data.aLists);
               setTotalRecord(response.data.iTotalRecords);
          })
          .catch((error) => {
              if(error.response && error.response.status === 401) {
                  localStorage.removeItem('isAdminLoggedIn');
                  navigate(`${process.env.PUBLIC_URL}/`);
              }
          })
}
/*--------------- filter list data ---------------*/

/*--------------- edit data ----------------------*/
   const editData = (opportunityClassId,opportunityClassName,userId) => {
           setOpportunityClassId(opportunityClassId);
           setUserAllocation(userId);
           setOpportunityClassName(opportunityClassName);
   }
/*--------------- edit data ----------------------*/

/*------------- form validation ---------------*/
const checkValidation = () => {
  let errors = {};
  if(!opportunityClassId) {
      errors.opportunityClassId = "Opportunity class is required";
  } 
  if(!userAllocation) {
    errors.userId = "User is required";
  } 
  return errors;
};

/*------------- form validation ---------------*/

/*--------------- handle submit --------------------*/
const handleSubmit = async (e) => {
  e.preventDefault();
  let isErrors = await checkValidation();
  setValidation(isErrors);
  if(Object.keys(isErrors).length === 0) {
        /*---------------- get access token --------------*/
            const accessToken = getAccessToken();
            if(!accessToken) {
                localStorage.removeItem('isAdminLoggedIn');
                navigate(`${process.env.PUBLIC_URL}/`);
            }
            const config = {
                headers: { Authorization: `Bearer ${accessToken}` }
            };
       /*---------------- get access token --------------*/
          setShowAlert(false);
          setColor('');
          let inputValues = {opportunity_class_id:opportunityClassId,user_id:userAllocation};
          axios.post(`${endPoints}add-management-allocations`,inputValues,config)
          .then((response)=>{
            if(response.data.status === true) {
                setVisible(false);
                setShowMessage(response.data.message);
                setShowAlert(true);
                setColor('success');
                filterListData(currentPage,sortConfig.key,sortConfig.direction);
                setUserAllocation('');
            } else {
                setShowMessage(response.data.message);
                setShowAlert(true);
                setColor('failure');
            }           
          })
          .catch(function (error) {
              if(error.response && error.response.status === 401) {
                  localStorage.removeItem('isAdminLoggedIn');
                  navigate(`${process.env.PUBLIC_URL}/`);
              }
          }); 

  } 
  
}
/*--------------- handle submit --------------------*/

  return (
    <>
      <CCol xs={12}>
      <CRow className="align-items-center mb-2">
          <CCol xs={3}></CCol>
             <CCol xs={6}>
                <CToast autohide={true} delay={5000} visible={showAlert} color={`${color && color==='failure' ? 'danger' : 'success' }`} animation={true} className="text-white align-items-center">
                              <div className="d-flex">
                                <CToastBody>{showMessage}</CToastBody>
                                <CToastClose className="me-2 m-auto" />
                              </div>
                  </CToast>
             </CCol>
          <CCol xs={3}></CCol>
       </CRow>
        <CCard className="mb-4 customCardTable">
          <CCardBody>
          <div className='table-reposnive'>
              <CTable bordered>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell scope="col">#</CTableHeaderCell>
                    <CTableHeaderCell scope="col" onClick={() => sortColumn('name')}><CIcon icon={sortConfig.direction && sortConfig.direction === 'asc' && sortConfig.key === 'name' ? cilArrowTop : cilArrowBottom} className="me-2" />Opportunity Class</CTableHeaderCell>
                    <CTableHeaderCell scope="col" onClick={() => sortColumn('status')}><CIcon icon={sortConfig.direction && sortConfig.direction === 'asc' && sortConfig.key === 'status' ? cilArrowTop : cilArrowBottom} className="me-2" />Management Allocation</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Action</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {
                    data && data.length > 0 ? data.map((item,index)=>{
                              
                              return <CTableRow key={index+1}>
                                <CTableHeaderCell scope="row">{++indexOfFirstData}</CTableHeaderCell>
                                <CTableDataCell>{item.name}</CTableDataCell>
                                <CTableDataCell>
                                     {item.first_name} {item.last_name}
                                  </CTableDataCell>
                                  
                                <CTableDataCell className="actionBtn actionBtnReport">
                                    <NavLink onClick={(e) => {setVisible(!visible);editData(item.id,item.name,item.user_id);}} >
                                    <CTooltip content="Edit"  placement="top">
                                      <CButton  className='editBtn'> <FaPencilAlt/> </CButton>
                                    </CTooltip>
                                      </NavLink>
                                </CTableDataCell>
                            </CTableRow>
                           
                       })
                       : <CTableRow>
                            <CTableDataCell colSpan="20" className="noDataFound">{loading ? 'Loading ...' : 'No records were found'}</CTableDataCell>
                       </CTableRow>
                  }
                  
                </CTableBody>
              </CTable>
              </div>
              <CRow className="align-items-center showPageNum">
                  {
                    totalRecord && totalRecord > 10 ?
                     <>
                            <CCol xs={4} >
                              <p>Showing {((currentPage-1)*dataPerPage+1)} to {indexOfLastData < totalRecord ? indexOfLastData : totalRecord} of {totalRecord} entries</p>
                            </CCol>
                            <CCol xs={4} ></CCol>
                            <CCol xs={4} >
                                <CPagination align="end" aria-label="Page navigation example">
                                          <Pagination
                                              currentPage={currentPage}
                                              totalPages={Math.ceil(totalRecord/dataPerPage)}
                                              onPageChange={paginate}
                                              dataPerPage={dataPerPage}
                                          />
                                      </CPagination> 
                              
                            </CCol>
                      </>
                    : 
                    null
                  }
             </CRow>
          </CCardBody>
        {/*---------------- Modal section start here -------------------*/}
              <CModal visible={visible} alignment="center" onClose={() => setVisible(false)}>
                  <CModalHeader>
                    <CModalTitle>Edit Allocation</CModalTitle>
                  </CModalHeader>
                  <CForm onSubmit={handleSubmit}>
                      <CModalBody>
                      <CRow>
                          <CCol xs={12}>
                                <div className="mb-3">
                                  <CFormLabel htmlFor="opportunity_class_id">Opportunity Class<span className="formError">*</span></CFormLabel>
                                  <CFormInput aria-label="Default select example" name="opportunity_class_id" id="opportunity_class_id" value={opportunityClassName} disabled />
                                   <p className="formError">{validation.opportunityClassId ? validation.opportunityClassId :  ''}</p>
                                </div>
                            </CCol>
                            
                            <CCol xs={12}>
                                <div className="mb-3">
                                  <CFormLabel htmlFor="user_id">Management Allocation<span className="formError">*</span></CFormLabel>
                                  <CFormSelect aria-label="Default select example" name="user_id" id="user_id" value={userAllocation} onChange={(e) => setUserAllocation(e.target.value)}>
                                       <option value="">Select User</option>
                                       {
                                           adminUsersList && adminUsersList.length > 0 ? adminUsersList.map((item,index)=><option key={item.id} value={item.id}>{item.first_name} {item.last_name}</option>) : null
                                       }
                                  </CFormSelect>
                                  <p className="formError">{validation.userId ? validation.userId :  ''}</p>
                                </div>
                            </CCol>
                        </CRow>
                      </CModalBody>
                      <CModalFooter>
                        <CButton color="secondary" onClick={() => setVisible(false)}>
                          Close
                        </CButton>
                        <CButton type="submit" color="success">Submit</CButton>
                      </CModalFooter>
                  </CForm>
              </CModal>
          {/*---------------- Modal section end here -------------------*/}
        </CCard>
      </CCol>
    </>
  )
}

export default List
