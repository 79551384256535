import React from 'react'
import { useLocation } from 'react-router-dom'

import routes from './routes'

import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react'

const AppBreadcrumb = () => {
  const currentLocation = useLocation().pathname
  let aCurrentLocation = currentLocation.split('/');
  const getRouteName = (pathname, routes) => {
    let currentRoute = '';
    if(aCurrentLocation[2] && (aCurrentLocation[2].indexOf('edit-') > -1 || aCurrentLocation[2].indexOf('view-') > -1))  {
        currentRoute = routes.find((route) => route.path.split('/')[2] === aCurrentLocation[2])
    } else if(aCurrentLocation[3] && aCurrentLocation[3].indexOf('edit-') > -1) {
        currentRoute = routes.find((route) => route.path.split('/')[3] === aCurrentLocation[3])
    } else {
        currentRoute = routes.find((route) => route.path === pathname)
    }
    
    return currentRoute ? currentRoute.name : false
  }

  const getBreadcrumbs = (location) => {
    const breadcrumbs = []
    const aRouteName = [];
    location.split('/').reduce((prev, curr, index, array) => {
      const currentPathname = `${prev}/${curr}`
      
      const routeName = getRouteName(currentPathname, routes)
      if((aCurrentLocation[2] && aCurrentLocation[2].indexOf('edit-') > -1) || aCurrentLocation[3] && (aCurrentLocation[3].indexOf('edit-') > -1) || (aCurrentLocation[2] && aCurrentLocation[2].indexOf('view-') > -1)) {
        if(!aRouteName.includes(routeName)) {
              routeName &&
              breadcrumbs.push({
                pathname: currentPathname,
                name: routeName,
                active: index + 1 === array.length ? true : false,
              })
              aRouteName.push(routeName);
          }
      } else {
          routeName &&
          breadcrumbs.push({
            pathname: currentPathname,
            name: routeName,
            active: index + 1 === array.length ? true : false,
          })
          aRouteName.push(routeName);
      }
      
       return currentPathname
    })
    return breadcrumbs
  }

  const breadcrumbs = getBreadcrumbs(currentLocation)

  return (
    <CBreadcrumb className="m-0 ms-2">
      {/* <CBreadcrumbItem>{aCurrentLocation[2] ? aCurrentLocation[2].charAt(0).toUpperCase()+ aCurrentLocation[2].slice(1) : '' } {aCurrentLocation[3] ? ' / '+aCurrentLocation[3].charAt(0).toUpperCase()+ aCurrentLocation[3].slice(1) : '' }</CBreadcrumbItem> */}
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          
          (aCurrentLocation[2] && aCurrentLocation[2].indexOf('edit-') > -1) || (aCurrentLocation[3] && aCurrentLocation[3].indexOf('edit-') > -1) || (aCurrentLocation[2] && aCurrentLocation[2].indexOf('view-') > -1) ?  
              <>
                 <CBreadcrumbItem key={index+1}>{aCurrentLocation && aCurrentLocation.length > 4 ? breadcrumb.name.split('/')[0]+' / '+breadcrumb.name.split('/')[1] : breadcrumb.name.split('/')[0]}</CBreadcrumbItem>
                  <CBreadcrumbItem
                    className="active"
                    key={index}
                  >
                  {aCurrentLocation && aCurrentLocation.length > 4 ? breadcrumb.name.split('/')[2] :  breadcrumb.name.split('/')[1]}
                </CBreadcrumbItem>
             </>
           : 
            <>
                 <CBreadcrumbItem key={index+1}>{aCurrentLocation && aCurrentLocation.length > 3 ? breadcrumb.name.split('/')[0]+' / '+breadcrumb.name.split('/')[1] : breadcrumb.name.split('/')[0]}</CBreadcrumbItem>
                <CBreadcrumbItem
                  className="active"
                  key={index}
                >
                  {aCurrentLocation && aCurrentLocation.length > 3 ? breadcrumb.name.split('/')[2] :  breadcrumb.name.split('/')[1]}
                </CBreadcrumbItem>
             </>
        )
      })}
    </CBreadcrumb>
  )
}

export default React.memo(AppBreadcrumb)
