import { cilArrowLeft } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CForm, CFormInput, CFormLabel, CFormSelect, CFormTextarea, CRow, CToast, CToastBody, CToastClose,CFooter } from '@coreui/react'
import axios from 'axios'
import React,{useState, useEffect} from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { endPoints } from '../../../../Constants'
import { getAccessToken, writeDocumentTitle } from '../../../../Helpers'

const Edit = (props) => {
      const [inputValues, setInputValue] = useState({
            first_name:'',
            last_name:'',
            email:'',
            mobile:'',
            username:'',
            password:'',
            user_role:'user',
            status:'1',
            comment:''
      });
      const [validation, setValidation] = useState({
            first_name:'',
            last_name:'',
            email:'',
            mobile:'',
            username:'',
            password:'',
            user_role:'',
            status:'',
            comment:''
      });
      const [showAlert, setShowAlert] = useState(false);
      const [showMessage, setShowMessage] = useState('');
      const [color, setColor] = useState('');
      const navigate = useNavigate();
      const params = useParams();
      const {id}   = params;
      
      const handleChange = (e) => {
          const { name, value } = e.target;
          setInputValue({ ...inputValues, [name]: value });
      }
      useEffect(()=>{
              writeDocumentTitle(props.title);
              /*---------------- get access token --------------*/
              const accessToken = getAccessToken();
              if(!accessToken) {
                  localStorage.removeItem('isAdminLoggedIn');
                  navigate(`${process.env.PUBLIC_URL}/`);
              }
              const config = {
                  headers: { Authorization: `Bearer ${accessToken}` }
              };
            /*---------------- get access token --------------*/
            
            axios.get(`${endPoints}edit-user/${id}`,config)
            .then((response)=>{
                if(response.data.aDetail) { 
                    setInputValue(response.data.aDetail);
                }
            })
            .catch((error)=>{
                if(error.response && error.response.status === 401) {
                  localStorage.removeItem('isAdminLoggedIn');
                  navigate(`${process.env.PUBLIC_URL}/`);
                }
            })
      },[]);
    /*------------- form validation ---------------*/
            const checkValidation = () => {
              let errors = {};
              let regexEmail  = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
              let regexPassword = /^(?=.{8,}$)(?=.*?[a-z])(?=.*?[0-9])(?=.*?\W).*$/;
              let numberRegexAllow = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
              let numberRegex = /^\d+$/;
              if(!inputValues.first_name.trim()) {
                 errors.first_name = "First name is required";
              } 
              if(!inputValues.last_name.trim()) {
                 errors.last_name = "Last name is required";
              } 
              if(!inputValues.email.trim()) {
                errors.email = "Email is required";
              } else if(!!inputValues.email && !regexEmail.test(inputValues.email)) {
                errors.email = "Please enter valid email";
              }
              if(!inputValues.mobile) {
                 errors.mobile = "Mobile number is required";
              } else if(inputValues.mobile && !numberRegexAllow.test(inputValues.mobile)) {
                 errors.mobile = "Mobile number should be only number";
              } else if(inputValues.mobile && (inputValues.mobile.length < 4 || inputValues.mobile.length > 15)) {
                 errors.mobile = "Mobile number should be between 4 to 15 digits";
              }
              if(!inputValues.username.trim()) {
                errors.username = "Username is required";
              }
              if(!!inputValues.password && inputValues.password.trim() && !regexPassword.test(inputValues.password)){ 
                errors.password = "Password must have contain at least one lowercase letter, one number, one special character and minimum 8 characters in length";
              }
              return errors;
          };
    
  /*------------- form validation ---------------*/
      
  /*------------ handle form submit ----------*/
        const handleSubmit = async (e) => {
          e.preventDefault();
          let isErrors = await checkValidation(inputValues);
          setValidation(isErrors);
          if(Object.keys(isErrors).length === 0) {
                /*---------------- get access token --------------*/
                    const accessToken = getAccessToken();
                    if(!accessToken) {
                        localStorage.removeItem('isAdminLoggedIn');
                        navigate(`${process.env.PUBLIC_URL}/`);
                    }
                    const config = {
                        headers: { Authorization: `Bearer ${accessToken}` }
                    };
               /*---------------- get access token --------------*/
                  setShowAlert(false);
                  setColor('');
                  setInputValue({...inputValues,id:id});
                  axios.put(`${endPoints}update-user`,inputValues,config)
                  .then((response)=>{
                    if(response.data.status === true) {
                        setShowMessage(response.data.message);
                        setShowAlert(true);
                        setColor('success');
                        setTimeout(()=>{
                           navigate(`${process.env.PUBLIC_URL}/admin/users`);
                        },1500);
                    } else {
                        setShowMessage(response.data.message);
                        setShowAlert(true);
                        setColor('failure');
                    }           
                  })
                  .catch(function (error) {
                      if(error.response && error.response.status === 401) {
                          localStorage.removeItem('isAdminLoggedIn');
                          navigate(`${process.env.PUBLIC_URL}/`);
                      }
                  }); 
    
          } 
          
      }
  /*------------ handle form submit ----------*/
  return (
     <>
       <CRow className="align-items-center mb-2">
          <CCol xs={3}></CCol>
             <CCol xs={6}>
                <CToast autohide={true} delay={5000} visible={showAlert} color={`${color && color==='failure' ? 'danger' : 'success' }`} animation={true} className="text-white align-items-center">
                              <div className="d-flex">
                                <CToastBody>{showMessage}</CToastBody>
                                <CToastClose className="me-2 m-auto" />
                              </div>
                  </CToast>
             </CCol>
          <CCol xs={3}></CCol>
       </CRow>
       <CRow>
        
          <CCol xs={12}>
            <CCard className="mb-4">
              <CCardHeader>
               <CRow className="align-items-center">
                  <CCol xs={8} ><h5 className="cardHeading">Edit User</h5></CCol>
                  <CCol xs={4} className="text-end">
                      <NavLink to={`${process.env.PUBLIC_URL}/admin/users`} className="linkText"><CIcon icon={cilArrowLeft} className="me-2" />Back</NavLink>
                  </CCol>
              </CRow>
                
              </CCardHeader>
              <CCardBody>
                <CForm onSubmit={handleSubmit}>
                   <CRow>
                      <CCol xs={12} md={6} xl={6}>
                            <div className="mb-3">
                              <CFormLabel htmlFor="first_name">First Name<span className="formError">*</span></CFormLabel>
                              <CFormInput
                                type="text"
                                id="first_name"
                                name="first_name"
                                placeholder="First Name"
                                onChange={(e) => handleChange(e)} 
                                value={inputValues.first_name}
                              />
                              <p className="formError">{validation.first_name ? validation.first_name :  ''}</p>
                            </div>
                        </CCol>
                        <CCol xs={12} md={6} xl={6}>
                        <div className="mb-3">
                          <CFormLabel htmlFor="last_name">Last Name<span className="formError">*</span></CFormLabel>
                          <CFormInput
                            type="text"
                            id="last_name"
                            name="last_name"
                            placeholder="Last Name"
                            onChange={(e) => handleChange(e)}
                            value={inputValues.last_name}
                          />
                          <p className="formError">{validation.last_name ? validation.last_name :  ''}</p>
                        </div>
                        </CCol>
                        <CCol xs={12} md={6} xl={6}>
                          <div className="mb-3">
                            <CFormLabel htmlFor="email">Email<span className="formError">*</span></CFormLabel>
                            <CFormInput
                              type="text"
                              id="email"
                              name="email"
                              placeholder="Email"
                              onChange={(e) => handleChange(e)}
                              value={inputValues.email}
                            />
                            <p className="formError">{validation.email ? validation.email :  ''}</p>
                          </div>
                        </CCol>
                        <CCol xs={12} md={6} xl={6}>
                        <div className="mb-3">
                          <CFormLabel htmlFor="mobile">Mobile<span className="formError">*</span></CFormLabel>
                          <CFormInput
                            type="text"
                            id="mobile"
                            name="mobile"
                            placeholder="Mobile"
                            onChange={(e) => handleChange(e)}
                            value={inputValues.mobile}
                            
                          />
                          <p className="formError">{validation.mobile ? validation.mobile :  ''}</p>
                        </div>
                        </CCol>
                        <CCol xs={12} md={6} xl={6}>
                        <div className="mb-3">
                          <CFormLabel htmlFor="username">Username<span className="formError">*</span></CFormLabel>
                          <CFormInput
                            type="text"
                            id="username"
                            name="username"
                            placeholder="Username"
                            onChange={(e) => handleChange(e)}
                            value={inputValues.username}
                          />
                          <p className="formError">{validation.username ? validation.username :  ''}</p>
                        </div>
                        </CCol>
                        <CCol xs={12} md={6} xl={6}>
                        <div className="mb-3">
                          <CFormLabel htmlFor="password" >Password</CFormLabel>
                          <CFormInput
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Password"
                            onChange={(e) => handleChange(e)}
                            value={inputValues.password}
                          />
                          <p className="formError">{validation.password ? validation.password :  ''}</p>
                        </div>
                        </CCol>
                        <CCol xs={12} md={6} xl={6}>
                          <div className="mb-3">
                            <CFormLabel htmlFor="user_role">User Role<span className="formError">*</span></CFormLabel>
                            <CFormSelect aria-label="Default select example" value={inputValues.user_role} name="user_role" id="user_role" onChange={(e) => handleChange(e)}> 
                                <option value="user">User</option>
                                <option value="admin">Admin</option>
                            </CFormSelect>
                          </div>
                        </CCol>
                        <CCol xs={12} md={6} xl={6}>
                            <div className="mb-3">
                              <CFormLabel htmlFor="status">Status<span className="formError">*</span></CFormLabel>
                              <CFormSelect aria-label="Default select example" value={inputValues.status} name="status" id="status" onChange={(e) => handleChange(e)}>
                                  <option value="1">Active</option>
                                  <option value="0">Inactive</option>
                              </CFormSelect>
                            </div>
                        </CCol>
                        <CCol xs={12}>
                           <div className="mb-3">
                              <CFormLabel htmlFor="comment">Comment</CFormLabel>
                              <CFormTextarea id="comment" name="comment" rows={3} onChange={(e) => handleChange(e)} value={inputValues.comment}></CFormTextarea>
                          </div>
                        </CCol>
                     </CRow>
                   
                    <CFooter className='cardFooter'>
                         <CButton type="submit" color="success">Update</CButton>
                    </CFooter>
                  </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
     </>
   )
}

export default Edit
