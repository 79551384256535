import { CPaginationItem } from '@coreui/react';
import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange, dataPerPage }) => {
  //const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  const totalPagesGenerate  = totalPages;
  // const pageNumbers = Array.from({ length: totalPagesGenerate }, (_, i) => i + 1);
  
    const pageNumbers = [];

    // Show 5 page numbers after the "..." (dot dot)
    const totalAdjacentPageNumbers = 3;

    if (totalPagesGenerate <= totalAdjacentPageNumbers + 2) {
      // If total pages is less than or equal to 7, show all page numbers
      for (let i = 1; i <= totalPagesGenerate; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage <= Math.ceil(totalAdjacentPageNumbers / 2) + 1) {
      // If current page is in the first group of page numbers
      for (let i = 1; i <= totalAdjacentPageNumbers + 2; i++) {
        pageNumbers.push(i);
      }
      pageNumbers.push('DOTS');
      pageNumbers.push(totalPagesGenerate);
    } else if (currentPage >= totalPagesGenerate - Math.floor(totalAdjacentPageNumbers / 2)) {
      // If current page is in the last group of page numbers
      pageNumbers.push(1);
      pageNumbers.push('DOTS');
      for (let i = totalPagesGenerate - (totalAdjacentPageNumbers + 1); i <= totalPagesGenerate; i++) {
        pageNumbers.push(i);
      }
    } else {
      // If current page is in the middle group of page numbers
      pageNumbers.push(1);
      pageNumbers.push('DOTS');
      for (let i = currentPage - Math.floor(totalAdjacentPageNumbers / 2); i <= currentPage + Math.floor(totalAdjacentPageNumbers / 2); i++) {
        pageNumbers.push(i);
      }
      pageNumbers.push('DOTS');
      pageNumbers.push(totalPages);
    }
     
  return (
      <>
     
      <CPaginationItem aria-label="Previous" onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}>
                  <span aria-hidden="true">&laquo;</span>
      </CPaginationItem>
      
      {pageNumbers.map((number,index) => {
        if (number === 'DOTS') {
            return <CPaginationItem  key={index} className="pagination-item_dots">...</CPaginationItem>
        }
        return (<CPaginationItem
          key={index}
          onClick={() => onPageChange(number)}
          disabled={currentPage === number}
          className={currentPage === number ? 'active' : ''}
          
         >
          {number}
        </CPaginationItem>);
})}
      
        <CPaginationItem aria-label="Next" onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}>
                    <span aria-hidden="true">&raquo;</span>
        </CPaginationItem>
     </>
  );
};

export default Pagination;
