import { CButton, CCard, CCardBody, CCardHeader, CCol, CForm, CFormInput, CFormLabel, CFormSelect, CFormTextarea, CRow, CToast, CToastBody, CToastClose,CFooter } from '@coreui/react'
import axios from 'axios'
import React,{useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { endPoints } from '../../../../Constants'
import { getAccessToken, writeDocumentTitle } from '../../../../Helpers'
import { decryptData } from '../../../../util'

const ChangePassword = (props) => {
      const [inputValues, setInputValue] = useState({
            old_password:'',
            new_password:'',
            confirm_new_password:'',
            id:''
      });
      const [validation, setValidation] = useState({
            old_password:'',
            new_password:'',
            confirm_new_password:''
      });
      const [showAlert, setShowAlert] = useState(false);
      const [showMessage, setShowMessage] = useState('');
      const [color, setColor] = useState('');
      //const [id, setId] = useState('');
      const navigate = useNavigate();
      
      const handleChange = (e) => {
          const { name, value } = e.target;
          setInputValue({ ...inputValues, [name]: value });
      }
      useEffect(()=>{
              writeDocumentTitle(props.title);
              /*---------------- get access token --------------*/
              let sIsAdminLoggedIn = localStorage.getItem('isAdminLoggedIn');
              let salt = process.env.REACT_APP_API_KEY;
              let oIsAdminLoggedIn = !!sIsAdminLoggedIn ? decryptData(sIsAdminLoggedIn,salt) : '';
              
              if(!oIsAdminLoggedIn && !oIsAdminLoggedIn['token']) {
                  localStorage.removeItem('isAdminLoggedIn');
                  navigate(`${process.env.PUBLIC_URL}/`);
              }
              const config = {
                  headers: { Authorization: `Bearer ${oIsAdminLoggedIn['token']}` }
              };
              let id = !!oIsAdminLoggedIn['id'] ?  oIsAdminLoggedIn['id'] : '';
              setInputValue({ ...inputValues, id: id });
              //setId(id);
              
            /*---------------- get access token --------------*/
      },[]);
    /*------------- form validation ---------------*/
            const checkValidation = () => {
              let errors = {};
              let regex = /^(?=.{8,}$)(?=.*?[a-z])(?=.*?[0-9])(?=.*?\W).*$/;
              if(!inputValues.old_password.trim()) {
                 errors.old_password = "Current password is required";
              }
              if(!inputValues.new_password.trim()) {
                 errors.new_password = "New password is required";
              } else if(!!inputValues.new_password && inputValues.new_password.trim() && !regex.test(inputValues.new_password)) { 
                errors.new_password = "Password must have contain at least one lowercase letter, one number, one special character and minimum 8 characters in length";
              }
              if(!inputValues.confirm_new_password.trim()) {
                errors.confirm_new_password = "Confirm new password is required";
              } else if(inputValues.new_password.trim() !== inputValues.confirm_new_password.trim()) {
                errors.confirm_new_password = "New password and confirm new password does not match";
              }
              return errors;
          };
    
  /*------------- form validation ---------------*/
      
  /*------------ handle form submit ----------*/
        const handleSubmit = async (e) => {
          e.preventDefault();
          let isErrors = await checkValidation(inputValues);
          setValidation(isErrors);
          if(Object.keys(isErrors).length === 0) {
                /*---------------- get access token --------------*/
                    const accessToken = getAccessToken();
                    if(!accessToken) {
                        localStorage.removeItem('isAdminLoggedIn');
                        navigate(`${process.env.PUBLIC_URL}/`);
                    }
                    const config = {
                        headers: { Authorization: `Bearer ${accessToken}` }
                    };
               /*---------------- get access token --------------*/
                  setShowAlert(false);
                  setColor('');
                  //setInputValue({...inputValues,id:id});
                  
                  axios.post(`${endPoints}change-password`,inputValues,config)
                  .then((response)=>{
                    if(response.data.status === true) {
                        setShowMessage(response.data.message);
                        setShowAlert(true);
                        setColor('success');
                        setInputValue({
                          old_password:'',
                          new_password:'',
                          confirm_new_password:''
                       });
                    } else {
                        setShowMessage(response.data.message);
                        setShowAlert(true);
                        setColor('failure');
                    }           
                  })
                  .catch(function (error) {
                      if(error.response && error.response.status === 401) {
                          localStorage.removeItem('isAdminLoggedIn');
                          navigate(`${process.env.PUBLIC_URL}/`);
                      }
                  }); 
    
          } 
          
      }
  /*------------ handle form submit ----------*/
  return (
     <>
       <CRow className="align-items-center mb-2">
          <CCol xs={3}></CCol>
             <CCol xs={6}>
                <CToast autohide={true} delay={5000} visible={showAlert} color={`${color && color==='failure' ? 'danger' : 'success' }`} animation={true} className="text-white align-items-center">
                              <div className="d-flex">
                                <CToastBody>{showMessage}</CToastBody>
                                <CToastClose className="me-2 m-auto" />
                              </div>
                  </CToast>
             </CCol>
          <CCol xs={3}></CCol>
       </CRow>
       <CRow>
        
          <CCol xs={12}>
            <CCard className="mb-4">
              <CCardHeader>
               <CRow className="align-items-center">
                  <CCol xs={12} ><h5 className="cardHeading">Change Password</h5></CCol>
              </CRow>
              </CCardHeader>
              <CCardBody>
               
                  <CForm onSubmit={handleSubmit}>
                    <CRow>
                       <CCol xs={4}>
                        <div className="mb-3">
                          <CFormLabel htmlFor="old_password">Current Password<span className="formError">*</span></CFormLabel>
                          <CFormInput
                            type="password"
                            id="old_password"
                            name="old_password"
                            placeholder="Current Password"
                            onChange={(e) => handleChange(e)} 
                            value={inputValues.old_password}
                          />
                          <p className="formError">{validation.old_password ? validation.old_password :  ''}</p>
                        </div>
                        </CCol>
                        <CCol xs={4}>
                        <div className="mb-3">
                          <CFormLabel htmlFor="new_password">New Password<span className="formError">*</span></CFormLabel>
                          <CFormInput
                            type="password"
                            id="new_password"
                            name="new_password"
                            placeholder="New Password"
                            onChange={(e) => handleChange(e)}
                            value={inputValues.new_password}
                          />
                          <p className="formError">{validation.new_password ? validation.new_password :  ''}</p>
                        </div>
                        </CCol>
                        <CCol xs={4}>
                        <div className="mb-3">
                          <CFormLabel htmlFor="email">Confirm New Password<span className="formError">*</span></CFormLabel>
                          <CFormInput
                            type="password"
                            id="confirm_new_password"
                            name="confirm_new_password"
                            placeholder="Confirm New Password"
                            onChange={(e) => handleChange(e)}
                            value={inputValues.confirm_new_password}
                          />
                          <p className="formError">{validation.confirm_new_password ? validation.confirm_new_password :  ''}</p>
                        </div>
                        </CCol>
                        <CFooter className='cardFooter'>
                            <CButton type="submit" color="success">Update</CButton>
                        </CFooter>
                    </CRow>
                  </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
     </>
   )
}

export default ChangePassword
