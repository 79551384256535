import { cilArrowLeft } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CFooter, CForm, CFormCheck, CFormInput, CFormLabel, CFormSelect, CFormTextarea, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow, CSpinner, CToast, CToastBody, CToastClose, CTooltip } from '@coreui/react'
import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { endPoints } from '../../../../Constants'
import { getAccessToken, writeDocumentTitle } from '../../../../Helpers'

const Add = (props) => {
  const [ inputValues, setInputValue ] = useState({
    date: "",
    site: "",
    site_detail: "",
    site_detail2: "",
    issue_description: "",
    opportunity_class_id: "",
    problem_class_id: "",
    reportable: "",
    problem_category_id: "",
    problem_detail_id: "",
    document_number: "",
    root_cause_required: "",
    immediate_action_description: "",
    root_cause_description: "",
    corrective_action_description: "",
    assign_to: "",
    opportunity_status: "Open",
    verification_of_effectiveness: "",
    quality_cost: "",
    comment:"",
    is_related_to_work: ""
  });
  const [ validation, setValidation ] = useState({
    date: "",
    site: "",
    site_detail: "",
    site_detail2: "",
    issue_description: "",
    opportunity_class_id: "",
    problem_class_id: "",
    reportable: "",
    problem_category_id: "",
    problem_detail_id: "",
    document_number: "",
    root_cause_required: "",
    immediate_action_description: "",
    root_cause_description: "",
    corrective_action_description: "",
    assign_to: "",
    opportunity_status: "",
    verification_of_effectiveness: "",
    quality_cost: "",
    comment:"",
    is_related_to_work:""
  });
  
  
  const [ opportunityClassList, setOpportunityClassList ] = useState([]);
  const [ problemClassList, setProblemClassList ] = useState([]);
  const [ problemCategoriesList, setProblemCategoriesList ] = useState([]);
  const [ problemDetailList, setProblemDetailList ] = useState([]);
  const [ managementAllocation, setManagementAllocation ] = useState(null);
  const [ showAlert, setShowAlert ] = useState(false);
  const [ showMessage, setShowMessage ] = useState('');
  const [ color, setColor ] = useState('');
  const [ selectedRootCause, setSelectedRootCause ] = useState('');
  const [ selectedReportable, setSelectedReportable ] = useState('');
  const [ site, setSite ] = useState([]);
  const [ siteDetail, setSiteDetail ] = useState([]);
  const [ siteDetail2, setSiteDetail2 ] = useState([]);
  const [ siteDetailAndSiteDetail2ShowHide, setSiteDetailAndSiteDetail2ShowHide ] = useState(true);
  const [ reportableShowHide, setReportableShowHide ] = useState(false);
  const [ documentNumberShowHide, setDocumentNumberShowHide ] = useState(false);
  const [ problemCategoryShowHide, setProblemCategoryShowHide ] = useState(false);
  const [ problemDetailShowHide, setProblemDetailShowHide ] = useState(true);
  const [ isDisabled, setIsDisabled ] = useState(true);
  const [ isRootCauseDisabled, setRootCauseDisabled ] = useState(true);
  const [ opportunityClassId, setOpportunityClassId ] = useState(true);
  const [ usersList, setUsersList ] = useState([]);
  const [ selectedAssignUser, setSelectedAssignUser ] = useState([]);
  const [ isWorkRelated, setIsWorkRelated ] = useState(false);
  const [loader, setLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  const [siteType, setSiteType] = useState("");
  const [validateSiteDetailORSiteDetail2, setValidateSiteDetailORSiteDetail2] = useState("");
  const [inputValuesSiteDetailAndSiteDetail2, setInputValuesSiteDetailAndSiteDetail2 ] = useState({
       newSiteDetail:"",
       newSiteDetail2:""
  });
  const [validationSiteDetailAndSiteDetail2, setValidationSiteDetailAndSiteDetail2 ] = useState({
       newSiteDetail:"",
       newSiteDetail2:""
  });
  const [ showSiteDetail2AddBtn, setShowSiteDetail2AddBtn ] = useState(false);
  const [ showAnotherAlert, setShowAnotherAlert ] = useState(false);
  const [ showAnotherMessage, setShowAnotherMessage ] = useState();
  const [ anotherColor, setAnotherColor ] = useState('');
  const [anotherLoader, setAnotherLoader] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (e) => {
    //const files = Array.from(e.target.files);
    setSelectedFiles(e.target.files);
  };
  
  const navigate = useNavigate();
  const handleChange = (e) => {
      const { name, value } = e.target;
      if(name && name === "opportunity_class_id") {
          let oInputValues = inputValues;
          if(inputValues.problem_class_id) {
              oInputValues.problem_class_id = "";
          }
          if(inputValues.problem_category_id) {
              oInputValues.problem_category_id = "";
          }
          if(inputValues.problem_detail_id) {
              oInputValues.problem_detail_id = "";
          }
          setInputValue(oInputValues);
       }
       if(name && name === "problem_class_id") {
           let oInputValues = inputValues;
           if(inputValues.problem_category_id) {
                oInputValues.problem_category_id = "";
           }
       }
       setInputValue({ ...inputValues, [ name ]: value });
  }
  
  const assignedReportToUser = (event) => {
         const selectedValues = Array.from(event.target.selectedOptions, (option) => option.value);
         setSelectedAssignUser(selectedValues);
         setInputValue({ ...inputValues, assign_to: selectedValues });
  }
  const handleCheckboxChange = (value) => {
    setSelectedRootCause(value);
    setInputValue({ ...inputValues, root_cause_required: value });
    if (value && value === 'yes') {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
    //setRootCauseDisabled(true);
  }
  const handleCheckboxChangeReportable = (value) => {
    setSelectedReportable(value);
    setInputValue({ ...inputValues, reportable: value });
  }


  useEffect(() => {
     writeDocumentTitle(props.title);
    /*---------------- get access token --------------*/
      const accessToken = getAccessToken();
      if(!accessToken) {
        localStorage.removeItem('isAdminLoggedIn');
        navigate(`${process.env.PUBLIC_URL}/`);
      }
      const config = {
         headers: { Authorization: `Bearer ${accessToken}` }
      };
    /*---------------- get access token --------------*/

    /*---------------- get site ---------------------*/
    axios.get(`${endPoints}site`, config)
      .then((response) => {
        if (response.data.status === true) {
          setSite(response.data.aSiteList);
          setUsersList(response.data.aUsersList);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('isAdminLoggedIn');
          navigate(`${process.env.PUBLIC_URL}/`);
        }
      })
    /*---------------- get site ---------------------*/

    /*---------------- get site detail ---------------------*/
    axios.get(`${endPoints}opportunityClassesList`, config)
      .then((response) => {
         console.log({response});
        if (response.data.status === true) {
          setOpportunityClassList(response.data.aLists);

        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('isAdminLoggedIn');
          navigate(`${process.env.PUBLIC_URL}/`);
        }
      })
    /*---------------- get site detail ---------------------*/
  }, []);
  
  
 
  /*------------- form validation ---------------*/
  const checkValidation = () => {
        let errors = {};
        let numberRegex = /^\d+$/;
        if (!inputValues.date) {
           errors.date = "Date is required";
        }
        if (!inputValues.site) {
          errors.site = "Site is required";
        }
        if(inputValues.site && (inputValues.site ==='1' || inputValues.site ==='4')) {
            if (!inputValues.site_detail) {
              errors.site_detail = "Site detail is required";
            }
            if (!inputValues.site_detail2) {
              errors.site_detail2 = "Site detail 2 is required";
            }
        } else if(!inputValues.site){
            if (!inputValues.site_detail) {
              errors.site_detail = "Site detail is required";
            }
            if (!inputValues.site_detail2) {
              errors.site_detail2 = "Site detail 2 is required";
            }
        } 
        
        if(inputValues.opportunity_class_id && +inputValues.opportunity_class_id === 4 && (!inputValues.assign_to || inputValues.assign_to.length === 0)) {
                errors.assign_to = "Assign to is required";
        }
        /*------------------- validate opportunity class, problem class,  problem category or problem detail --------------*/
          if(inputValues.opportunity_class_id) {
                if(inputValues.opportunity_class_id && (inputValues.opportunity_class_id != 2)) {
                      if (!inputValues.problem_class_id) {
                             errors.problem_class_id = "Problem class is required";
                      }
                      if(inputValues.opportunity_class_id != 4 && inputValues.opportunity_class_id != 6  && inputValues.opportunity_class_id != 11) {
                         if((inputValues.problem_class_id && (inputValues.problem_class_id!=28 && inputValues.problem_class_id!=43)) || !inputValues.problem_class_id) {
                            if (!inputValues.problem_detail_id) {
                                errors.problem_detail_id = "Problem detail is required";
                            }
                        }
                     }
                     if(inputValues.problem_class_id && (inputValues.problem_class_id == 27 || inputValues.problem_class_id == 43)) {
                          if (!inputValues.problem_category_id) {
                             errors.problem_category_id = "Problem category is required";
                          }
                     }
                }
                if(inputValues.opportunity_class_id == 7) {
                     if (!inputValues.is_related_to_work) {
                       errors.is_related_to_work = "Please select related";
                     }
                }
          }
          
        /*------------------- validate opportunity class, problem class,  problem category or problem detail --------------*/
        if(!!inputValues.quality_cost && !numberRegex.test(inputValues.quality_cost)) {
            errors.quality_cost = "Quality cost should be only number";
        }
        return errors;
  };

  /*------------- form validation ---------------*/

  /*------------ handle form submit ----------*/
  const handleSubmit = async (e) => {
    e.preventDefault();
    let isErrors = await checkValidation(inputValues);
    setValidation(isErrors);
    
    
    if (Object.keys(isErrors).length === 0) {
      setLoader(true);
      /*---------------- get access token --------------*/
      const accessToken = getAccessToken();
      if (!accessToken) {
        localStorage.removeItem('isAdminLoggedIn');
        navigate(`${process.env.PUBLIC_URL}/`);
      }
      const config = {
        headers: { Authorization: `Bearer ${accessToken}`,'Content-Type': 'multipart/form-data', }
      };
      /*---------------- get access token --------------*/
      
      setShowAlert(false);
      setColor('');
      const formData = new FormData();
      Object.keys(inputValues).forEach((item) => {
          formData.append(item, inputValues[item]);
      });
      if(selectedFiles.length > 0) {
          for (let i = 0; i < selectedFiles.length; i++) {
            formData.append(`uploadPhoto[${i}]`, selectedFiles[i]);
          }
      }
      
      axios.post(`${endPoints}add-report`, formData, config)
        .then((response) => {
          if (response.data.status === true) {
            setShowMessage(response.data.message);
            setShowAlert(true);
            setColor('success');
            setInputValue({
                  date: "",
                  site: "",
                  site_detail: "",
                  site_detail2: "",
                  issue_description: "",
                  opportunity_class_id: "",
                  problem_class_id: "",
                  reportable: "",
                  problem_category_id: "",
                  problem_detail_id: "",
                  document_number: "",
                  root_cause_required: "",
                  immediate_action_description: "",
                  root_cause_description: "",
                  corrective_action_description: "",
                  opportunity_status: "Open",
                  verification_of_effectiveness: "",
                  quality_cost: "",
                  is_related_to_work: ""
            });
            setSiteDetail([]);
            setSiteDetail2([]);
            setProblemClassList([]);
            setProblemCategoriesList([]);
            setProblemCategoriesList([]);
            setProblemDetailList([]);
            setSelectedAssignUser([]);
            setManagementAllocation('');
            setSelectedRootCause('');
            setSelectedReportable('');
            setLoader(false);
            
          } else {
            setShowMessage(response.data.message);
            setShowAlert(true);
            setColor('failure');
            setLoader(false);
          }
          
        })
        .catch(function (error) {
          if (error.response && error.response.status === 401) {
            localStorage.removeItem('isAdminLoggedIn');
            navigate(`${process.env.PUBLIC_URL}/`);
          }
          setLoader(false);
        });
         // Scroll to the top of the page
         window.scrollTo({
            top: 0,
             behavior: 'smooth' // Add smooth scrolling effect
         });
        // Scroll to the top of the page

    }

  }
  /*------------ handle form submit ----------*/

  /*------------ get problem class by opportunity class --------------*/
  const getProblemClassCategoryAndDetailByOpportunityClass = (e) => {
    
    let opportunity_class_id = e.target.value;

    /*---------------- get access token --------------*/
    const accessToken = getAccessToken();
    if (!accessToken) {
      localStorage.removeItem('isAdminLoggedIn');
      navigate(`${process.env.PUBLIC_URL}/`);
    }
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };
    /*---------------- get access token --------------*/
    setIsDisabled(true);
    setDocumentNumberShowHide(false);
    setRootCauseDisabled(true);
    setProblemDetailShowHide(true);
    setProblemCategoryShowHide(false);
    if(opportunity_class_id == 7) {
        setIsWorkRelated(true);
    } else {
        setIsWorkRelated(false);
    }
    
    let inputValue = { opportunity_class_id: opportunity_class_id };
    axios.post(`${endPoints}getProblemClassCategoryAndDetailList`, inputValue, config)
      .then((response) => {
        if (response.data.status === true) {
          let rootCauseRequired = response.data.opportunityDetail.root_cause_required;
          let opportunity_class_id = response.data.opportunityDetail.id;
          setProblemClassList(response.data.aProblemClassesLists);
          setProblemCategoriesList(response.data.aProblemCategoryLists);
          setProblemDetailList(response.data.aProblemDetailLists);
          setSelectedRootCause(rootCauseRequired);
          
          setManagementAllocation(`${response.data.opportunityDetail.first_name} ${response.data.opportunityDetail.last_name}`);
          setOpportunityClassId(opportunity_class_id);
          if (opportunity_class_id && (opportunity_class_id == '4' || opportunity_class_id == '6')) {
            setDocumentNumberShowHide(true);
            setProblemDetailShowHide(false);
          }
          if (rootCauseRequired && rootCauseRequired == 'yes') {
            if (opportunity_class_id && opportunity_class_id != '7') {
              setIsDisabled(false);
            }
          }
          if (opportunity_class_id && opportunity_class_id == '5') {
            setRootCauseDisabled(false);
          }
          if (opportunity_class_id && (opportunity_class_id == '2' || opportunity_class_id == '11')) {
              setProblemDetailShowHide(false);
          }
          
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('isAdminLoggedIn');
          navigate(`${process.env.PUBLIC_URL}/`);
        }
      })
  }
  /*------------ get problem class by opportunity class --------------*/

  /*------------ get problem category by opportunity and problem class --------------*/
  const getProblemCategoryByOpportunityAndProblemClass = (e) => {
    let opportunity_class_id = document.getElementById("opportunity_class_id").value;
    let problem_class_id = e.target.value;
    
    if (!!problem_class_id || !!opportunity_class_id) {
      /*-------------- problem category and reportable section manage if opportunity class is SAFETY --------*/
      if (opportunityClassId && opportunityClassId == '7') {
        // var index = e.nativeEvent.target.selectedIndex;
        // let sProblemClass = e.nativeEvent.target[ index ].text;
        if (problem_class_id && (problem_class_id == '25' || problem_class_id == '26' || problem_class_id == '29')) {
          setReportableShowHide(true);
          setIsDisabled(false);
          setProblemCategoryShowHide(false);
          setSelectedRootCause('yes');
          setProblemDetailShowHide(true);
        } else if (problem_class_id && (problem_class_id == '27')) {
          setProblemCategoryShowHide(true);
          setIsDisabled(true);
          setReportableShowHide(false);
          setSelectedRootCause('no');
          setProblemDetailShowHide(true);
        } else if (problem_class_id && (problem_class_id == '43')) {
          setProblemCategoryShowHide(true);
          setIsDisabled(false);
          setReportableShowHide(false);
          setSelectedRootCause('yes');
          setProblemDetailShowHide(false);
        } else {
          setReportableShowHide(false);
          setIsDisabled(true);
          setProblemCategoryShowHide(false);
          setSelectedRootCause('no');
          setProblemDetailShowHide(false);

        }
      }
      /*-------------- problem category and reportable section manage if opportunity class is SAFETY --------*/

      /*---------------- get access token --------------*/
      const accessToken = getAccessToken();
      if (!accessToken) {
        localStorage.removeItem('isAdminLoggedIn');
        navigate(`${process.env.PUBLIC_URL}/`);
      }
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` }
      };
      /*---------------- get access token --------------*/
      let inputValue = { opportunity_class_id: opportunity_class_id, problem_class_id: problem_class_id };
      axios.post(`${endPoints}getProblemCategoryByOpportunityAndProblemClassList`, inputValue, config)
        .then((response) => {
          if (response.data.status === true) {
            setProblemCategoriesList(response.data.aProblemCategoriesLists);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            localStorage.removeItem('isAdminLoggedIn');
            navigate(`${process.env.PUBLIC_URL}/`);
          }
        })
    }
  }
  /*------------ get problem category by opportunity and problem class --------------*/

  /*------------ get site detail by site ------------*/
  const getSiteDetailBySite = async (e) => {
    let site = e.target.value;
    setSiteType(site);
    setInputValue(prevState => ({
      ...prevState,
      site_detail: '',
      site_detail2: '',
    }));
    
    if (!!site && (site != 1 && site != 4)) {
      setSiteDetailAndSiteDetail2ShowHide(false);
      return;
    } else {
      setSiteDetailAndSiteDetail2ShowHide(true);
    }
    /*---------------- get access token --------------*/
    const accessToken = getAccessToken();
    if (!accessToken) {
      localStorage.removeItem('isAdminLoggedIn');
      navigate(`${process.env.PUBLIC_URL}/`);
    }
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };
    /*---------------- get access token --------------*/
    let inputValue = { site: site };
    await axios.post(`${endPoints}getSiteDetail`, inputValue, config)
      .then((response) => {
        if (response.data.status === true) {
          setSiteDetail(response.data.aSiteDetail);

        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('isAdminLoggedIn');
          navigate(`${process.env.PUBLIC_URL}/`);
        }
      })

  }
  /*------------ get site detail by site ------------*/

  /*------------ get site detail 2 by site and detail ------------*/
  const getSiteDetail2BySiteAndDetail = (e) => {
    let site = document.getElementById("site").value;
    let site_detail = e.target.value;
    if(!!site_detail && site_detail === 'addNew') {
         setValidateSiteDetailORSiteDetail2("siteDetail");
         setVisible(true);
         setShowSiteDetail2AddBtn(false);
         return;
    }
    /*---------------- get access token --------------*/
    const accessToken = getAccessToken();
    if (!accessToken) {
      localStorage.removeItem('isAdminLoggedIn');
      navigate(`${process.env.PUBLIC_URL}/`);
    }
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };
    /*---------------- get access token --------------*/
    let inputValue = { site: site, site_detail: site_detail };
    axios.post(`${endPoints}getSiteDetail2`, inputValue, config)
      .then((response) => {
        if (response.data.status === true) {
          setSiteDetail2(response.data.aSiteDetail2);
        }
        setShowSiteDetail2AddBtn(true);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('isAdminLoggedIn');
          navigate(`${process.env.PUBLIC_URL}/`);
        }
      })

  }
  /*------------ get site detail 2 by site and detail ------------*/

  /*------------ root cause required or not based on problem detail -------------*/
  const getProblemDetailRootCauseRequired = (e) => {
    let problem_detail_id = e.target.value;
    /*---------------- get access token --------------*/
    const accessToken = getAccessToken();
    if (!accessToken) {
      localStorage.removeItem('isAdminLoggedIn');
      navigate(`${process.env.PUBLIC_URL}/`);
    }
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };
    /*---------------- get access token --------------*/
    let inputValue = { problem_detail_id: problem_detail_id };
    axios.post(`${endPoints}getProblemDetailRootCauseRequired`, inputValue, config)
      .then((response) => {
        if (response.data.status === true) {
          let root_cause_required = response.data.aProblemDetail.root_cause_required;
          if (root_cause_required && root_cause_required === 'yes') {
            setIsDisabled(false);
            setSelectedRootCause('yes');
          } else {
            setIsDisabled(true);
            setSelectedRootCause('no');
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('isAdminLoggedIn');
          navigate(`${process.env.PUBLIC_URL}/`);
        }
      })
  }
  /*------------ root cause required or not based on problem detail -------------*/
  
  /*------------ get problem detail by opportunity, problem and category --------*/
  const getProblemDetail = (e) => {
    let opportunity_class_id = document.getElementById("opportunity_class_id").value;
    let problem_class_id = document.getElementById("problem_class_id").value;
    let problem_category_id = document.getElementById("problem_category_id") ? document.getElementById("problem_category_id").value : '';
    
            /*---------------- get access token --------------*/
              const accessToken = getAccessToken();
              if(!accessToken) {
                  localStorage.removeItem('isAdminLoggedIn');
                  navigate(`${process.env.PUBLIC_URL}/`);
              }
              const config = {
                  headers: { Authorization: `Bearer ${accessToken}` }
              };
            /*---------------- get access token --------------*/
            let inputData = {opportunity_class_id,problem_class_id,problem_category_id};
            axios.post(`${endPoints}getProblemDetailByOpportunityProblemAndCategoryClass`,inputData,config)
            .then((response)=>{
                if(response.data.status === true) { 
                     setProblemDetailList(response.data.aProblemDetailLists);
                }
            })
            .catch((error)=>{
                if(error.response && error.response.status === 401) {
                  localStorage.removeItem('isAdminLoggedIn');
                  navigate(`${process.env.PUBLIC_URL}/`);
                }
            })
}
/*------------ get problem detail by opportunity, problem and category --------*/


/*----------- add new site detail and site detail 2 -------------------*/
          const resetFormSiteDetailAndSiteDetail2 = () => {
                setInputValuesSiteDetailAndSiteDetail2({
                     newSiteDetail:"",
                     newSiteDetail2:""
                });
                setValidationSiteDetailAndSiteDetail2({
                    newSiteDetail:"",
                    newSiteDetail2:""
                });
                setShowAnotherAlert(false);
          }
          
          const addNewSiteDetail2  = (e) => {
              let site_detail2 = e.target.value;
              if(!!site_detail2 && site_detail2 === 'addNew') {
                  setValidateSiteDetailORSiteDetail2("siteDetail2");
                  setVisible(true);
              }
         } 
        /*------------- form validation ---------------*/
            const checkFormValidation = () => {
                let errors = {};
                if(validateSiteDetailORSiteDetail2 && validateSiteDetailORSiteDetail2 === "siteDetail") {
                   if(!inputValuesSiteDetailAndSiteDetail2.newSiteDetail) {
                       errors.newSiteDetail = siteType && siteType === "1" ? "Customer name is required" : "Supplier name is required";
                   }
                } else {
                   if(!inputValuesSiteDetailAndSiteDetail2.newSiteDetail2) {
                      errors.newSiteDetail2 = siteType && siteType === "1" ? "Field is required" : "Address is required";
                    }
                }
                return errors;
              };

        /*------------- form validation ---------------*/
          const handleChangeSiteDetailAndSiteDetail2 = (e) => {
             const { name, value } = e.target;
             setInputValuesSiteDetailAndSiteDetail2({ ...inputValuesSiteDetailAndSiteDetail2, [ name ]: value });
          }
          
          
          const handleSiteDetailAndSiteDetail2Submit = async (e) => {
                    e.preventDefault();
                    let isErrors = await checkFormValidation(inputValuesSiteDetailAndSiteDetail2);
                    setValidationSiteDetailAndSiteDetail2(isErrors);
                    let inputValueData = '';
                    if(validateSiteDetailORSiteDetail2 && validateSiteDetailORSiteDetail2 === "siteDetail2") {
                         let site_detail = document.getElementById("site_detail").value;
                         inputValueData = { ...inputValuesSiteDetailAndSiteDetail2,newSiteDetail:site_detail, siteType:siteType,type:validateSiteDetailORSiteDetail2 };
                    } else {
                         inputValueData = { ...inputValuesSiteDetailAndSiteDetail2,siteType:siteType,type:validateSiteDetailORSiteDetail2 };
                    }
                    if(Object.keys(isErrors).length === 0) {
                            setAnotherLoader(true);
                            setShowAnotherAlert(false);
                          /*---------------- get access token --------------*/
                              const accessToken = getAccessToken();
                              if(!accessToken) {
                                  localStorage.removeItem('isAdminLoggedIn');
                                  navigate(`${process.env.PUBLIC_URL}/`);
                              }
                              const config = {
                                  headers: { Authorization: `Bearer ${accessToken}` }
                              };
                            /*---------------- get access token --------------*/
                            axios.post(`${endPoints}addSiteDetailOrSiteDetail2`,inputValueData,config)
                            .then((response)=>{
                                if(response.data.status === true) { 
                                      let {aSiteDetail,aSiteDetail2} = response.data;
                                      setAnotherColor('success');
                                      aSiteDetail && setSiteDetail(aSiteDetail);
                                      aSiteDetail2 && setSiteDetail2(aSiteDetail2);
                                      setInputValuesSiteDetailAndSiteDetail2({
                                          newSiteDetail:"",
                                          newSiteDetail2:""
                                      });
                                } else {
                                       setAnotherColor('failure');
                                }
                                 setAnotherLoader(false);
                                 setShowAnotherMessage(response.data.message);
                                 setShowAnotherAlert(true);
                            })
                            .catch((error)=>{
                                if(error.response && error.response.status === 401) {
                                  localStorage.removeItem('isAdminLoggedIn');
                                  navigate(`${process.env.PUBLIC_URL}/`);
                                }
                            })
                    }
          }
/*----------- add new site detail and site detail 2 -------------------*/

  return (
    <>
      <CRow className="align-items-center mb-2">
        <CCol xs={3}></CCol>
        <CCol xs={6}>
          <CToast autohide={true} delay={5000} visible={showAlert} color={`${color && color === 'failure' ? 'danger' : 'success'}`} animation={true} className="text-white align-items-center">
            <div className="d-flex">
              <CToastBody>{showMessage}</CToastBody>
              <CToastClose className="me-2 m-auto" />
            </div>
          </CToast>
        </CCol>
        <CCol xs={3}></CCol>
      </CRow>
      <CRow>

        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <CRow className="align-items-center">
                <CCol xs={8} ><h5 className="cardHeading">New Report</h5></CCol>
                <CCol xs={4} className="text-end">
                  <NavLink to={`${process.env.PUBLIC_URL}/admin/reports`} className='linkText'><CIcon icon={cilArrowLeft} className="me-2" />Back</NavLink>
                </CCol>
              </CRow>

            </CCardHeader>
            <CCardBody>

              <CForm onSubmit={handleSubmit} id="reportForm" className='formResponsiveCol'>
                <CRow>
                  <CCol xs={12} md={6} xl={6}>
                    <div className="mb-3">
                      <CFormLabel htmlFor="date">Date<span className="formError">*</span></CFormLabel>
                      <CFormInput
                        type="date"
                        id="date"
                        name="date"
                        onChange={(e) => handleChange(e)}
                        value={inputValues.date ?? ''}
                      />
                      <p className="formError">{validation.date ? validation.date : ''}</p>
                    </div>
                  </CCol>
                  <CCol xs={12} md={6} xl={6}>
                    <div className="mb-3">
                      <CFormLabel htmlFor="site">Site<span className="formError">*</span></CFormLabel>
                      <CFormSelect aria-label="Default select example"
                        name="site"
                        id="site"
                        onChange={(e) => { handleChange(e); getSiteDetailBySite(e); }}
                        value={inputValues.site ?? ''}>
                        <option value="">Select Site</option>
                        {
                          site && site.length > 0 ? site.map((item, index) => <option key={item.id} value={item.id}>{item.name}</option>) : null
                        }

                      </CFormSelect>
                      <p className="formError">{validation.site ? validation.site : ''}</p>
                    </div>
                  </CCol>
                  {
                    siteDetailAndSiteDetail2ShowHide ? <>
                      <CCol xs={12} md={6} xl={6}>
                        <div className="mb-3">
                          <CFormLabel htmlFor="site_detail">Site Detail<span className="formError">*</span></CFormLabel>
                          <CFormSelect aria-label="Default select example"
                            name="site_detail"
                            id="site_detail"
                            onChange={(e) => { handleChange(e); getSiteDetail2BySiteAndDetail(e); }}
                            value={inputValues.site_detail ?? ''}>
                            <option value="">Select Site Detail</option>
                            {
                              siteDetail && siteDetail.length > 0 ? siteDetail.map((item, index) => <option key={item.id} value={item.id}>{item.name}</option>) : null
                            }
                            {(siteType == '1' ||  siteType == '4') && <option value="addNew">Add New</option>} 
                          </CFormSelect>
                          <p className="formError">{validation.site_detail ? validation.site_detail : ''}</p>
                        </div>
                      </CCol>
                      <CCol xs={12} md={6} xl={6}>
                        <div className="mb-3">
                          <CFormLabel htmlFor="site_detail2">Site Detail 2<span className="formError">*</span></CFormLabel>
                          <CFormSelect aria-label="Default select example"
                            name="site_detail2"
                            id="site_detail2"
                            onChange={(e) => { handleChange(e); addNewSiteDetail2(e) }}
                            value={inputValues.site_detail2 ?? ''}>
                            <option value="">Select Site Detail 2</option>
                            {
                              siteDetail2 && siteDetail2.length > 0 ? siteDetail2.map((item, index) => <option key={item.id} value={item.id}>{item.name}</option>) : null
                            }
                            {
                              showSiteDetail2AddBtn ?  <option value="addNew">Add New</option> : null
                            }
                          </CFormSelect>
                          <p className="formError">{validation.site_detail2 ? validation.site_detail2 : ''}</p>
                        </div>
                      </CCol>
                    </> : null
                  }

                  <CCol xs={12} md={6} xl={6}>
                    <div className="mb-3">
                      <CFormLabel htmlFor="issue_description">Issue Description</CFormLabel>
                      <CFormTextarea id="issue_description" name="issue_description" placeholder="Issue Description" rows={2} onChange={(e) => handleChange(e)} value={inputValues.issue_description ?? ''}></CFormTextarea>
                    </div>
                  </CCol>
                  <CCol xs={12} md={6} xl={6}>
                    <div className="mb-3">
                      <CFormLabel htmlFor="opportunity_class_id">Opportunity Class</CFormLabel>
                      <CFormSelect aria-label="Default select example"
                        name="opportunity_class_id"
                        id="opportunity_class_id"
                        onChange={(e) => { handleChange(e); getProblemClassCategoryAndDetailByOpportunityClass(e);}}
                        value={inputValues.opportunity_class_id ?? ''}>
                        <option value="">Select Opportunity Class</option>
                        {
                          opportunityClassList && opportunityClassList.length > 0 ? opportunityClassList.map((item, index) => <option key={item.id} value={item.id}>{item.name}</option>) : null
                        }

                      </CFormSelect>
                      <p className="formError">{validation.opportunity_class_id ? validation.opportunity_class_id : ''}</p>
                    </div>
                  </CCol>
                  {
                    opportunityClassId && opportunityClassId != '2' ? <CCol xs={12} md={6} xl={6}>
                      <div className="mb-3">
                        <CFormLabel htmlFor="problem_class_id">Problem Class</CFormLabel>
                        <CFormSelect aria-label="Default select example"
                          name="problem_class_id"
                          id="problem_class_id"
                          onChange={(e) => { handleChange(e); getProblemCategoryByOpportunityAndProblemClass(e);getProblemDetail(e) }}
                          value={inputValues.problem_class_id ?? ''}>
                          <option value="">Select Problem Class</option>
                          {
                            problemClassList && problemClassList.length > 0 ? problemClassList.map((item, index) => <option key={item.id} value={item.id}>{item.name}</option>) : null
                          }

                        </CFormSelect>
                        <p className="formError">{validation.problem_class_id ? validation.problem_class_id : ''}</p>
                      </div>
                    </CCol>
                      : null
                  }

                  {
                    reportableShowHide ? <CCol xs={12} md={6} xl={6}>
                      <div className="mb-3">
                        <CFormLabel htmlFor="reportable">Reportable?</CFormLabel>
                        <div>
                          <CFormCheck inline id="yes" name="reportable" value="yes" label="Yes" checked={selectedReportable === 'yes'} onChange={() => handleCheckboxChangeReportable('yes')} />
                          <CFormCheck inline id="no" name="reportable" value="no" label="No" checked={selectedReportable === 'no'} onChange={() => handleCheckboxChangeReportable('no')} />
                        </div>
                      </div>
                    </CCol>
                      : null
                  }

                  {
                    documentNumberShowHide ? <CCol xs={12} md={6} xl={6}>
                      <div className="mb-3">
                        <CFormLabel htmlFor="document_number">Document Number</CFormLabel>
                        <CFormInput
                          type="text"
                          id="document_number"
                          name="document_number"
                          placeholder="Document Number"
                          onChange={(e) => handleChange(e)}
                          value={inputValues.document_number ?? ''}
                        />
                        <p className="formError">{validation.document_number ? validation.document_number : ''}</p>
                      </div>
                    </CCol>
                      : null
                  }
                  {
                    problemCategoryShowHide && opportunityClassId != '2' ? <CCol xs={12} md={6} xl={6}>
                      <div className="mb-3">
                        <CFormLabel htmlFor="problem_category_id">Problem Category</CFormLabel>
                        <CFormSelect aria-label="Default select example"
                          name="problem_category_id"
                          id="problem_category_id"
                          onChange={(e) => {handleChange(e); getProblemDetail(e) }}
                          value={inputValues.problem_category_id ?? ''}>
                          <option value="">Select Problem Category</option>
                          {
                            problemCategoriesList && problemCategoriesList.length > 0 ? problemCategoriesList.map((item, index) => <option key={item.id} value={item.id}>{item.name}</option>) : null
                          }

                        </CFormSelect>
                        <p className="formError">{validation.problem_category_id ? validation.problem_category_id : ''}</p>
                      </div>
                    </CCol> : null
                  }

                  {
                    //opportunityClassId && opportunityClassId!='2' && opportunityClassId!='11' && !documentNumberShowHide ?
                    problemDetailShowHide ?
                      <CCol xs={12} md={6} xl={6}>
                        <div className="mb-3">
                          <CFormLabel htmlFor="problem_detail_id">Problem Detail</CFormLabel>
                          <CFormSelect aria-label="Default select example"
                            name="problem_detail_id"
                            id="problem_detail_id"
                            onChange={(e) => { handleChange(e); getProblemDetailRootCauseRequired(e); }}
                            value={inputValues.problem_detail_id ?? ''}>
                            <option value="">Select Problem Detail</option>
                            {
                              problemDetailList && problemDetailList.length > 0 ? problemDetailList.map((item, index) => <option key={item.id} value={item.id}>{item.name}</option>) : null
                            }

                          </CFormSelect>
                          <p className="formError">{validation.problem_detail_id ? validation.problem_detail_id : ''}</p>
                        </div>
                      </CCol>
                      : null
                  }

                 { isWorkRelated &&  <CCol xs={12} md={6} xl={6}>
                        <div className="mb-3">
                          <CFormLabel htmlFor="is_related_to_work">Related</CFormLabel>
                          <CFormSelect aria-label="Default select example"
                            name="is_related_to_work"
                            id="is_related_to_work"
                            onChange={(e) => { handleChange(e); }}
                            value={inputValues.is_related_to_work ?? ''}>
                            <option value="">Select Related</option>
                            <option value="Work-Related">Work-Related</option>
                            <option value="Non-Work-Related">Non-Work-Related</option>
                          </CFormSelect>
                          <p className="formError">{validation.is_related_to_work ? validation.is_related_to_work : ''}</p>
                        </div>
                   </CCol> }


                  <CCol xs={12} md={6} xl={6}>
                    <div className="mb-3">
                      <CFormLabel htmlFor="status">Root Cause Required</CFormLabel>
                      <div>
                        <CFormCheck inline id="yes" name="root_cause_required" value="yes" disabled={isRootCauseDisabled} label="Yes" checked={selectedRootCause === 'yes'} onChange={() => handleCheckboxChange('yes')} />
                        <CFormCheck inline id="no" name="root_cause_required" value="no" label="No" checked={selectedRootCause === 'no'} onChange={() => handleCheckboxChange('no')} disabled={isRootCauseDisabled} />
                      </div>
                      <p className="formError">{validation.root_cause_required ? validation.root_cause_required : ''}</p>
                    </div>
                  </CCol>
                  <CCol xs={12} md={6} xl={6}>
                    <div className="mb-3">
                      <CFormLabel htmlFor="management_allocation">Management Allocation</CFormLabel>
                      <CFormInput
                        type="text"
                        id="management_allocation"
                        name="management_allocation"
                        placeholder="Management Allocation"
                        value={managementAllocation ?? ''}
                        disabled
                      />

                    </div>
                  </CCol>
                  <CCol xs={12} md={6} xl={6}>
                    <div className="mb-3">
                      <CFormLabel htmlFor="immediate_action_description">Immediate Action Description</CFormLabel>
                      <CFormTextarea id="immediate_action_description " name="immediate_action_description" placeholder="Immediate Action Description" disabled={isDisabled} rows={2} onChange={(e) => handleChange(e)} value={inputValues.immediate_action_description ?? ''} className="customTextarea"></CFormTextarea>
                    </div>
                  </CCol>
                  <CCol xs={12} md={6} xl={6}>

                    <div className="mb-3">
                      <CFormLabel htmlFor="assign_to">Assign To</CFormLabel>
                      <CTooltip
                        content="Press CTRL to select multiple"
                        placement="top"
                      >
                        <CFormSelect htmlSize={5} name="assign_to" id="assign_to" value={selectedAssignUser} onChange={(e) => assignedReportToUser(e)} multiple aria-label="size 3 select example">
                          {
                            usersList && usersList.length > 0 ? usersList.map((item, index) => <option key={item.id} value={item.id}>{item.first_name} {item.last_name}</option>) : null
                          }
                        </CFormSelect>  
                      </CTooltip>
                      <p className="formError">{validation.assign_to ? validation.assign_to : ''}</p>
                    </div>

                  </CCol>

                  <CCol xs={12} md={6} xl={6}>
                    <div className="mb-3">
                      <CFormLabel htmlFor="root_cause_description">Root Cause Description</CFormLabel>
                      <CFormTextarea id="root_cause_description" name="root_cause_description" placeholder="Root Cause Description" disabled={isDisabled} rows={2} onChange={(e) => handleChange(e)} value={inputValues.root_cause_description ?? ''}></CFormTextarea>
                    </div>
                  </CCol>
                  <CCol xs={12} md={6} xl={6}>
                    <div className="mb-3">
                      <CFormLabel htmlFor="corrective_action_description">Corrective Action Description</CFormLabel>
                      <CFormTextarea id="corrective_action_description" name="corrective_action_description" placeholder="Corrective Action Description" disabled={isDisabled} rows={2}  onChange={(e) => handleChange(e)} value={inputValues.corrective_action_description ?? ''}></CFormTextarea>
                    </div>
                  </CCol>
                  <CCol xs={12} md={6} xl={6}>
                    <div className="mb-3">
                      <CFormLabel htmlFor="opportunity_status">Opportunity Status</CFormLabel>
                      <CFormSelect aria-label="Default select example" name="opportunity_status" id="opportunity_status" onChange={(e) => handleChange(e)}>
                        <option value="Open">Open</option>
                        <option value="In Progress">In Progress</option>
                        <option value="Closed">Closed</option>
                      </CFormSelect>
                    </div>
                  </CCol>
                  <CCol xs={12} md={6} xl={6}>
                    <div className="mb-3">
                      <CFormLabel htmlFor="verification_of_effectiveness">Verification of Effectiveness</CFormLabel>
                      <CFormInput
                        type="text"
                        id="verification_of_effectiveness"
                        name="verification_of_effectiveness"
                        placeholder="Verification of Effectiveness"
                        value={inputValues.verification_of_effectiveness ?? ''}
                        disabled={isDisabled}
                        onChange={(e) => handleChange(e)}
                      />

                    </div>
                  </CCol>
                  <CCol xs={12} md={6} xl={6}>
                    <div className="mb-3">
                      <CFormLabel htmlFor="quality_cost">Quality Cost</CFormLabel>
                      <CFormInput
                        type="text"
                        id="quality_cost"
                        name="quality_cost"
                        placeholder="Quality Cost"
                        value={inputValues.quality_cost ?? ''}
                        onChange={(e) => handleChange(e)}
                      />
                      <p className="formError">{validation.quality_cost ? validation.quality_cost : ''}</p>
                    </div>
                  </CCol>
                  <CCol xs={12} md={6} xl={6}>
                    <div className="mb-3">
                      <CFormLabel htmlFor="comment">Comment</CFormLabel>
                      <CFormTextarea id="comment" name="comment" placeholder="Comment" rows={2} onChange={(e) => handleChange(e)} value={inputValues.comment ?? ''}></CFormTextarea>
                    </div>
                  </CCol>
                  

                </CRow>
                <CRow>
                <CCol xs={12} md={6} xl={6}>
                    <div className="mb-3">
                      <CFormLabel htmlFor="uploadPhoto">Photo Upload (Press ctrl to upload multiple photo)</CFormLabel>
                      <CFormInput type="file" id="uploadPhoto" name="uploadPhoto" accept="image/*" onChange={handleFileChange} multiple />
                      <p className="formError">{validation.uploadPhoto ? validation.uploadPhoto : ''}</p>
                    </div>
                  </CCol>
                </CRow>
                <CFooter className='cardFooter'>
                  {
                    loader ? <CSpinner color="success"/> : <CButton type="submit" color="success">Submit</CButton>
                  }
                </CFooter>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      {/*---------------- Modal section start here, to add site and site detail 2 -------------------*/}
      <CModal size="lg" alignment="center" visible={visible} onClose={() => {setVisible(false);resetFormSiteDetailAndSiteDetail2()}}>
                  <CModalHeader>
                    <CModalTitle>{validateSiteDetailORSiteDetail2 === "siteDetail" ? (siteType && siteType === '1' ? 'New Customer' : 'New Supplier') : (siteType && siteType === '1' ? 'New Field' : 'New Address')}</CModalTitle>
                  </CModalHeader>
                  
                  <CForm onSubmit={handleSiteDetailAndSiteDetail2Submit}>
                  <CRow className="align-items-center text-center">
                    <CCol xs={12}>
                      <CToast autohide={true} delay={5000} visible={showAnotherAlert} color={`${anotherColor && anotherColor === 'failure' ? 'danger' : 'success'}`} animation={true} className="text-white align-items-center mx-auto mt-2">
                        <div className="d-flex justify-content-center">
                          <CToastBody>{showAnotherMessage}</CToastBody>
                          <CToastClose className="me-2 m-auto" />
                        </div>
                      </CToast>
                    </CCol>
                  </CRow>
                  <CModalBody>
                    {
                      validateSiteDetailORSiteDetail2 && validateSiteDetailORSiteDetail2 === "siteDetail" &&
                        <CRow>
                            <CCol xs={12} >
                                <CFormLabel htmlFor="newSiteDetail">{siteType && siteType === '1' ? 'Customer Name' : 'Supplier Name' }<span className="formError">*</span></CFormLabel>
                                <CFormInput
                                  type="text"
                                  id="newSiteDetail"
                                  name="newSiteDetail"
                                  placeholder={siteType && siteType === '1' ? 'Customer Name' : 'Supplier Name' }
                                  onChange={(e) => handleChangeSiteDetailAndSiteDetail2(e)}
                                  value={inputValuesSiteDetailAndSiteDetail2.newSiteDetail}
                                />
                                <p className="formError">{validationSiteDetailAndSiteDetail2.newSiteDetail ? validationSiteDetailAndSiteDetail2.newSiteDetail :  ''}</p>
                            </CCol>
                        </CRow>
                       }
                       <CRow>
                          <CCol xs={12}>
                              <CFormLabel htmlFor="newSiteDetail2">{validateSiteDetailORSiteDetail2 && validateSiteDetailORSiteDetail2 === "siteDetail2" ?(siteType && siteType === '1' ? 'Field' : 'Address') : (siteType && siteType === '1' ? 'Field' : 'Address') }</CFormLabel>
                              <CFormInput
                                type="text"
                                id="newSiteDetail2"
                                name="newSiteDetail2"
                                placeholder={siteType && siteType === '1' ? 'Field' : 'Address' }
                                onChange={(e) => handleChangeSiteDetailAndSiteDetail2(e)}
                                value={inputValuesSiteDetailAndSiteDetail2.newSiteDetail2}
                              />
                              <p className="formError">{validationSiteDetailAndSiteDetail2.newSiteDetail2 ? validationSiteDetailAndSiteDetail2.newSiteDetail2 :  ''}</p>
                         </CCol>
                      </CRow>
                      </CModalBody>
                      <CModalFooter>
                       {
                          anotherLoader ? <CSpinner color="success"/> : <CButton type="submit" color="success">Submit</CButton>
                       }
                      </CModalFooter>
                  </CForm>
              </CModal>
    {/*---------------- Modal section start here, to add site and site detail 2 -------------------*/}
    </>
  )
}

export default Add
