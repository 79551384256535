import { cilArrowLeft } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CForm, CFormCheck, CFormInput, CFormLabel, CFormSelect, CFormTextarea, CRow, CToast, CToastBody, CToastClose,CFooter } from '@coreui/react'
import axios from 'axios'
import React,{useState, useEffect} from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { endPoints } from '../../../../../Constants'
import { getAccessToken, writeDocumentTitle } from '../../../../../Helpers'

const Edit = (props) => {
      const [inputValues, setInputValue] = useState({
            opportunity_class_id:'',
            problem_category_id:'',
            problem_class_id:'',
            name:'',
            root_cause_required:'',
            status:'1'
      });
      const [validation, setValidation] = useState({
            opportunity_class_id:'',
            problem_category_id:'',
            problem_class_id:'',
            name:'',
            root_cause_required:'',
            status:'1'
      });
      const [opportunityClassList, setOpportunityClassList] = useState([]);
      const [problemClassList, setProblemClassList] = useState([]);
      const [problemCategoriesList, setProblemCategoriesList] = useState([]);
      const [showAlert, setShowAlert] = useState(false);
      const [showMessage, setShowMessage] = useState('');
      const [color, setColor] = useState('');
      const [selectedRootCause, setSelectedRootCause] = useState('');
      const navigate = useNavigate();
      const params = useParams();
      const {id}   = params;
      
      const handleChange = (e) => {
          const { name, value } = e.target;
          setInputValue({ ...inputValues, [name]: value });
      }
      const handleCheckboxChange = (value) => {
        setSelectedRootCause(value);
        setInputValue({ ...inputValues, root_cause_required: value });
      }
      useEffect(()=>{
              writeDocumentTitle(props.title);
              /*---------------- get access token --------------*/
              const accessToken = getAccessToken();
              if(!accessToken) {
                  localStorage.removeItem('isAdminLoggedIn');
                  navigate(`${process.env.PUBLIC_URL}/`);
              }
              const config = {
                  headers: { Authorization: `Bearer ${accessToken}` }
              };
            /*---------------- get access token --------------*/
            
            axios.get(`${endPoints}edit-problem-detail/${id}`,config)
            .then((response)=>{
                if(response.data.aDetail) { 
                    setInputValue(response.data.aDetail);
                    setSelectedRootCause(response.data.aDetail.root_cause_required);
                    setOpportunityClassList(response.data.aOpportunityClassesLists);
                    setProblemClassList(response.data.aProblemClassesLists);
                    setProblemCategoriesList(response.data.aProblemCategoriesLists);
                }
            })
            .catch((error)=>{
                if(error.response && error.response.status === 401) {
                  localStorage.removeItem('isAdminLoggedIn');
                  navigate(`${process.env.PUBLIC_URL}/`);
                }
            })
      },[]);
      
    /*------------------- reset problem category value when change problem class -------------*/
      useEffect(()=>{
         //setInputValue({...inputValues,problem_category_id:''});
      },[problemCategoriesList]);
      
    /*------------------- reset problem category value when change problem class -------------*/
    
    /*------------- form validation ---------------*/
            const checkValidation = () => {
              let errors = {};
              if(!inputValues.opportunity_class_id) {
                errors.opportunity_class_id = "Opportunity class is required";
              }
              if(!inputValues.problem_class_id) {
                errors.problem_class_id = "Problem class is required";
              }
              if(!inputValues.name) {
                errors.name = "Problem detail is required";
              }  
              if(!inputValues.root_cause_required) {
                errors.root_cause_required = "Root cause is required";
              }
              return errors;
           };
    
  /*------------- form validation ---------------*/
      
  /*------------ handle form submit ----------*/
        const handleSubmit = async (e) => {
          e.preventDefault();
          let isErrors = await checkValidation(inputValues);
          setValidation(isErrors);
          if(Object.keys(isErrors).length === 0) {
                /*---------------- get access token --------------*/
                    const accessToken = getAccessToken();
                    if(!accessToken) {
                        localStorage.removeItem('isAdminLoggedIn');
                        navigate(`${process.env.PUBLIC_URL}/`);
                    }
                    const config = {
                        headers: { Authorization: `Bearer ${accessToken}` }
                    };
               /*---------------- get access token --------------*/
                  setShowAlert(false);
                  setColor('');
                  setInputValue({...inputValues,id:id});
                  axios.put(`${endPoints}update-problem-detail`,inputValues,config)
                  .then((response)=>{
                    if(response.data.status === true) {
                        setShowMessage(response.data.message);
                        setShowAlert(true);
                        setColor('success');
                        setTimeout(()=>{
                           navigate(`${process.env.PUBLIC_URL}/admin/master/problem-details`);
                        },1500);
                    } else {
                        setShowMessage(response.data.message);
                        setShowAlert(true);
                        setColor('failure');
                    }           
                  })
                  .catch(function (error) {
                      if(error.response && error.response.status === 401) {
                          localStorage.removeItem('isAdminLoggedIn');
                          navigate(`${process.env.PUBLIC_URL}/`);
                      }
                  }); 
    
          } 
          
      }
  /*------------ handle form submit ----------*/
  
  /*------------ get problem class by opportunity class --------------*/
  const getProblemClassByOpportunityClass = (e) => {
    let opportunity_class_id = e.target.value;
    if(!!opportunity_class_id) {
             /*---------------- get access token --------------*/
              const accessToken = getAccessToken();
              if(!accessToken) {
                  localStorage.removeItem('isAdminLoggedIn');
                  navigate(`${process.env.PUBLIC_URL}/`);
              }
              const config = {
                  headers: { Authorization: `Bearer ${accessToken}` }
              };
            /*---------------- get access token --------------*/
            let inputValues = {opportunity_class_id:opportunity_class_id};
            axios.post(`${endPoints}getProblemClassByOpportunityClassList`,inputValues,config)
            .then((response)=>{
                 if(response.data.status === true) { 
                      
                      setProblemClassList(response.data.aProblemClassesLists);
                 }
            })
            .catch((error)=>{
                if(error.response && error.response.status === 401) {
                  localStorage.removeItem('isAdminLoggedIn');
                  navigate(`${process.env.PUBLIC_URL}/`);
                }
            })
    }
}
/*------------ get problem class by opportunity class --------------*/

 /*------------ get problem category by opportunity and problem class --------------*/
 const getProblemCategoryByOpportunityAndProblemClass = (e) => {
  let opportunity_class_id = document.getElementById("opportunity_class_id").value;
  let problem_class_id = e.target.value;
  if(!!problem_class_id || !!opportunity_class_id) {
           /*---------------- get access token --------------*/
            const accessToken = getAccessToken();
            if(!accessToken) {
                localStorage.removeItem('isAdminLoggedIn');
                navigate(`${process.env.PUBLIC_URL}/`);
            }
            const config = {
                headers: { Authorization: `Bearer ${accessToken}` }
            };
          /*---------------- get access token --------------*/
          let inputData = {opportunity_class_id:opportunity_class_id,problem_class_id:problem_class_id};
          axios.post(`${endPoints}getProblemCategoryByOpportunityAndProblemClassList`,inputData,config)
          .then((response)=>{
               if(response.data.status === true) { 
                    setProblemCategoriesList(response.data.aProblemCategoriesLists);
                    
               }
          })
          .catch((error)=>{
              if(error.response && error.response.status === 401) {
                localStorage.removeItem('isAdminLoggedIn');
                navigate(`${process.env.PUBLIC_URL}/`);
              }
          })
  }
}
/*------------ get problem category by opportunity and problem class --------------*/
  return (
     <>
       <CRow className="align-items-center mb-2">
          <CCol xs={3}></CCol>
             <CCol xs={6}>
                <CToast autohide={true} delay={5000} visible={showAlert} color={`${color && color==='failure' ? 'danger' : 'success' }`} animation={true} className="text-white align-items-center">
                              <div className="d-flex">
                                <CToastBody>{showMessage}</CToastBody>
                                <CToastClose className="me-2 m-auto" />
                              </div>
                  </CToast>
             </CCol>
          <CCol xs={3}></CCol>
       </CRow>
       <CRow>
        
          <CCol xs={12}>
            <CCard className="mb-4">
              <CCardHeader>
               <CRow className="align-items-center">
                  <CCol xs={8} ><h5 className="cardHeading">Edit Problem Category</h5></CCol>
                  <CCol xs={4} className="text-end">
                      <NavLink to={`${process.env.PUBLIC_URL}/admin/master/problem-details`} className="linkText"><CIcon icon={cilArrowLeft} className="me-2" />Back</NavLink>
                  </CCol>
              </CRow>
                
              </CCardHeader>
              <CCardBody>
               
                  <CForm onSubmit={handleSubmit}>
                  <CRow>
                   <CCol xs={12} md={6} xl={6}>
                            <div className="mb-3">
                              <CFormLabel htmlFor="opportunity_class_id">Opportunity Class<span className="formError">*</span></CFormLabel>
                              <CFormSelect aria-label="Default select example" 
                                name="opportunity_class_id"
                                id="opportunity_class_id"
                                onChange={(e) => {handleChange(e); getProblemClassByOpportunityClass(e); }}
                                value={inputValues.opportunity_class_id}>
                                  <option value="">Select Opportunity Class</option>
                                  {
                                    opportunityClassList && opportunityClassList.length > 0 ? opportunityClassList.map((item,index)=><option key={item.id} value={item.id}>{item.name}</option>) : null
                                  }
                                  
                              </CFormSelect>
                              <p className="formError">{validation.opportunity_class_id ? validation.opportunity_class_id :  ''}</p>
                            </div>
                      </CCol>
                      <CCol xs={12} md={6} xl={6}>
                            <div className="mb-3">
                              <CFormLabel htmlFor="problem_class_id">Problem Class<span className="formError">*</span></CFormLabel>
                              <CFormSelect aria-label="Default select example" 
                                name="problem_class_id"
                                id="problem_class_id"
                                onChange={(e) => {handleChange(e);  getProblemCategoryByOpportunityAndProblemClass(e);}} 
                                value={inputValues.problem_class_id}>
                                  <option value="">Select Problem Class</option>
                                  {
                                    problemClassList && problemClassList.length > 0 ? problemClassList.map((item,index)=><option key={item.id} value={item.id}>{item.name}</option>) : null
                                  }
                                  
                              </CFormSelect>
                              <p className="formError">{validation.problem_class_id ? validation.problem_class_id :  ''}</p>
                            </div>
                      </CCol>
                      <CCol xs={12} md={6} xl={6}>
                            <div className="mb-3">
                              <CFormLabel htmlFor="problem_class_id">Problem Category</CFormLabel>
                              <CFormSelect aria-label="Default select example" 
                                name="problem_category_id"
                                id="problem_category_id"
                                onChange={(e) => handleChange(e)} 
                                value={inputValues.problem_category_id}>
                                  <option value="">Select Problem Class</option>
                                  {
                                    problemCategoriesList && problemCategoriesList.length > 0 ? problemCategoriesList.map((item,index)=><option key={item.id} value={item.id}>{item.name}</option>) : null
                                  }
                                  
                              </CFormSelect>
                              <p className="formError">{validation.problem_category_id ? validation.problem_category_id :  ''}</p>
                            </div>
                      </CCol>
                      <CCol xs={12} md={6} xl={6}>
                            <div className="mb-3">
                              <CFormLabel htmlFor="name">Problem Detail<span className="formError">*</span></CFormLabel>
                              <CFormInput
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Problem Detail"
                                onChange={(e) => handleChange(e)} 
                                value={inputValues.name}
                              />
                              <p className="formError">{validation.name ? validation.name :  ''}</p>
                            </div>
                        </CCol>
                        <CCol xs={12} md={6} xl={6}>
                            <div className="mb-3">
                                 <CFormLabel htmlFor="status">Root Cause Required<span className="formError">*</span></CFormLabel>
                               <div>
                                  <CFormCheck inline id="yes" name="root_cause_required" value="yes" label="Yes" checked={selectedRootCause === 'yes'}  onChange={() => handleCheckboxChange('yes')}  />
                                  <CFormCheck inline id="no" name="root_cause_required" value="no" label="No" checked={selectedRootCause === 'no'} onChange={() => handleCheckboxChange('no')}  />
                                </div>
                                <p className="formError">{validation.root_cause_required ? validation.root_cause_required :  ''}</p>
                            </div>
                        </CCol>
                        
                        <CCol xs={12} md={6} xl={6}>
                            <div className="mb-3">
                              <CFormLabel htmlFor="status">Status<span className="formError">*</span></CFormLabel>
                              <CFormSelect aria-label="Default select example" name="status" id="status" value={inputValues.status} onChange={(e) => handleChange(e)}>
                                  <option value="1">Active</option>
                                  <option value="0">Inactive</option>
                              </CFormSelect>
                            </div>
                        </CCol>
                    </CRow>
                    
                    <CFooter className='cardFooter'>
                         <CButton type="submit" color="success">Update</CButton>
                    </CFooter>
                  </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
     </>
   )
}

export default Edit
