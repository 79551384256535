import React from 'react'
import {
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import {
  cilSettings,
  cilUser,
  cilAccountLogout,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {FaPlus, FaRegEye,FaPencilAlt,FaTrashAlt,FaSyncAlt,FaFileDownload,FaRegUser,FaAngleDown } from 'react-icons/fa';
import { NavLink, useNavigate } from 'react-router-dom'
import { endPoints } from '../../../../Constants'
import { getAccessToken, getLoggedInUserName, getLoggedInUserNameWithRole, getLoggedInUserRole } from '../../../../Helpers'
import axios from 'axios'

const AppHeaderDropdown = () => {
  const navigate = useNavigate();
  const logout = (e) => {
      e.preventDefault();
       /*---------------- get access token --------------*/
          const accessToken = getAccessToken();
          if(!accessToken) {
            localStorage.removeItem('isAdminLoggedIn');
            navigate(`${process.env.PUBLIC_URL}/`);
          }
          const config = {
              headers: { Authorization: `Bearer ${accessToken}` }
          };
      /*---------------- get access token --------------*/
      
      /*------------- destroy user session -----------------*/
            axios.get(`${endPoints}logout`, config)
            .then((response) => {
               if(response.data.status === true) {
                  localStorage.removeItem("isAdminLoggedIn");
                   navigate(`${process.env.PUBLIC_URL}/`);
                }
            })
            .catch((error) => {
              if (error.response && error.response.status === 401) {
                localStorage.removeItem('isAdminLoggedIn');
                navigate(`${process.env.PUBLIC_URL}/`);
              }
            })
      /*------------- destroy user session -----------------*/
      
     
  }
  return (
    <CDropdown variant="nav-item">
    
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
      <div className="header-userinfo">
											<div className="header-media">
											<FaRegUser/>
                      <div className='topRightDropdown'>
                        <FaAngleDown/>
                       </div>
											</div>
											<div className="header-info">
												<h6 className='userName'>{ getLoggedInUserName()}</h6>
												<p className='userRole'>({getLoggedInUserRole()})</p>
											</div>
										</div>
       
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-light fw-semibold py-2">Account</CDropdownHeader>

        {/* <CDropdownItem href="/admin/profile"> */}
          <NavLink to={`${process.env.PUBLIC_URL}/admin/profile`}><CIcon icon={cilUser} className="me-2" />Profile</NavLink>
          
        {/* </CDropdownItem> */}
        {/* <CDropdownItem href=""> */}
          <NavLink to={`${process.env.PUBLIC_URL}/admin/change-password`}><CIcon icon={cilSettings} className="me-2" />
          Change Password</NavLink>
        {/* </CDropdownItem> */}
        <CDropdownDivider />
        <NavLink onClick={logout}>
        <CIcon icon={cilAccountLogout} className="me-2" />
           Logout
        </NavLink>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
