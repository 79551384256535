import React from 'react'

const Dashboard = React.lazy(() => import('../dashboard/Dashboard'))
const UsersList = React.lazy(() => import('../users/List'))
const AddUser = React.lazy(() => import('../users/Add'))
const EditUser = React.lazy(() => import('../users/Edit'))
const Profile = React.lazy(() => import('../account/Profile'))
const ChangePassword = React.lazy(() => import('../account/ChangePassword'))
const CustomerList = React.lazy(() => import('../masters/customer/List'))
const AddCustomer = React.lazy(() => import('../masters/customer/Add'))
const EditCustomer = React.lazy(() => import('../masters/customer/Edit'))
const SuppliersList = React.lazy(() => import('../masters/supplier/List'))
const AddSupplier = React.lazy(() => import('../masters/supplier/Add'))
const EditSupplier = React.lazy(() => import('../masters/supplier/Edit'))
const FieldsList = React.lazy(() => import('../masters/fields/List'))
const AddField = React.lazy(() => import('../masters/fields/Add'))
const EditField = React.lazy(() => import('../masters/fields/Edit'))
const AddressList = React.lazy(() => import('../masters/address/List'))
const AddAddress = React.lazy(() => import('../masters/address/Add'))
const EditAddress = React.lazy(() => import('../masters/address/Edit'))
const OpportunityClassList = React.lazy(() => import('../masters/opportunityClass/List'))
const AddOpportunityClass = React.lazy(() => import('../masters/opportunityClass/Add'))
const EditOpportunityClass = React.lazy(() => import('../masters/opportunityClass/Edit'))
const ProblemClassList = React.lazy(() => import('../masters/opportunityClass/List'))
const AddProblemClass = React.lazy(() => import('../masters/opportunityClass/Add'))
const EditProblemClass = React.lazy(() => import('../masters/opportunityClass/Edit'))
const ProblemCategoryList = React.lazy(() => import('../masters/problemCategory/List'))
const AddProblemCategory = React.lazy(() => import('../masters/problemCategory/Add'))
const EditProblemCategory = React.lazy(() => import('../masters/problemCategory/Edit'))
const ProblemDetailList = React.lazy(() => import('../masters/problemDetail/List'))
const AddProblemDetail = React.lazy(() => import('../masters/problemDetail/Add'))
const EditProblemDetail = React.lazy(() => import('../masters/problemDetail/Edit'))
const managementAllocationList = React.lazy(() => import('../masters/managementAllocation/List'))
const ReportsList = React.lazy(() => import('../reports/List'))
const AddReport = React.lazy(() => import('../reports/Add'))
const EditReport = React.lazy(() => import('../reports/Edit'))
const ViewReport = React.lazy(() => import('../reports/View'))
const routes = [
  { path: `${process.env.PUBLIC_URL}/admin/dashboard`, name: 'Dashboard / Dashboard Info', element: Dashboard },
  { path: `${process.env.PUBLIC_URL}/admin/users`, name: 'Users / Users List', element: UsersList },
  { path: `${process.env.PUBLIC_URL}/admin/add-user`, name: 'Users / Add User', element: AddUser },
  { path: `${process.env.PUBLIC_URL}/admin/edit-user/id`, name: 'Users / Edit User', element: EditUser },
  { path: `${process.env.PUBLIC_URL}/admin/profile`, name: 'Profile / Update Profile', element: Profile },
  { path: `${process.env.PUBLIC_URL}/admin/change-password`, name: 'Change Password / Update Password', element: ChangePassword },
  { path: `${process.env.PUBLIC_URL}/admin/master/customers`, name: 'Master / Customers / Customers List', element: CustomerList },
  { path: `${process.env.PUBLIC_URL}/admin/master/add-customer`, name: 'Master / Customers / Add Customer', element: AddCustomer },
  { path: `${process.env.PUBLIC_URL}/admin/master/edit-customer/id`, name: 'Master / Customers / Edit Customer', element: EditCustomer },
  { path: `${process.env.PUBLIC_URL}/admin/master/suppliers`, name: 'Master / Suppliers/ Suppliers List', element: SuppliersList },
  { path: `${process.env.PUBLIC_URL}/admin/master/add-supplier`, name: 'Master / Suppliers/ Add Supplier', element: AddSupplier },
  { path: `${process.env.PUBLIC_URL}/admin/master/edit-supplier/id`, name: 'Master / Suppliers/ Edit Supplier', element: EditSupplier },
  { path: `${process.env.PUBLIC_URL}/admin/master/fields`, name: 'Master / Fields/ Fields List', element: FieldsList },
  { path: `${process.env.PUBLIC_URL}/admin/master/add-field`, name: 'Master / Fields/ Add Field', element: AddField },
  { path: `${process.env.PUBLIC_URL}/admin/master/edit-field/id`, name: 'Master / Fields/ Edit Field', element: EditField },
  { path: `${process.env.PUBLIC_URL}/admin/master/address`, name: 'Master / Address/ Address List', element: AddressList },
  { path: `${process.env.PUBLIC_URL}/admin/master/add-address`, name: 'Master / Address/ Add Address', element: AddAddress },
  { path: `${process.env.PUBLIC_URL}/admin/master/edit-address/id`, name: 'Master / Address/  Edit Address', element: EditAddress },
  { path: `${process.env.PUBLIC_URL}/admin/master/opportunity-classes`, name: 'Master / Opportunity Class / Opportunity Class List', element: OpportunityClassList },
  { path: `${process.env.PUBLIC_URL}/admin/master/add-opportunity-class`, name: 'Master / Opportunity Class / Add Opportunity Class', element: AddOpportunityClass },
  { path: `${process.env.PUBLIC_URL}/admin/master/edit-opportunity-class/id`, name: 'Master / Opportunity Class / Edit Opportunity Class', element: EditOpportunityClass },
  { path: `${process.env.PUBLIC_URL}/admin/master/problem-classes`, name: 'Master / Problem Class / Problem Class List', element: ProblemClassList },
  { path: `${process.env.PUBLIC_URL}/admin/master/add-problem-class`, name: 'Master / Problem Class / Add Problem Class', element: AddProblemClass },
  { path: `${process.env.PUBLIC_URL}/admin/master/edit-problem-class/id`, name: 'Master / Problem Class / Edit Problem Class', element: EditProblemClass },
  { path: `${process.env.PUBLIC_URL}/admin/master/problem-categories`, name: 'Master / Problem Category / Problem Category List', element: ProblemCategoryList },
  { path: `${process.env.PUBLIC_URL}/admin/master/add-problem-category`, name: 'Master / Problem Category / Add Problem Category', element: AddProblemCategory },
  { path: `${process.env.PUBLIC_URL}/admin/master/edit-problem-category/id`, name: 'Master / Problem Category /  Edit Problem Category', element: EditProblemCategory },
  { path: `${process.env.PUBLIC_URL}/admin/master/problem-details`, name: 'Master / Problem Detail / Problem Detail List', element: ProblemDetailList },
  { path: `${process.env.PUBLIC_URL}/admin/master/add-problem-detail`, name: 'Master / Problem Detail / Add Problem Detail', element: AddProblemDetail },
  { path: `${process.env.PUBLIC_URL}/admin/master/edit-problem-detail/id`, name: 'Master / Problem Detail /  Edit Problem Detail', element: EditProblemDetail },
  { path: `${process.env.PUBLIC_URL}/admin/master/management-allocation`, name: 'Master / Management Allocation', element: managementAllocationList },
  { path: `${process.env.PUBLIC_URL}/admin/reports`, name: 'Reports / Reports List', element: ReportsList },
  { path: `${process.env.PUBLIC_URL}/admin/add-report`, name: 'Reports / Add Report', element: AddReport },
  { path: `${process.env.PUBLIC_URL}/admin/edit-report/id`, name: 'Reports / Edit Report', element: EditReport },
  { path: `${process.env.PUBLIC_URL}/admin/view-report/id`, name: 'Reports / View Report', element: ViewReport },
]

export default routes
