import { cilArrowLeft } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CForm, CFormInput, CFormLabel, CFormSelect, CFormTextarea, CRow, CToast, CToastBody, CToastClose,CFooter } from '@coreui/react'
import axios from 'axios'
import React,{useState, useEffect} from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { endPoints } from '../../../../../Constants'
import { getAccessToken, writeDocumentTitle } from '../../../../../Helpers'

const Edit = (props) => {
      const [inputValues, setInputValue] = useState({
          name:'',
          status:'1'
      });
      const [validation, setValidation] = useState({
          name:'',
          status:'1'
      });
      const [showAlert, setShowAlert] = useState(false);
      const [showMessage, setShowMessage] = useState('');
      const [color, setColor] = useState('');
      const navigate = useNavigate();
      const params = useParams();
      const {id}   = params;
      
      const handleChange = (e) => {
          const { name, value } = e.target;
          setInputValue({ ...inputValues, [name]: value });
      }
      useEffect(()=>{
              writeDocumentTitle(props.title);
              /*---------------- get access token --------------*/
              const accessToken = getAccessToken();
              if(!accessToken) {
                  localStorage.removeItem('isAdminLoggedIn');
                  navigate(`${process.env.PUBLIC_URL}/`);
              }
              const config = {
                  headers: { Authorization: `Bearer ${accessToken}` }
              };
            /*---------------- get access token --------------*/
            
            axios.get(`${endPoints}edit-supplier/${id}`,config)
            .then((response)=>{
                if(response.data.aDetail) { 
                    setInputValue(response.data.aDetail);
                }
            })
            .catch((error)=>{
                if(error.response && error.response.status === 401) {
                  localStorage.removeItem('isAdminLoggedIn');
                  navigate(`${process.env.PUBLIC_URL}/`);
                }
            })
      },[]);
    /*------------- form validation ---------------*/
            const checkValidation = () => {
              let errors = {};
              if(!inputValues.name.trim()) {
                errors.name = "Supplier name is required";
              } 
              return errors;
           };
    
    /*------------- form validation ---------------*/
      
  /*------------ handle form submit ----------*/
        const handleSubmit = async (e) => {
          e.preventDefault();
          let isErrors = await checkValidation(inputValues);
          setValidation(isErrors);
          if(Object.keys(isErrors).length === 0) {
                /*---------------- get access token --------------*/
                    const accessToken = getAccessToken();
                    if(!accessToken) {
                        localStorage.removeItem('isAdminLoggedIn');
                        navigate(`${process.env.PUBLIC_URL}/`);
                    }
                    const config = {
                        headers: { Authorization: `Bearer ${accessToken}` }
                    };
               /*---------------- get access token --------------*/
                  setShowAlert(false);
                  setColor('');
                  setInputValue({...inputValues,id:id});
                  axios.put(`${endPoints}update-supplier`,inputValues,config)
                  .then((response)=>{
                    if(response.data.status === true) {
                        setShowMessage(response.data.message);
                        setShowAlert(true);
                        setColor('success');
                        setTimeout(()=>{
                           navigate(`${process.env.PUBLIC_URL}/admin/master/suppliers`);
                        },1500);
                    } else {
                        setShowMessage(response.data.message);
                        setShowAlert(true);
                        setColor('failure');
                    }           
                  })
                  .catch(function (error) {
                      if(error.response && error.response.status === 401) {
                          localStorage.removeItem('isAdminLoggedIn');
                          navigate(`${process.env.PUBLIC_URL}/`);
                      }
                  }); 
    
          } 
          
      }
  /*------------ handle form submit ----------*/
  return (
     <>
       <CRow className="align-items-center mb-2">
          <CCol xs={3}></CCol>
             <CCol xs={6}>
                <CToast autohide={true} delay={5000} visible={showAlert} color={`${color && color==='failure' ? 'danger' : 'success' }`} animation={true} className="text-white align-items-center">
                              <div className="d-flex">
                                <CToastBody>{showMessage}</CToastBody>
                                <CToastClose className="me-2 m-auto" />
                              </div>
                  </CToast>
             </CCol>
          <CCol xs={3}></CCol>
       </CRow>
       <CRow>
        
          <CCol xs={12}>
            <CCard className="mb-4">
              <CCardHeader>
               <CRow className="align-items-center">
                  <CCol xs={8} ><h5 className="cardHeading">Edit Supplier</h5></CCol>
                  <CCol xs={4} className="text-end">
                      <NavLink to={`${process.env.PUBLIC_URL}/admin/master/suppliers`} className="linkText"><CIcon icon={cilArrowLeft} className="me-2" />Back</NavLink>
                  </CCol>
              </CRow>
                
              </CCardHeader>
              <CCardBody>
               
                  <CForm onSubmit={handleSubmit}>
                     <CRow>
                      <CCol xs={12} md={6} xl={6}>
                            <div className="mb-3">
                              <CFormLabel htmlFor="name">Supplier Name<span className="formError">*</span></CFormLabel>
                              <CFormInput
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Supplier Name"
                                onChange={(e) => handleChange(e)} 
                                value={inputValues.name}
                              />
                              <p className="formError">{validation.name ? validation.name :  ''}</p>
                            </div>
                        </CCol>
                        
                        <CCol xs={12} md={6} xl={6}>
                            <div className="mb-3">
                              <CFormLabel htmlFor="status">Status<span className="formError">*</span></CFormLabel>
                              <CFormSelect value={inputValues.status} aria-label="Default select example" name="status" id="status" onChange={(e) => handleChange(e)}>
                                  <option value="1">Active</option>
                                  <option value="0">Inactive</option>
                              </CFormSelect>
                            </div>
                        </CCol>
                    </CRow>
                    
                    <CFooter className='cardFooter'>
                         <CButton type="submit" color="success">Update</CButton>
                    </CFooter>
                  </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
     </>
   )
}

export default Edit
