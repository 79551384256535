import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CImage,
  CInputGroup,
  CInputGroupText,
  CRow,
  CToast,
  CToastBody,
  CToastClose,

} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import logo from '../../../assets/images/logo.png'
import {endPoints} from '../../../Constants';
import {encryptData} from '../../../util';
import {writeDocumentTitle} from '../../../Helpers';

const Login = (props) => {
 
    const [inputValues, setInputValue] = useState({
            username:'',
            password:''
    });
    const [validation, setValidation] = useState({
            username:'',
            password:''
    });
    const [showAlert, setShowAlert] = useState(false);
    const [showMessage, setShowMessage] = useState('');
    const [color, setColor] = useState('');
    
    const navigate = useNavigate();
    
    
    const handleChange = (e) => {
      const { name, value } = e.target;
      setInputValue({ ...inputValues, [name]: value });
    }
    
    const checkValidation = () => {
        let errors = {};
        //Username validation
        if(!inputValues.username.trim()) {
           errors.username = "Username is required";
        } 
        //password validation
        if(!inputValues.password.trim()) {
           errors.password = "Password is required";
        } 
        return errors;
    };
    
    useEffect(() => {
      writeDocumentTitle(props.title);
      if(!!localStorage.getItem("isAdminLoggedIn")) {
          navigate(`${process.env.PUBLIC_URL}/admin/dashboard`);
      }
      
  });

    const handleSubmit = async (e) => {
      let isErrors = await checkValidation(inputValues);
      setValidation(isErrors);
      if(Object.keys(isErrors).length === 0) {
              setShowAlert(false);
              setColor('');
              axios.post(`${endPoints}login`,inputValues)
              .then(function (response) {
                  if(response.data.status === false) {
                      setShowMessage(response.data.message);
                      setShowAlert(true);
                      setColor('failure');
                  } else {
                        let aUserDetail = response.data.aUserDetail;
                        if(!!aUserDetail) {
                            const salt = process.env.REACT_APP_API_KEY;
                            const encryptedData = encryptData(aUserDetail, salt);
                            localStorage.setItem("isAdminLoggedIn",encryptedData);
                            navigate(`${process.env.PUBLIC_URL}/admin/dashboard`);
                        }
                  }
              })
              .catch(function (error) {
                  
              }); 

      } 
      e.preventDefault();
      
  }
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
      <CRow className="justify-content-center mb-3 text-center">
      <CCol md={5}>
      <CToast  autohide={true} delay={5000} visible={showAlert} color={`${color && color==='failure' ? 'danger' : '' }`} animation={true} className="text-white align-items-center">
              <div className="d-flex">
                <CToastBody>{showMessage}</CToastBody>
                <CToastClose className="me-2 m-auto" />
              </div>
        </CToast>
        </CCol>
        </CRow >
     
        
        <CRow className="justify-content-center CustomloginWrap" >
          <CCol md={5} >
            <CCardGroup>
              <CCard className="CustomloginCard">
                <CCardBody className='p-0'>
                  <CRow className='logoWrap'>
                     <CCol md={2}></CCol>
                     <CCol md={8}><CImage fluid src={logo} /></CCol>
                     <CCol md={2}></CCol>
                  </CRow>
                  
                  <CForm onSubmit={handleSubmit} noValidate>
                    <div className='pageNameHeading'>
                    <h1 className="loginHeading">Login</h1>
                    <p className="text-medium-emphasis text-center">Sign In to your account</p>
                    </div>
                    <CInputGroup className={`${!validation.username ? "mb-3" :  ''}`}>
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput placeholder="Username" autoComplete="username" id="validationCustom01" name="username" onChange={(e) => handleChange(e)}  />
                    </CInputGroup>
                    <CRow className="formError"><p>{validation.username ? validation.username :  ''}</p></CRow>
                    
                    <CInputGroup className={`${!validation.username ? "mb-0" :  ''}`}>
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password" 
                        name="password"
                        onChange={(e) => handleChange(e)}
                      />
                    </CInputGroup>
                    <CRow className="formError"><p>{validation.password ? validation.password :  ''}</p></CRow>
                    <CRow>
                    <CCol xs={12} className="text-start mb-3">
                         <Link to={`${process.env.PUBLIC_URL}/forgot-password`}>
                            <CButton color="link" className="px-0 greenText">
                              Forgot password?
                            </CButton>
                         </Link>
                      </CCol>
                      </CRow>
                    <CRow>
                      <CCol xs={12}  className="text-center">
                        <CButton type="submit" color="primary" className="customLoginBtn">
                          Login
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
        
      </CContainer>
    </div>
  )
}

export default Login
