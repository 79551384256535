import { cilArrowLeft } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton, CCard, CCardBody, CCardHeader, CCardText, CCardTitle, CCol, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow,  CTable, CTableBody, CTableDataCell, CTableHeaderCell, CTableRow,CImage,CTooltip, CTableHead, CSpinner } from '@coreui/react'
import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { endPoints } from '../../../../Constants'
import { getAccessToken, writeDocumentTitle } from '../../../../Helpers'


const View = (props) => {
  const [ inputValues, setInputValue ] = useState({
    date: "",
    site: "",
    site_detail: "",
    site_detail2: "",
    issue_description: "",
    opportunity_class_id: "",
    problem_class_id: "",
    reportable: "",
    problem_category_id: "",
    problem_detail_id: "",
    document_number: "",
    root_cause_required: "",
    immediate_action_description: "",
    root_cause_description: "",
    corrective_action_description: "",
    assign_to: "",
    opportunity_status: "Open",
    verification_of_effectiveness: "",
    quality_cost: "",
    comment:""
  });
 
  const [ managementAllocation, setManagementAllocation ] = useState(null);
  const [ siteDetailAndSiteDetail2ShowHide, setSiteDetailAndSiteDetail2ShowHide ] = useState(true);
  const [ reportableShowHide, setReportableShowHide ] = useState(false);
  const [ documentNumberShowHide, setDocumentNumberShowHide ] = useState(false);
  const [ problemCategoryShowHide, setProblemCategoryShowHide ] = useState(false);
  const [ problemDetailShowHide, setProblemDetailShowHide ] = useState(true);
  const [ opportunityClassId, setOpportunityClassId ] = useState(true);
  
  const [ selectedAssignUser, setSelectedAssignUser ] = useState([]);
  const [ commentsList, setCommentsList ] = useState([]);
  const [visible, setVisible] = useState(false);
  const [uploadedFilesList, setUploadedFilesList] = useState([]);
  const [loader, setLoader] = useState(false);
 
  
  const navigate = useNavigate();
  const params = useParams();
  const {id}   = params;
 

  useEffect(() => {
     writeDocumentTitle(props.title);
  
            /*---------------- get access token --------------*/
              const accessToken = getAccessToken();
              if(!accessToken) {
                localStorage.removeItem('isAdminLoggedIn');
                navigate(`${process.env.PUBLIC_URL}/`);
              }
              const config = {
                headers: { Authorization: `Bearer ${accessToken}` }
              };
            /*---------------- get access token --------------*/
             axios.get(`${endPoints}view-report/${id}`, config)
              .then((response) => {
                  
                  if(response.data.status === true) {
                    let {aDetail,aCommentList,aUploadedImagesList} = response.data;
                    setInputValue(aDetail);
                    var assignedUser = response.data.aAssignedUserList.assigned_user_name;
                    setSelectedAssignUser(assignedUser);
                    setCommentsList(aCommentList);
                    setUploadedFilesList(aUploadedImagesList);
                    if(!!aDetail.site && (aDetail.site != 1 && aDetail.site != 4)) {
                        setSiteDetailAndSiteDetail2ShowHide(false);
                    }
                    /*------------------- handle data of problem, category and detail class by opportunity class --------*/
                            let opportunity_class_id = aDetail.opportunity_class_id;
                            let problem_class_id = aDetail.problem_class_id;
                            let inputValue = { opportunity_class_id: opportunity_class_id };
                            
                            axios.post(`${endPoints}getProblemClassCategoryAndDetailList`, inputValue, config)
                              .then((response) => {
                                if (response.data.status === true) {
                                  let opportunity_class_id = response.data.opportunityDetail.id;
                                  
                                  setManagementAllocation(`${response.data.opportunityDetail.first_name} ${response.data.opportunityDetail.last_name}`);
                                  setOpportunityClassId(opportunity_class_id);
                                  if (opportunity_class_id && (opportunity_class_id == '4' || opportunity_class_id == '6')) {
                                    setDocumentNumberShowHide(true);
                                    setProblemDetailShowHide(false);
                                  }
                                  
                                  if (opportunity_class_id && (opportunity_class_id == '2' || opportunity_class_id == '11')) {
                                      setProblemDetailShowHide(false);
                                  }
                        
                                }
                              })
                              .catch((error) => {
                                if (error.response && error.response.status === 401) {
                                  localStorage.removeItem('isAdminLoggedIn');
                                  navigate(`${process.env.PUBLIC_URL}/`);
                                }
                              })
                    /*------------------- handle data of problem, category and detail class by opportunity class --------*/
                    
                    /*------------ get problem category by opportunity and problem class --------------*/
                            
                              
                              
                              if (!!problem_class_id || !!opportunity_class_id) {
                              /*-------------- problem category and reportable section manage if opportunity class is SAFETY --------*/
                                if(opportunity_class_id && opportunity_class_id == '7') {
                                 
                                  if (problem_class_id && (problem_class_id == '25' || problem_class_id == '26' || problem_class_id == '29')) {
                                    setReportableShowHide(true);
                                    setProblemCategoryShowHide(false);
                                    setProblemDetailShowHide(true);
                                  } else if (problem_class_id && (problem_class_id == '27')) {
                                    setProblemCategoryShowHide(true);
                                    setReportableShowHide(false);
                                    setProblemDetailShowHide(true);
                                  } else if (problem_class_id && (problem_class_id == '43')) {
                                    setProblemCategoryShowHide(true);
                                    setReportableShowHide(false);
                                    setProblemDetailShowHide(false);
                                  } else {
                                    setReportableShowHide(false);
                                    setProblemCategoryShowHide(false);
                                    setProblemDetailShowHide(false);

                                  }
                                }
                                /*-------------- problem category and reportable section manage if opportunity class is SAFETY --------*/
                          }
                            
                      /*------------ get problem category by opportunity and problem class --------------*/
                      
                }
              })
              .catch((error) => {
                if (error.response && error.response.status === 401) {
                  localStorage.removeItem('isAdminLoggedIn');
                  navigate(`${process.env.PUBLIC_URL}/`);
                }
              });
      
  }, []);
  
  const downloadPDF = () => {
          setLoader(true);
         /*---------------- get access token --------------*/
            const accessToken = getAccessToken();
            if(!accessToken) {
              localStorage.removeItem('isAdminLoggedIn');
              navigate(`${process.env.PUBLIC_URL}/`);
            }
            const config = {
              headers: { Authorization: `Bearer ${accessToken}` }
            };
       /*---------------- get access token --------------*/
       
        axios.get(`${endPoints}downloadReportPdf/${id}`, config)
              .then((response) => {
                  try {
                      const {fileURL,iRecordId} = response.data;
                      window.open(fileURL,"_blank");
                      // const link = document.createElement('a');
                      // link.href = fileURL;
                      // link.download = `IQR_SYSTEM_REPORT_${iRecordId}`;
                      // link.click();
                      // // Clean up the temporary URL and link
                      // link.parentNode.removeChild(link);
                  } catch (error) {
                      
                  }
                  setLoader(false);
              })
              .catch((error) => {
                  if (error.response && error.response.status === 401) {
                      localStorage.removeItem('isAdminLoggedIn');
                      navigate(`${process.env.PUBLIC_URL}/`);
                  }
                  setLoader(false);
              });
  }
  
  return (
    <>
      
      <CRow>

        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <CRow className="align-items-center">
                <CCol xs={8} ><h5 className="cardHeading">View Report</h5></CCol>
                <CCol xs={2} className='text-end'>
                  {
                    loader ? <CSpinner color="success"/> :  <CButton type="button" onClick={() => downloadPDF()} color="success">Download PDF</CButton>
                  }
                  
                </CCol>
                <CCol xs={2} className='text-end'>
                  <NavLink to={`${process.env.PUBLIC_URL}/admin/reports`} className="linkText"><CIcon icon={cilArrowLeft} className="me-2" />Back</NavLink>
                </CCol>
              </CRow>

            </CCardHeader>
            <CCardBody>
             <CRow className='customViewMobile'> 
                    <CCol xs={4} >
                      <div className='table-responsive customViewTable'>
                        <CTable bordered>
                              <CTableBody>
                                  <CTableRow>
                                      <CTableHeaderCell>Report ID:</CTableHeaderCell>
                                      <CTableDataCell>{inputValues.record_id ?? ''}</CTableDataCell>
                                  </CTableRow>
                                  <CTableRow>
                                      <CTableHeaderCell>Date:</CTableHeaderCell>
                                      <CTableDataCell>{inputValues.date ?? ''}</CTableDataCell>
                                  </CTableRow>
                                  <CTableRow>
                                      <CTableHeaderCell>Opportunity Class:</CTableHeaderCell>
                                      <CTableDataCell>{inputValues.opportunity_class_name ?? ''}</CTableDataCell>
                                  </CTableRow>
                                  <CTableRow>
                                      <CTableHeaderCell>Related:</CTableHeaderCell>
                                      <CTableDataCell>{inputValues.is_related_to_work ?? ''}</CTableDataCell>
                                  </CTableRow>
                              </CTableBody>
                        </CTable>
                        </div>
                    </CCol>
                    <CCol xs={4} >
                    <div className='table-responsive customViewTable'>
                        <CTable bordered>
                              <CTableBody>
                                  <CTableRow>
                                      <CTableHeaderCell>Site:</CTableHeaderCell>
                                      <CTableDataCell>{inputValues.site_name ?? ''}</CTableDataCell>
                                  </CTableRow>
                                  <CTableRow>
                                      <CTableHeaderCell>Site Detail:</CTableHeaderCell>
                                      <CTableDataCell>{inputValues.site && inputValues.site == '1' ? inputValues.customer_site_detail_name : inputValues.supplier_site_detail_name}</CTableDataCell>
                                  </CTableRow>
                                  <CTableRow>
                                      <CTableHeaderCell>Problem Class:</CTableHeaderCell>
                                      <CTableDataCell>{inputValues.problem_class_name ?? ''}</CTableDataCell>
                                  </CTableRow>
                                  <CTableRow>
                                      <CTableHeaderCell>Problem Category:</CTableHeaderCell>
                                      <CTableDataCell>{inputValues.problem_category_name ?? ''}</CTableDataCell>
                                  </CTableRow>
                              </CTableBody>
                        </CTable>
                        </div>
                    </CCol>
                    <CCol xs={4} >
                    <div className='table-responsive customViewTable'>
                        <CTable bordered>
                              <CTableBody>
                                  <CTableRow>
                                      <CTableHeaderCell>Site Detail 2:</CTableHeaderCell>
                                      <CTableDataCell>{inputValues.site && inputValues.site == '1' ? inputValues.customer_site_detail2_name : inputValues.supplier_site_detail2_name}</CTableDataCell>
                                  </CTableRow>
                                  <CTableRow>
                                      <CTableHeaderCell>Problem Detail:</CTableHeaderCell>
                                      <CTableDataCell>{inputValues.problem_detail_name ?? ''}</CTableDataCell>
                                  </CTableRow>
                                  <CTableRow>
                                      <CTableHeaderCell>Report By:</CTableHeaderCell>
                                      <CTableDataCell>{inputValues.first_name ? `${inputValues.first_name} ${inputValues.last_name}`: ''}</CTableDataCell>
                                  </CTableRow>
                                  <CTableRow>
                                      <CTableHeaderCell>Reportable?:</CTableHeaderCell>
                                      <CTableDataCell>{inputValues.reportable ? inputValues.reportable : 'No'}</CTableDataCell>
                                  </CTableRow>
                              </CTableBody>
                        </CTable>
                        </div>
                    </CCol>
               </CRow> 
               <CRow>
                  <CCol xs={12} >
                  <div className='table-responsive customViewTable'>
                      <CTable bordered>
                              <CTableBody>
                                  <CTableRow>
                                      <CTableHeaderCell>Issue Description:</CTableHeaderCell>
                                      <CTableDataCell>{inputValues.issue_description ?? ''}</CTableDataCell>
                                  </CTableRow>
                              </CTableBody>
                      </CTable>
                      </div>
                    </CCol>
               </CRow> 
               <CRow className='customViewMobile'>
                  <CCol xs={6} >
                  <div className='table-responsive customViewTable'>
                      <CTable bordered>
                              <CTableBody>
                                  <CTableRow>
                                      <CTableHeaderCell>Root Cause Required:</CTableHeaderCell>
                                      <CTableDataCell>{inputValues.root_cause_required ? inputValues.root_cause_required.charAt(0).toUpperCase()+ inputValues.root_cause_required.slice(1) : ''}</CTableDataCell>
                                  </CTableRow>
                                  <CTableRow>
                                      <CTableHeaderCell>Management Allocation:</CTableHeaderCell>
                                      <CTableDataCell>{managementAllocation ?? ''}</CTableDataCell>
                                  </CTableRow>
                                  <CTableRow>
                                      <CTableHeaderCell>Root Cause Description:</CTableHeaderCell>
                                      <CTableDataCell>{inputValues.root_cause_description ?? ''}</CTableDataCell>
                                  </CTableRow>
                                  <CTableRow>
                                      <CTableHeaderCell>Immediate Action Description:</CTableHeaderCell>
                                      <CTableDataCell>{inputValues.immediate_action_description ?? ''}</CTableDataCell>
                                  </CTableRow>
                                  <CTableRow>
                                      <CTableHeaderCell>Assigned To:</CTableHeaderCell>
                                      <CTableDataCell>{selectedAssignUser ?? ''}</CTableDataCell>
                                  </CTableRow>
                              </CTableBody>
                      </CTable>
                      </div>
                    </CCol>
                    <CCol xs={6} >
                    <div className='table-responsive customViewTable'>
                      <CTable bordered>
                              <CTableBody>
                                  <CTableRow>
                                      <CTableHeaderCell>Corrective Action Description:</CTableHeaderCell>
                                      <CTableDataCell>{inputValues.corrective_action_description ?? ''}</CTableDataCell>
                                  </CTableRow>
                                  <CTableRow>
                                      <CTableHeaderCell>Opportunity Status:</CTableHeaderCell>
                                      <CTableDataCell>{inputValues.opportunity_status ?? ''}</CTableDataCell>
                                  </CTableRow>
                                  <CTableRow>
                                      <CTableHeaderCell>Verification of Effectiveness:</CTableHeaderCell>
                                      <CTableDataCell>{inputValues.verification_of_effectiveness ?? ''}</CTableDataCell>
                                  </CTableRow>
                                  <CTableRow>
                                      <CTableHeaderCell>Quality Cost:</CTableHeaderCell>
                                      <CTableDataCell>{inputValues.quality_cost ?? ''}</CTableDataCell>
                                  </CTableRow>
                                  <CTableRow>
                                      <CTableHeaderCell>Document Number:</CTableHeaderCell>
                                      <CTableDataCell>{inputValues.document_number ?? ''}</CTableDataCell>
                                  </CTableRow>
                              </CTableBody>
                      </CTable>
                      </div>
                    </CCol>
               </CRow>
               <CRow className='customViewMobile'>
                  <CCol xs={12} >
                  <div className='table-responsive customViewTable'>
                      <CTable bordered>
                              <CTableBody>
                                  <CTableRow>
                                      <CTableHeaderCell>Comment:</CTableHeaderCell>
                                      <CTableDataCell>
                                          {
                                            commentsList && commentsList.length > 0 ? <NavLink onClick={() => setVisible(true)} > View All {commentsList.length > 1 ? 'Comments' : 'Comment'}</NavLink> : null
                                          }
                                      </CTableDataCell>
                                  </CTableRow>
                              </CTableBody>
                      </CTable>
                      </div>
                    </CCol>
               </CRow>
               <CRow className='customViewMobile'>
                  <CCol xs={12} >
                  <div className='table-responsive customViewTable'>
                      <CTable bordered>
                              <CTableHead>
                                  <CTableRow>
                                       <CTableHeaderCell>Uploaded Photo:</CTableHeaderCell>
                                  </CTableRow>
                              </CTableHead>
                              <CTableBody>
                                  <CTableRow>
                                     {
                                       
                                      
                                        uploadedFilesList.length > 0 && uploadedFilesList.map((document,index)=> 
                                        
                                            
                                                       <CTableDataCell>
                                                      
                                                          <div className="list-item">
                                                            <span className="item-text">
                                                              <NavLink to={`${process.env.REACT_APP_BACKEND_URL}${document.image}`} target="_blank" ><CImage align="start" rounded src={`${process.env.REACT_APP_BACKEND_URL}${document.image}`} width={100} height={100} /></NavLink>
                                                            </span>
                                                          </div>
                                                      
                                                    </CTableDataCell>
                                               
                                        )
                                      }
                                  </CTableRow>
                              </CTableBody>
                      </CTable>
                      </div>
                    </CCol>
               </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      {/*---------------- Modal section start here -------------------*/}
      <CModal size="xl" visible={visible} onClose={() => setVisible(false)}>
                  <CModalHeader>
                    <CModalTitle>All {commentsList && commentsList.length > 1 ? 'Comments' : 'Comment'}</CModalTitle>
                  </CModalHeader>
                      <CModalBody>
                         {
                             commentsList && commentsList.length > 0 ? commentsList.map((comment,index)=> 
                                 <CCard className={index > 0 ? "my-3":"" } key={index+1}>
                                     <CCardBody>
                                      <CCardTitle>{comment.first_name} {comment.last_name}</CCardTitle>
                                      <CCardText>{comment.created_at}</CCardText>
                                      <CCardText>{comment.comment}</CCardText>
                                    </CCardBody>
                                  </CCard>
                              )
                              : null
                         }
                      </CModalBody>
                      <CModalFooter>
                        <CButton color="secondary" onClick={() => setVisible(false)}>
                          Close
                        </CButton>
                      </CModalFooter>
              </CModal>
          {/*---------------- Modal section end here -------------------*/}
    </>
  )
}

export default View
