import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link,useNavigate,useParams } from 'react-router-dom';
import {FaLongArrowAltLeft} from 'react-icons/fa';

import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CImage,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSpinner,
  CToast,
  CToastBody,
  CToastClose,

} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked } from '@coreui/icons'
import logo from '../../../assets/images/logo.png'
import {endPoints} from '../../../Constants';
import {writeDocumentTitle} from '../../../Helpers';

const ResetPassword = (props) => {

    const [inputValues, setInputValue] = useState({
          newPassword:'',
          confirmNewPassword:''
    });
    const [validation, setValidation] = useState({
          newPassword:'',
          confirmNewPassword:''
    });
    const [showAlert, setShowAlert] = useState(false);
    const [showMessage, setShowMessage] = useState('');
    const [color, setColor] = useState('');
    const [loader, setLoader] = useState(false);
    const [userId, setUserId] = useState('');
    
    const params = useParams();
    const link   = params.link;
    
    const navigate = useNavigate();
    
    useEffect(() => {
            writeDocumentTitle(props.title);
            if(!!link) {
              axios.post(`${endPoints}resetPassword`, {sLink:link})
              .then((response) => {
                  if(response.data.status === false) {
                        setShowMessage(response.data.message);
                        setShowAlert(true);
                        setColor('failure');
                        setTimeout(()=>{
                          navigate(`${process.env.PUBLIC_URL}/`);
                        },2000);
                  } else {
                      //  setInputValues({ ...inputValues, userId:response.data.iUserId });
                      setUserId(response.data.iUserId);
                  }
              })
            .catch((error) => console.log(error)) 
          } else {
              setShowMessage('Invalid links');
              setShowAlert(true);
              setColor('failure');
          }
    },[link]);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setInputValue({ ...inputValues, [name]: value });
    }
    
    const checkValidation = () => {
          let errors = {};
          let regex = /^(?=.{8,}$)(?=.*?[a-z])(?=.*?[0-9])(?=.*?\W).*$/;
          //email validation
          if (!inputValues.newPassword.trim()) {
              errors.newPassword = "New password is required";
          } else if(inputValues.newPassword.trim() && !regex.test(inputValues.newPassword)){ 
              errors.newPassword = "Password must have contain at least one lowercase letter, one number, one special character and minimum 8 characters in length";
          }
          
          if(!inputValues.confirmNewPassword.trim()) {
             errors.confirmNewPassword = "Confirm new password is required";
          } else if(inputValues.newPassword.trim() !== inputValues.confirmNewPassword.trim()) {
             errors.confirmNewPassword = "Password and confirm new password does not match";
          }
          return errors;
    };
    
    const handleSubmit = async (e) => {
      let isErrors = await checkValidation(inputValues);
      setValidation(isErrors);
      if(Object.keys(isErrors).length === 0) {
              setLoader(true);
              setShowAlert(false);
              setColor('');
              axios.put(`${endPoints}updatePassword/${userId}`,inputValues)
              .then(function (response) {
                  setShowMessage(response.data.message);
                  setShowAlert(true);
                  if(response.data.status === false) {
                      setColor('failure');
                  } else {
                      setColor('success');
                      setInputValue({
                            newPassword:'',
                            confirmNewPassword:''
                      });
                       setTimeout(()=>{
                           navigate(`${process.env.PUBLIC_URL}/`);
                       },2000);
                  }
                  setLoader(false);
              })
              .catch(function (error) {
                  setLoader(false);
              }); 
              
      } 
      e.preventDefault();
      
  }
  return (
   
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
      <CRow className="justify-content-center mb-3 text-center">
      <CCol md={5}>
      <CToast  autohide={true} delay={5000} visible={showAlert} color={`${color && color==='failure' ? 'danger' : 'success' }`} animation={true} className="text-white align-items-center">
              <div className="d-flex">
                <CToastBody>{showMessage}</CToastBody>
                <CToastClose className="me-2 m-auto" />
              </div>
        </CToast>
        </CCol>
        </CRow >
        <CRow className="justify-content-center CustomloginWrap">
          <CCol md={5}>
            <CCardGroup>
              <CCard className="CustomloginCard">
                <CCardBody className='p-0'>
                  <CRow className='logoWrap'>
                     <CCol md={2}></CCol>
                     <CCol md={8}><CImage fluid src={logo} /></CCol>
                     <CCol md={2}></CCol>
                  </CRow>
                  
                  <CForm onSubmit={handleSubmit} noValidate>
                  <div className='pageNameHeading'>
                  <h1 className="loginHeading">Reset Password</h1>
                    <p className="text-medium-emphasis">Enter your email to reset your password</p>
                    </div>
                  
                    <CInputGroup className={`${!validation.newPassword ? "mb-3" :  ''}`}>
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput type="password" placeholder="New Password" name="newPassword" autoComplete="newPassword" onChange={(e) => handleChange(e)} value={inputValues.newPassword} />
                    </CInputGroup>
                    <CRow className="formError"><p>{validation.newPassword ? validation.newPassword :  ''}</p></CRow>
                    
                    <CInputGroup className={`${!validation.confirmNewPassword ? "mb-4" :  ''}`}>
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput type="password" placeholder="Confirm New Password" name="confirmNewPassword" autoComplete="confirmNewPassword" onChange={(e) => handleChange(e)}  value={inputValues.confirmNewPassword} />
                    </CInputGroup>
                    <CRow className="formError"><p>{validation.confirmNewPassword ? validation.confirmNewPassword :  ''}</p></CRow>
                    
                    <CRow>
                      <CCol xs={6}>
                        {
                          loader ? <CSpinner color="success"/> : <CButton type="submit" color="primary" className="px-4 customLoginBtn">Submit</CButton>
                        }
                        
                      </CCol>
                      
                      <CCol xs={6} className="text-end">
                        <Link to={`${process.env.PUBLIC_URL}/`} >
                        <CButton color="link"  className="px-0 greenText">
                               <FaLongArrowAltLeft className="me-1" /> Back to Login
                            </CButton>
                         </Link>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
        
      </CContainer>
    </div>
  )
}

export default ResetPassword
