import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {FaLongArrowAltLeft,FaPlus,FaRegEye,FaPencilAlt,FaTrashAlt,FaSyncAlt,FaFileDownload } from 'react-icons/fa';

import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CImage,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSpinner,
  CToast,
  CToastBody,
  CToastClose,

} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilUser } from '@coreui/icons'
import logo from '../../../assets/images/logo.png'
import {endPoints} from '../../../Constants';
import {writeDocumentTitle} from '../../../Helpers';

const Login = (props) => {

    const [inputValues, setInputValue] = useState({
          userEmail:''
    });
    const [validation, setValidation] = useState({
         userEmail:''
    });
    const [showAlert, setShowAlert] = useState(false);
    const [showMessage, setShowMessage] = useState('');
    const [color, setColor] = useState('');
    const [loader, setLoader] = useState(false);
    
    useEffect(() => {
        writeDocumentTitle(props.title);
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setInputValue({ ...inputValues, [name]: value });
    }
    
    const checkValidation = () => {
          let errors = {};
          let regex  = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
          //email validation
          if (!inputValues.userEmail.trim()) {
             errors.userEmail = "Email is required";
          } else if(!!inputValues.userEmail && !regex.test(inputValues.userEmail)) {
             errors.userEmail = "Please enter valid email";
          }
          return errors;
    };
    
    const handleSubmit = async (e) => {
      let isErrors = await checkValidation(inputValues);
      setValidation(isErrors);
      if(Object.keys(isErrors).length === 0) {
              setLoader(true);
              setShowAlert(false);
              setColor('');
              axios.post(`${endPoints}forgotPassword`,inputValues)
              .then(function (response) {
                  setShowMessage(response.data.message);
                  setShowAlert(true);
                  if(response.data.status === false) {
                      setColor('failure');
                  } else {
                      setColor('success');
                      setInputValue({
                           userEmail:''
                       });
                  }
                  setLoader(false);
              })
              .catch(function (error) {
                  
                  setLoader(false);
              }); 
              
      } 
      e.preventDefault();
      
  }
  return (
   
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
      <CRow className="justify-content-center mb-3 text-center">
      <CCol md={5}>
      <CToast  autohide={true} delay={5000} visible={showAlert} color={`${color && color==='failure' ? 'danger' : 'success' }`} animation={true} className="text-white align-items-center">
              <div className="d-flex">
                <CToastBody>{showMessage}</CToastBody>
                <CToastClose className="me-2 m-auto" />
              </div>
        </CToast>
        </CCol>
        </CRow >
        <CRow className="justify-content-center CustomloginWrap">
          <CCol md={5}>
            <CCardGroup>
              <CCard className="CustomloginCard">
                <CCardBody className='p-0'>
                  <CRow className='logoWrap'>
                     <CCol md={2}></CCol>
                     <CCol md={8}><CImage fluid src={logo} /></CCol>
                     <CCol md={2}></CCol>
                  </CRow>
                  
                  <CForm onSubmit={handleSubmit} noValidate>
                  <div className='pageNameHeading'>
                  <h1 className="loginHeading">Forgot Password</h1>
                    <p className="text-medium-emphasis text-center">Enter your email to reset your password</p>
                    </div>
                    
                    <CInputGroup className={`${!validation.userEmail ? "mb-3" :  ''}`}>
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput placeholder="Email" autoComplete="userEmail" id="validationCustom01" name="userEmail" onChange={(e) => handleChange(e)} value={inputValues.userEmail}  />
                    </CInputGroup>
                    <CRow className="formError"><p>{validation.userEmail ? validation.userEmail :  ''}</p></CRow>
                    
                    <CRow>
                    <CCol xs={6}  className="">
                      {
                          loader ? <CSpinner color="success"/> : <CButton type="submit"  className="px-4 customLoginBtn">Submit</CButton>
                        }
                      </CCol>
                      <CCol xs={6} className="text-end">
                         <Link to={`${process.env.PUBLIC_URL}/`} >
                            <CButton color="link"  className="px-0 greenText">
                               <FaLongArrowAltLeft className="me-1" /> Back to Login
                            </CButton>
                         </Link>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
        
      </CContainer>
    </div>
  )
}

export default Login
