import React,{useEffect, Suspense} from 'react';
import {useNavigate} from 'react-router-dom';

import AppSidebar from './AppSidebar';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import { CContainer, CSpinner } from '@coreui/react'
const MasterLayout = (props) => {
  
  const navigate = useNavigate();
    useEffect(()=>{
           let isAdminLoggedIn = localStorage.getItem('isAdminLoggedIn');
           if(!isAdminLoggedIn) {
              
              return navigate(`${process.env.PUBLIC_URL}/`);
           }
   },[]);
   let Cmp = props.cmp;
  return (
    <>
       <div>
          <AppSidebar />
          <div className="wrapper d-flex flex-column min-vh-100 bg-light" style={{  }}>
            <AppHeader />
            <div className="body flex-grow-1 px-3">
              <CContainer lg>
                <Suspense fallback={<CSpinner color="primary" />}>
                  <Cmp title={props.title} />
                </Suspense>
              </CContainer>
            </div>
            <AppFooter />
          </div>
        </div>
    </>
  )
}

export default MasterLayout
