import React,{useEffect, Suspense} from 'react';
import {useNavigate} from 'react-router-dom';
import AppSidebar from './AppSidebar';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import { CContainer, CSpinner } from '@coreui/react'
import { decryptData } from '../../../../util';
const MasterLayoutProtected = (props) => {
  
  const navigate = useNavigate();
  let sIsAdminLoggedIn = localStorage.getItem('isAdminLoggedIn');
  let salt = process.env.REACT_APP_API_KEY;
  let oIsAdminLoggedIn = !!sIsAdminLoggedIn ? decryptData(sIsAdminLoggedIn,salt) : '';
  let sLoggedInUserName = oIsAdminLoggedIn['user_role'] ? oIsAdminLoggedIn['user_role'] : '';
  useEffect(()=>{
    let sIsAdminLoggedIn = localStorage.getItem('isAdminLoggedIn');
    let salt = process.env.REACT_APP_API_KEY;
    let oIsAdminLoggedIn = !!sIsAdminLoggedIn ? decryptData(sIsAdminLoggedIn,salt) : '';
    if(!oIsAdminLoggedIn || oIsAdminLoggedIn['user_role']!='superadmin') {
            localStorage.removeItem("isAdminLoggedIn");
            return navigate(`${process.env.PUBLIC_URL}/`);
    }
       
  },[]);
 
   let Cmp = props.cmp;
  return (
    
    sLoggedInUserName && sLoggedInUserName === 'superadmin' ? 
       <div>
          <AppSidebar />
          <div className="wrapper d-flex flex-column min-vh-100 bg-light" style={{  }}>
            <AppHeader />
            <div className="body flex-grow-1 px-3">
              <CContainer lg>
                <Suspense fallback={<CSpinner color="primary" />}>
                  <Cmp title={props.title} />
                </Suspense>
              </CContainer>
            </div>
            <AppFooter />
          </div>
        </div>
    : null
    
  )
}

export default MasterLayoutProtected
