import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilReportSlash,
  cilSettings,
  cilSpeedometer,
  cilUser,
} from '@coreui/icons'
import { CNavGroup, CNavItem } from '@coreui/react'
import { getLoggedInUserRole } from '../../../../Helpers';
let sLoggedInUserRole = getLoggedInUserRole();

let nav =   [
  
  
  {
    component: CNavItem,
    name: `Dashboard`,
    to: `${process.env.PUBLIC_URL}/admin/dashboard`,
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Reports',
    to: `${process.env.PUBLIC_URL}/admin/reports`,
    icon: <CIcon icon={cilReportSlash} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: 'Masters',
    to: '/masters',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Customers',
        to: `${process.env.PUBLIC_URL}/admin/master/customers`,
      },
      {
        component: CNavItem,
        name: 'Fields',
        to: `${process.env.PUBLIC_URL}/admin/master/fields`,
      },
      {
        component: CNavItem,
        name: 'Suppliers',
        to: `${process.env.PUBLIC_URL}/admin/master/suppliers`,
      },
      {
        component: CNavItem,
        name: 'Address',
        to: `${process.env.PUBLIC_URL}/admin/master/address`,
      },
      {
        component: CNavItem,
        name: 'Opportunity Class',
        to: `${process.env.PUBLIC_URL}/admin/master/opportunity-classes`,
      },
      {
        component: CNavItem,
        name: 'Problem Class',
        to: `${process.env.PUBLIC_URL}/admin/master/problem-classes`,
      },
      {
        component: CNavItem,
        name: 'Problem Category',
        to: `${process.env.PUBLIC_URL}/admin/master/problem-categories`,
      },
      {
        component: CNavItem,
        name: 'Problem Detail',
        to: `${process.env.PUBLIC_URL}/admin/master/problem-details`,
      },
      {
        component: CNavItem,
        name: 'Management Allocations',
        to: `${process.env.PUBLIC_URL}/admin/master/management-allocation`,
      },
    ],
  },
  {
    component: CNavItem,
    name: 'Users',
    to: `${process.env.PUBLIC_URL}/admin/users`,
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
  }
];

const _nav = nav;

export default _nav
