import React,{useState, useEffect} from 'react';
import {useNavigate, NavLink} from 'react-router-dom';
import axios from 'axios';
import { endPoints } from '../../../../Constants';
import {FaPlus,FaRegEye,FaPencilAlt,FaTrashAlt,FaSyncAlt,FaFileDownload } from 'react-icons/fa';
import { getAccessToken, writeDocumentTitle } from '../../../../Helpers';
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CFormSelect,
  CPagination,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CToast,
  CToastBody,
  CToastClose,CTooltip,
  CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle,
  CSpinner
} from '@coreui/react'
import Pagination from '../layout/Pagination';
import { cilArrowBottom, cilArrowTop, } from '@coreui/icons';
import CIcon from '@coreui/icons-react';


const List = (props) => {
  const [data, setData] = useState([]);
  const [opportunityClassList, setOpportunityClassList] = useState([]);
  const [problemClassList, setProblemClassList] = useState([]);
  const [problemCategoriesList, setProblemCategoriesList] = useState([]);
  const [ problemDetailList, setProblemDetailList ] = useState([]);
  const [ site, setSite ] = useState([]);
  const [ siteDetail, setSiteDetail ] = useState([]);
  const [ siteDetail2, setSiteDetail2 ] = useState([]);
  const [ inputValues, setInputValue ] = useState({
    site_detail: "",
    site_detail2: ""
  });
  const navigate = useNavigate();

   /*--------------- custom pagination ----------------*/
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [showAlert, setShowAlert] = useState(false);
    const [showMessage, setShowMessage] = useState('');
    const [color, setColor] = useState('');
    const [totalRecord, setTotalRecord] = useState('');
    
   
    const [ siteDetailAndSiteDetail2ShowHide, setSiteDetailAndSiteDetail2ShowHide ] = useState(true);
    const [ loading, setLoading ] = useState(true);
    const [ isSearch, setSearch ] = useState(false);
    const [visible, setVisible] = useState(false);
    const [loader, setLoader] = useState(false);
    let indexOfLastData = currentPage * dataPerPage;
    //let indexOfFirstData = indexOfLastData - dataPerPage;
    //const currentData = data.slice(indexOfFirstData, indexOfLastData);
    
    const paginate = (number) => {
          filterListData(number,sortConfig.key,sortConfig.direction);
          setCurrentPage(number);
    };
  /*--------------- custom pagination ----------------*/
  
     useEffect(()=>{
        writeDocumentTitle(props.title);   
        getListData();
      
    },[]);
    
    useEffect(()=>{
      
      if(isSearch) {
        filterListData(currentPage,sortConfig.key,sortConfig.direction);
      }
    },[isSearch]);
    
  
  /*---------------  Handle column sorting ----------------*/
        
      const sortColumn = (key) => {
          let direction = 'asc';
          if(sortConfig.key === key && sortConfig.direction === 'asc') {
             direction = 'desc';
          }
          filterListData(currentPage,key,direction);
          setSortConfig({ key, direction });
      };
   /*---------------  Handle column sorting ----------------*/

/*----------------------- get user data ----------------*/
  const getListData = async () => {
       /*----------------------- get user access token otherwise logout ----------------*/
          let token = getAccessToken();
          if(!!token) {
              const config = {
                  headers: { Authorization: `Bearer ${token}` }
              };
              await axios.get(`${endPoints}reports`,config)
              .then((response)=>{
                   
                    setData(response.data.aLists);
                    setTotalRecord(response.data.iTotalRecords);
                    setOpportunityClassList(response.data.aOpportunityClassesLists);
                    setSite(response.data.aSiteList);
                    setLoading(false);
               })
              .catch((error) => {
                  if(error.response && error.response.status === 401) {
                     localStorage.removeItem('isAdminLoggedIn');
                     navigate(`${process.env.PUBLIC_URL}/`);
                  }
                  setLoading(false);
               })
          } else {
              navigate(`${process.env.PUBLIC_URL}/`);
          }
       /*----------------------- get user access token otherwise logout ----------------*/
       
  } 
/*----------------------- get user data ----------------*/

 /*--------------- filter list data ---------------*/
 const filterListData = (pageNumber='',sortBy='',orderBy='') => {
  let search = isSearch ? document.getElementById("search").value : '';
  let site = isSearch ? document.getElementById("site").value : '';
  let site_detail = isSearch ? document.getElementById("site_detail").value: '';
  let site_detail2 = isSearch ? document.getElementById("site_detail2").value: '';
  let opportunity_class = isSearch ? document.getElementById("opportunity_class").value : '';
  let problem_class = isSearch ? document.getElementById("problem_class").value : '';
  let problem_category = isSearch ? document.getElementById("problem_category").value: '';
  let problem_detail = isSearch ? document.getElementById("problem_detail").value: '';
  
      /*---------------- get access token --------------*/
          const accessToken = getAccessToken();
          if(!accessToken) {
              localStorage.removeItem('isAdminLoggedIn');
              navigate(`${process.env.PUBLIC_URL}/`);
          }
          const config = {
              headers: { Authorization: `Bearer ${accessToken}` }
          };
      /*---------------- get access token --------------*/
      let inputValues = { search: search,opportunity_class:opportunity_class,problem_class:problem_class,problem_category:problem_category,site:site,site_detail:site_detail,site_detail2:site_detail2,problem_detail:problem_detail,pageNumber:pageNumber,sortBy:sortBy,orderBy:orderBy};
      axios.post(`${endPoints}filterReportsList`, inputValues,config)
          .then((response) => {
               setData(response.data.aLists);
               setTotalRecord(response.data.iTotalRecords);
          })
          .catch((error) => {
              if(error.response && error.response.status === 401) {
                  localStorage.removeItem('isAdminLoggedIn');
                  navigate(`${process.env.PUBLIC_URL}/`);
              }
          })
  
}
/*--------------- filter list data ---------------*/


/*--------------- delete data -------------*/
const deleteData = (id) => {
  if (!!id) {
      /*---------------- get access token --------------*/
          const accessToken = getAccessToken();
          if(!accessToken) {
              localStorage.removeItem('isAdminLoggedIn');
              navigate(`${process.env.PUBLIC_URL}/`);
          }
          const config = {
              headers: { Authorization: `Bearer ${accessToken}` }
          };
      /*---------------- get access token --------------*/
      setShowAlert(false);
      setColor('');
      axios.delete(`${endPoints}delete-report/${id}`,config)
          .then((response) => {
              if(response.data.status === true) {
                  getListData();
                  setColor('success');
              } else {
                  setColor('failure');
              }
              setShowMessage(response.data.message);
              setShowAlert(true);
          })
          .catch((error) => {
              if(error.response && error.response.status === 401) {
                  localStorage.removeItem('isAdminLoggedIn');
                  navigate(`${process.env.PUBLIC_URL}/`);
              }
      })
  }
}
/*--------------- delete data -------------*/

/*------------ get problem class by opportunity class --------------*/
const getProblemClassByOpportunityClass = (e) => {
  let opportunity_class = e.target.value;
  if(!!opportunity_class) {
           /*---------------- get access token --------------*/
            const accessToken = getAccessToken();
            if(!accessToken) {
                localStorage.removeItem('isAdminLoggedIn');
                navigate(`${process.env.PUBLIC_URL}/`);
            }
            const config = {
                headers: { Authorization: `Bearer ${accessToken}` }
            };
          /*---------------- get access token --------------*/
          let inputValues = {opportunity_class_id:opportunity_class};
          axios.post(`${endPoints}getProblemClassByOpportunityClassList`,inputValues,config)
          .then((response)=>{
               if(response.data.status === true) { 
                    setProblemClassList(response.data.aProblemClassesLists);
                    setProblemDetailList(response.data.aProblemDetailLists);
               }
          })
          .catch((error)=>{
              if(error.response && error.response.status === 401) {
                localStorage.removeItem('isAdminLoggedIn');
                navigate(`${process.env.PUBLIC_URL}/`);
              }
          })
  }
}
/*------------ get problem class by opportunity class --------------*/

 /*------------ get problem category by opportunity and problem class --------------*/
 const getProblemCategoryByOpportunityAndProblemClass = (e) => {
  let opportunity_class_id = document.getElementById("opportunity_class").value;
  let problem_class_id = e.target.value;
  if(!!problem_class_id || !!opportunity_class_id) {
           /*---------------- get access token --------------*/
            const accessToken = getAccessToken();
            if(!accessToken) {
                localStorage.removeItem('isAdminLoggedIn');
                navigate(`${process.env.PUBLIC_URL}/`);
            }
            const config = {
                headers: { Authorization: `Bearer ${accessToken}` }
            };
          /*---------------- get access token --------------*/
          let inputData = {opportunity_class_id:opportunity_class_id,problem_class_id:problem_class_id};
          axios.post(`${endPoints}getProblemCategoryByOpportunityAndProblemClassList`,inputData,config)
          .then((response)=>{
               if(response.data.status === true) { 
                    setProblemCategoriesList(response.data.aProblemCategoriesLists);
                    
               }
          })
          .catch((error)=>{
              if(error.response && error.response.status === 401) {
                localStorage.removeItem('isAdminLoggedIn');
                navigate(`${process.env.PUBLIC_URL}/`);
              }
          })
  }
}
/*------------ get problem category by opportunity and problem class --------------*/

/*------------ get site detail by site ------------*/
const getSiteDetailBySite = async (e) => {
  let site = e.target.value;
  setInputValue(prevState => ({
    site_detail: '',
    site_detail2: '',
  }));
  setSiteDetail([]);
  setSiteDetail2([]);
  if (!!site && (site != 1 && site != 4)) {
    setSiteDetailAndSiteDetail2ShowHide(false);
    return;
  } else {
    setSiteDetailAndSiteDetail2ShowHide(true);
  }
  /*---------------- get access token --------------*/
  const accessToken = getAccessToken();
  if (!accessToken) {
    localStorage.removeItem('isAdminLoggedIn');
    navigate(`${process.env.PUBLIC_URL}/`);
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  /*---------------- get access token --------------*/
  
  let inputValue = { site: site };
  await axios.post(`${endPoints}getSiteDetail`, inputValue, config)
    .then((response) => {
      if (response.data.status === true) {
        setSiteDetail(response.data.aSiteDetail);

      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('isAdminLoggedIn');
        navigate(`${process.env.PUBLIC_URL}/`);
      }
    })

}
/*------------ get site detail by site ------------*/

/*------------ get site detail 2 by site and detail ------------*/
const getSiteDetail2BySiteAndDetail = (e) => {
  let site = document.getElementById("site").value;
  let site_detail = e.target.value;
  /*---------------- get access token --------------*/
  const accessToken = getAccessToken();
  if (!accessToken) {
    localStorage.removeItem('isAdminLoggedIn');
    navigate(`${process.env.PUBLIC_URL}/`);
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  /*---------------- get access token --------------*/
  let inputValue = { site: site, site_detail: site_detail };
  axios.post(`${endPoints}getSiteDetail2`, inputValue, config)
    .then((response) => {
      if (response.data.status === true) {
        setSiteDetail2(response.data.aSiteDetail2);
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('isAdminLoggedIn');
        navigate(`${process.env.PUBLIC_URL}/`);
      }
    })

}
/*------------ get site detail 2 by site and detail ------------*/

/*------------ download report ------------------*/
       const downloadReport = () => {
            
             setLoader(true);
            /*---------------- get access token --------------*/
                const accessToken = getAccessToken();
                if (!accessToken) {
                  localStorage.removeItem('isAdminLoggedIn');
                  navigate(`${process.env.PUBLIC_URL}/`);
                }
                const config = {
                  headers: { Authorization: `Bearer ${accessToken}` }
                };
            /*---------------- get access token --------------*/
            let search = document.getElementById("search").value;
            let site = document.getElementById("site").value;
            let site_detail = document.getElementById("site_detail").value;
            let site_detail2 = document.getElementById("site_detail2").value;
            let opportunity_class = document.getElementById("opportunity_class").value;
            let problem_class = document.getElementById("problem_class").value;
            let problem_category = document.getElementById("problem_category").value;
            let problem_detail = document.getElementById("problem_detail").value;
            let inputValue = {search,site,site_detail,site_detail2,opportunity_class,problem_class,problem_category,problem_detail,type:'xlsx'};
             axios.post(`${endPoints}exportReport`,inputValue, config)
              .then((response) => {
                if (response.data.status === true) {
                     
                      const link = document.createElement('a');
                      link.download = 'IQR_SYSTEM_REPORT';
                      link.href = response.data.sPath;
                      link.click();
                      // Clean up the temporary URL and link
                      link.parentNode.removeChild(link);
                      setLoader(false);
                }
                
              })
              .catch((error) => {
                if (error.response && error.response.status === 401) {
                  localStorage.removeItem('isAdminLoggedIn');
                  navigate(`${process.env.PUBLIC_URL}/`);
                }
                setLoader(false);
            })
       }
/*------------ download report ------------------*/

/*------------ get problem detail by opportunity, problem and category --------*/
    const getProblemDetail = (e) => {
        let opportunity_class_id = document.getElementById("opportunity_class").value;
        let problem_class_id = document.getElementById("problem_class").value;
        let problem_category_id = document.getElementById("problem_category").value;
        
                /*---------------- get access token --------------*/
                  const accessToken = getAccessToken();
                  if(!accessToken) {
                      localStorage.removeItem('isAdminLoggedIn');
                      navigate(`${process.env.PUBLIC_URL}/`);
                  }
                  const config = {
                      headers: { Authorization: `Bearer ${accessToken}` }
                  };
                /*---------------- get access token --------------*/
                let inputData = {opportunity_class_id,problem_class_id,problem_category_id};
                axios.post(`${endPoints}getProblemDetailByOpportunityProblemAndCategoryClass`,inputData,config)
                .then((response)=>{
                    if(response.data.status === true) { 
                         setProblemDetailList(response.data.aProblemDetailLists);
                    }
                })
                .catch((error)=>{
                    if(error.response && error.response.status === 401) {
                      localStorage.removeItem('isAdminLoggedIn');
                      navigate(`${process.env.PUBLIC_URL}/`);
                    }
                })
    }
/*------------ get problem detail by opportunity, problem and category --------*/

/*--------------- clear filter --------------------*/
    const isFilter = () => {
      isSearch && filterListData(currentPage,sortConfig.key,sortConfig.direction);
      setSearch(true);
    }
   
    const clearFilter = () => {
        document.getElementById("search").value = '';
        document.getElementById("opportunity_class").value = '';
        document.getElementById("problem_class").value = '';
        document.getElementById("problem_category").value = '';
        document.getElementById("site").value ='';
        document.getElementById("site_detail").value = '';
        document.getElementById("site_detail2").value = '';
        document.getElementById("problem_detail").value = '';
        setSortConfig({key: '', direction: ''});
        getListData();
        setSearch(false);
        setCurrentPage(1);
        setProblemClassList([]);
        setProblemCategoriesList([]);
        setProblemDetailList([]);
        setSiteDetail([]);
        setSiteDetail2([]);
    }
/*--------------- clear filter --------------------*/

/*--------------- show description on modal ---------*/
   const [isModalVisible, setIsModalVisible] = useState(false);
   const [isModalTitle, setIsModalTitle] = useState(null);
   const [isModalText, setIsModalText] = useState(null);
   const showTextDescriptionOnModal = (title,text) => {
       setIsModalVisible(true);
       setIsModalTitle(title);
       setIsModalText(text);
   }
/*--------------- show description on modal ---------*/
  return (
    <>
      <CCol xs={12}>
      <CRow className="align-items-center mb-2">
          <CCol xs={3}></CCol>
             <CCol xs={6}>
                <CToast autohide={true} delay={5000} visible={showAlert} color={`${color && color==='failure' ? 'danger' : 'success' }`} animation={true} className="text-white align-items-center">
                              <div className="d-flex">
                                <CToastBody>{showMessage}</CToastBody>
                                <CToastClose className="me-2 m-auto" />
                              </div>
                  </CToast>
             </CCol>
          <CCol xs={3}></CCol>
       </CRow>
        <CCard className="mb-4 customCardTable">
          <CCardHeader>
          <CRow className="align-items-center">
          <CCol xs={12}>
            <CRow className='mb-3 forMobileViewN'>
            <CCol xs={12}>
            <div className='rightAddBtn'>
            <NavLink to={`${process.env.PUBLIC_URL}/admin/add-report`} className="btnAdd me-2">
            <CButton color="success"><FaPlus className='me-1'/>Add Report</CButton></NavLink>
              {
                    loader ? <CSpinner color="success"/> : <CButton className='filterBtn' onClick={ () => downloadReport() }>
                    <FaFileDownload className='me-1' />Export
                </CButton>
              }
              </div>
              </CCol>
            </CRow>
          <CRow className="align-items-center reportListCss">
          <CCol xs={3} className='mobilemarginSpace mobileSpace'>
            <CFormInput type="text" name="search" id="search" placeholder="Search ..." aria-label="default input example"/>
            </CCol>
<CCol xs={3} className='mobilemarginSpace mobileSpace'>
      <CFormSelect aria-label="Default select example" name="site"
          id="site"
          onChange={(e) => getSiteDetailBySite(e) }>
          <option value="">Filter By Site</option>
          {
             site && site.length > 0 ? site.map((item, index) => <option key={item.id} value={item.id}>{item.name}</option>) : null
          }
      </CFormSelect>
</CCol>

<CCol xs={3} className='mobilemarginSpace mobileSpace'>
      <CFormSelect aria-label="Default select example"   name="site_detail"
              id="site_detail"
              onChange={(e) => getSiteDetail2BySiteAndDetail(e) }>
          <option value="">Filter By Site Detail</option>
          {
            siteDetail && siteDetail.length > 0 && siteDetail.map((item, index) => <option key={item.id} value={item.id}>{item.name}</option>) 
          }
      </CFormSelect>
</CCol>
<CCol xs={3} className='mobilemarginSpace mobileSpace'>
      <CFormSelect aria-label="Default select example" name="site_detail2"
              id="site_detail2">
          <option value="">Filter By Site Detail2</option>
          {
              siteDetail2 && siteDetail2.length > 0 && siteDetail2.map((item, index) => <option key={item.id} value={item.id}>{item.name}</option>)
          }
      </CFormSelect>
</CCol>
       
   
<CCol xs={3} className='mobilemarginSpace mobileSpace'>
      <CFormSelect aria-label="Default select example" name="opportunity_class" id="opportunity_class" onChange={(e)=>getProblemClassByOpportunityClass(e)}>
          <option value="">Filter By Opportunity Class</option>
          {
             opportunityClassList && opportunityClassList.length > 0 ? opportunityClassList.map((item,index)=><option key={item.id} value={item.id}>{item.name}</option>) : null
          }
      </CFormSelect>
</CCol>
<CCol xs={3} className='mobilemarginSpace mobileSpace'>
      <CFormSelect aria-label="Default select example" name="problem_class" id="problem_class" onChange={(e)=>{getProblemCategoryByOpportunityAndProblemClass(e);getProblemDetail(e)}}>
          <option value="">Filter By Problem Class</option>
          {
             problemClassList && problemClassList.length > 0 ? problemClassList.map((item,index)=><option key={item.id} value={item.id}>{item.name}</option>) : null
          }
      </CFormSelect>
</CCol>
<CCol xs={3} className='mobilemarginSpace mobileSpace'>
      <CFormSelect aria-label="Default select example" name="problem_category" id="problem_category" onChange={(e)=>getProblemDetail(e)}>
          <option value="">Filter By Problem Category</option>
          {
             problemCategoriesList && problemCategoriesList.length > 0 ? problemCategoriesList.map((item,index)=><option key={item.id} value={item.id}>{item.name}</option>) : null
          }
      </CFormSelect>
</CCol>
<CCol xs={3} className='mobilemarginSpace mobileSpace'>
      <CFormSelect aria-label="Default select example" name="problem_detail"
              id="problem_detail">
          <option value="">Filter By Problem Detail</option>
          {
             problemDetailList && problemDetailList.length > 0 ? problemDetailList.map((item, index) => <option key={item.id} value={item.id}>{item.name}</option>) : null
          }
      </CFormSelect>
</CCol>
      </CRow>
            </CCol>
            <CCol xs={12}  xl={12} className='customOneFilters mobBigbtn'>
           
            <div className=''>
            <CButton className='searchBtn' onClick={() => isFilter()}>Search</CButton> 
            <CButton  className='refreshBtn' variant='outline'  onClick={() => { clearFilter() }}>Clear</CButton>
            </div>
            <div className='rightAddBtn forMobileViewB'>
            <NavLink to={`${process.env.PUBLIC_URL}/admin/add-report`} className="btnAdd me-2">
            <CButton color="success"><FaPlus className='me-1'/>Add Report</CButton></NavLink>
             {
                    loader ? <CSpinner color="success"/> : <CButton className='filterBtn' onClick={() => { downloadReport() }}>
                    <FaFileDownload className='me-1' />Export
                     </CButton>
              }
            
              </div>
              </CCol>
           </CRow>
          </CCardHeader>
          
          <CCardBody>
            <div className='table-reposnive'>
               <CTable bordered>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell scope="col">Action</CTableHeaderCell>
                    <CTableHeaderCell scope="col" onClick={() => sortColumn('opportunity_status')}><CIcon icon={sortConfig.direction && sortConfig.direction === 'asc' && sortConfig.key === 'opportunity_status' ? cilArrowTop : cilArrowBottom} className="me-2" />Opportunity Status</CTableHeaderCell>
                    <CTableHeaderCell scope="col" onClick={() => sortColumn('record_id')}><CIcon icon={sortConfig.direction && sortConfig.direction === 'asc' && sortConfig.key === 'record_id' ? cilArrowTop : cilArrowBottom} className="me-2" />Record Id</CTableHeaderCell>
                    <CTableHeaderCell scope="col" onClick={() => sortColumn('date')}><CIcon icon={sortConfig.direction && sortConfig.direction === 'asc' && sortConfig.key === 'date' ? cilArrowTop : cilArrowBottom} className="me-2" />Date</CTableHeaderCell>
                    <CTableHeaderCell scope="col" onClick={() => sortColumn('site')}><CIcon icon={sortConfig.direction && sortConfig.direction === 'asc' && sortConfig.key === 'site' ? cilArrowTop : cilArrowBottom} className="me-2" />Site</CTableHeaderCell>
                    <CTableHeaderCell scope="col" onClick={() => sortColumn('site_detail')}><CIcon icon={sortConfig.direction && sortConfig.direction === 'asc' && sortConfig.key === 'site_detail' ? cilArrowTop : cilArrowBottom} className="me-2" />Site Detail</CTableHeaderCell>
                    <CTableHeaderCell scope="col" onClick={() => sortColumn('site_detail2')}><CIcon icon={sortConfig.direction && sortConfig.direction === 'asc' && sortConfig.key === 'site_detail2' ? cilArrowTop : cilArrowBottom} className="me-2" />Site Detail2</CTableHeaderCell>
                    <CTableHeaderCell scope="col" onClick={() => sortColumn('issue_description')}><CIcon icon={sortConfig.direction && sortConfig.direction === 'asc' && sortConfig.key === 'issue_description' ? cilArrowTop : cilArrowBottom} className="me-2" />Issue Description</CTableHeaderCell>
                    <CTableHeaderCell scope="col" onClick={() => sortColumn('opportunity_class_id')}><CIcon icon={sortConfig.direction && sortConfig.direction === 'asc' && sortConfig.key === 'opportunity_class_id' ? cilArrowTop : cilArrowBottom} className="me-2" />Opportunity Class</CTableHeaderCell>
                    <CTableHeaderCell scope="col" onClick={() => sortColumn('problem_class_id')}><CIcon icon={sortConfig.direction && sortConfig.direction === 'asc' && sortConfig.key === 'problem_class_id' ? cilArrowTop : cilArrowBottom} className="me-2" />Problem Class</CTableHeaderCell>
                    <CTableHeaderCell scope="col" onClick={() => sortColumn('reportable')}><CIcon icon={sortConfig.direction && sortConfig.direction === 'asc' && sortConfig.key === 'reportable' ? cilArrowTop : cilArrowBottom} className="me-2" />Reportable</CTableHeaderCell>
                    <CTableHeaderCell scope="col" onClick={() => sortColumn('problem_category_id')}><CIcon icon={sortConfig.direction && sortConfig.direction === 'asc' && sortConfig.key === 'problem_category_id' ? cilArrowTop : cilArrowBottom} className="me-2" />Problem Category</CTableHeaderCell>
                    <CTableHeaderCell scope="col" onClick={() => sortColumn('problem_detail_id')}><CIcon icon={sortConfig.direction && sortConfig.direction === 'asc' && sortConfig.key === 'problem_detail_id' ? cilArrowTop : cilArrowBottom} className="me-2" />Problem Detail</CTableHeaderCell>
                    <CTableHeaderCell scope="col" onClick={() => sortColumn('root_cause_required')}><CIcon icon={sortConfig.direction && sortConfig.direction === 'asc' && sortConfig.key === 'root_cause_required' ? cilArrowTop : cilArrowBottom} className="me-2" />Root Cause Required</CTableHeaderCell>
                    <CTableHeaderCell scope="col" onClick={() => sortColumn('user_id')}><CIcon icon={sortConfig.direction && sortConfig.direction === 'asc' && sortConfig.key === 'user_id' ? cilArrowTop : cilArrowBottom} className="me-2" />Management Allocation</CTableHeaderCell>
                    <CTableHeaderCell scope="col" onClick={() => sortColumn('root_cause_description')}><CIcon icon={sortConfig.direction && sortConfig.direction === 'asc' && sortConfig.key === 'root_cause_description' ? cilArrowTop : cilArrowBottom} className="me-2" />Root Cause Description</CTableHeaderCell>
                    <CTableHeaderCell scope="col" onClick={() => sortColumn('immediate_action_description')}><CIcon icon={sortConfig.direction && sortConfig.direction === 'asc' && sortConfig.key === 'immediate_action_description' ? cilArrowTop : cilArrowBottom} className="me-2" />Immediate Action Description</CTableHeaderCell>
                    <CTableHeaderCell scope="col" onClick={() => sortColumn('corrective_action_description')}><CIcon icon={sortConfig.direction && sortConfig.direction === 'asc' && sortConfig.key === 'corrective_action_description' ? cilArrowTop : cilArrowBottom} className="me-2" />Corrective Action Description</CTableHeaderCell>
                   
                    <CTableHeaderCell scope="col" onClick={() => sortColumn('verification_of_effectiveness')}><CIcon icon={sortConfig.direction && sortConfig.direction === 'asc' && sortConfig.key === 'verification_of_effectiveness' ? cilArrowTop : cilArrowBottom} className="me-2" />Verification of Effectiveness</CTableHeaderCell>
                    <CTableHeaderCell scope="col" onClick={() => sortColumn('quality_cost')}><CIcon icon={sortConfig.direction && sortConfig.direction === 'asc' && sortConfig.key === 'quality_cost' ? cilArrowTop : cilArrowBottom} className="me-2" />Quality Cost</CTableHeaderCell>
                    <CTableHeaderCell scope="col" onClick={() => sortColumn('created_by')}><CIcon icon={sortConfig.direction && sortConfig.direction === 'asc' && sortConfig.key === 'created_by' ? cilArrowTop : cilArrowBottom} className="me-2" />Created By</CTableHeaderCell>
                    
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {
                    data && data.length > 0 ? data.map((item,index)=>{
                              
                              return <CTableRow key={index+1}>
                                <CTableDataCell className="actionBtn actionBtnReport">
                                    <NavLink to={`${process.env.PUBLIC_URL}/admin/edit-report/${item.id}`}>
                                    <CTooltip content="Edit"  placement="top">
                                      <CButton  className='editBtn'> <FaPencilAlt/> </CButton>
                                    </CTooltip></NavLink>
                                    <NavLink to={`${process.env.PUBLIC_URL}/admin/view-report/${item.id}`}>
                                    <CTooltip content="View" placement="top">
                                    <CButton  className='viewBtn'>
                                    <FaRegEye/> </CButton>
                                    </CTooltip></NavLink>
                                    <CTooltip content="Delete" placement="top">
                                     <CButton  onClick={() => deleteData(item.id)} className='trashBtn'><FaTrashAlt/></CButton>
                                     </CTooltip>
                                </CTableDataCell>
                                <CTableDataCell className="position-relative">{item.opportunity_status} <span className={`solidCircle ${item.opportunity_status==='Open'? 'redStatus' : item.opportunity_status==='In Progress' ? 'yellowtatus' : item.opportunity_status==='Closed'? 'greenStatus': ''}`}></span></CTableDataCell>
                                {/* <CTableHeaderCell scope="row">{++indexOfFirstData}</CTableHeaderCell> */}
                                <CTableDataCell>{item.record_id}</CTableDataCell>
                                <CTableDataCell>{item.date}</CTableDataCell>
                                <CTableDataCell>{item.site_name}</CTableDataCell>
                                <CTableDataCell>{item.site && item.site == '1' ? item.customer_site_detail_name : item.supplier_site_detail_name}</CTableDataCell>
                                <CTableDataCell>{item.site && item.site == '1' ? item.customer_site_detail2_name : item.supplier_site_detail2_name}</CTableDataCell>
                                <CTableDataCell>{item.issue_description && item.issue_description.length > 48 ? <><p className="notesWidth">{item.issue_description.slice(0,46)+'...'}<a onClick={() => { showTextDescriptionOnModal("Issue Description", item.issue_description)}}>Show more</a></p></>  : item.issue_description}</CTableDataCell>
                                <CTableDataCell>{item.opportunity_class_name}</CTableDataCell>
                                <CTableDataCell>{item.problem_class_name}</CTableDataCell>
                                <CTableDataCell>{item.reportable}</CTableDataCell>
                                <CTableDataCell>{item.problem_category_name}</CTableDataCell>
                                <CTableDataCell>{item.problem_detail_name}</CTableDataCell>
                                <CTableDataCell>{item.root_cause_required}</CTableDataCell>
                                <CTableDataCell>{item.management_allocation}</CTableDataCell>
                                <CTableDataCell>{item.root_cause_description && item.root_cause_description.length > 48 ? <><p className="notesWidth">{item.root_cause_description.slice(0,46)+'...'}<a onClick={() => { showTextDescriptionOnModal("ROOT CAUSE DESCRIPTION", item.root_cause_description)}}>Show more</a></p></>  : item.root_cause_description}</CTableDataCell>
                                <CTableDataCell>{item.immediate_action_description && item.immediate_action_description.length > 48 ? <><p className="notesWidth">{item.immediate_action_description.slice(0,46)+'...'}<a onClick={() => { showTextDescriptionOnModal("IMMEDIATE ACTION DESCRIPTION", item.immediate_action_description)}}>Show more</a></p></>  : item.immediate_action_description}</CTableDataCell>
                                <CTableDataCell>{item.corrective_action_description && item.corrective_action_description.length > 48 ? <><p className="notesWidth">{item.corrective_action_description.slice(0,46)+'...'}<a onClick={() => { showTextDescriptionOnModal("CORRECTIVE ACTION DESCRIPTION", item.corrective_action_description)}}>Show more</a></p></>  : item.corrective_action_description}</CTableDataCell>
                               
                                <CTableDataCell>{item.verification_of_effectiveness && item.verification_of_effectiveness.length > 48 ? <><p className="notesWidth">{item.verification_of_effectiveness.slice(0,46)+'...'}<a onClick={() => { showTextDescriptionOnModal("VERIFICATION OF EFFECTIVENESS", item.verification_of_effectiveness)}}>Show more</a></p></>  : item.verification_of_effectiveness}</CTableDataCell>
                                <CTableDataCell>
                                    {item.quality_cost}
                                </CTableDataCell>
                                  
                                <CTableDataCell>{item.first_name} {item.last_name}</CTableDataCell>
                                
                            </CTableRow>
                           
                       })
                       : <CTableRow>
                            <CTableDataCell colSpan="20" className="noDataFound">{loading ? 'Loading ...' : 'No records were found'}</CTableDataCell>
                       </CTableRow>
                  }
                  
                </CTableBody>
              </CTable>
              </div>
              <CRow className="align-items-center showPageNum">
                  {
                    totalRecord && totalRecord > 10 ?
                     <>
                            <CCol xs={4} >
                              <p>Showing {((currentPage-1)*dataPerPage+1)} to {indexOfLastData < totalRecord ? indexOfLastData : totalRecord} of {totalRecord} entries</p>
                            </CCol>
                            <CCol xs={4} ></CCol>
                            <CCol xs={4} >
                                <CPagination align="end" aria-label="Page navigation example">
                                          <Pagination
                                              currentPage={currentPage}
                                              totalPages={Math.ceil(totalRecord/dataPerPage)}
                                              onPageChange={paginate}
                                              dataPerPage={dataPerPage}
                                          />
                                      </CPagination> 
                              
                            </CCol>
                      </>
                    : 
                    null
                  }
             </CRow>
              
              
             
              
          </CCardBody>
         
        </CCard>
      </CCol>
      {/*---------------- Modal section start here -------------------*/}
            <CModal size="xl" alignment="center" visible={isModalVisible} onClose={() => setIsModalVisible(false)}>
                  <CModalHeader>
                    <CModalTitle>{isModalTitle && isModalTitle}</CModalTitle>
                  </CModalHeader>
                      <CModalBody>
                        {isModalText && isModalText}
                      </CModalBody>
                      <CModalFooter>
                        <CButton color="secondary" onClick={() => setIsModalVisible(false)}>
                          Close
                        </CButton>
                      </CModalFooter>
              </CModal>
          
      {/*---------------- Modal section end here -------------------*/}
    </>
  )
}

export default List



